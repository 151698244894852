import React, { useEffect, useState } from "react";
import { Typography } from "../typography/Typography";
import classNames from "classnames";
import { AccordionComponentProps } from "./type";
import { Icon } from "../icon/Icon";

function Accordion({
  name,
  icon,
  subname,
  quantity,
  className,
  children,
  isOpen = false,
  isHidden = false,
  onChange,
  id,
}: AccordionComponentProps): JSX.Element {
  const [accordionOpen, setAccordionOpen] = useState(isOpen);

  useEffect(() => {
    setAccordionOpen(isOpen);
  }, [isOpen]);

  const clName = classNames(
    { "sm:border-b-0 py-2 px-5": accordionOpen },
    { "sm:border-b-0 py-0 px-5": accordionOpen && quantity },
    {
      "sm:border-b sm:border-0 border-smoke border-b-[1px] py-2 px-5":
        !accordionOpen,
    },
    { "sm:pb-2 sm:pl-2 sm:border-black": !className },
    { "sm:border": className?.includes("full-border") },
    { hidden: isHidden },
    className
  );

  const subClName = classNames(
    { "opacity-100 visible py-0 h-auto": accordionOpen },
    { "opacity-0 invisible h-0 duration-100": !accordionOpen }
  );

  const handleAccordion = () => {
    setAccordionOpen(!accordionOpen);
    onChange?.(!accordionOpen);
  };
  return (
    <div
      className={classNames(clName, "flex flex-col w-full items-center")}
      id={id}
    >
      <div
        className={classNames("flex flex-row items-center h-full w-full cursor-pointer", {"md:p-1 p-0 sm:p-2" : quantity}, {"mb-6" : quantity && accordionOpen}, {"border-smoke border-b-[1px]": accordionOpen && !quantity})}
        onClick={handleAccordion}
      >
        {icon && (
          <div
            className={"flex items-center justify-center w-10 h-10"}>
            <Icon name={icon} />
          </div>
        )}
        {subname ? (
          <div className="flex flex-col w-full ml-5">
            <Typography tag="h5" className="text-black text-xs w-full">
              {name}
            </Typography>
            <Typography tag="p" className=" text-primary text-xs w-full mt-2">
              {subname}
            </Typography>
          </div>
        ) : (
          <div className="w-full">
            <Typography
              tag="h4"
              className={classNames(
                "text-black sm:text-lg break-all md:text-4xl text-4xl w-full font-normal my-4",
                { "md:ml-2.5 ml-3.5 sm:ml-8": icon }
              )}
            >
              {name}
            </Typography>
          </div>
        )}
        {quantity && (
          <div className="bg-black flex-none rounded-full w-7 h-7 mr-14">
            <Typography
              tag="p"
              className="text-white text-xs w-full h-full flex justify-center items-center"
            >
              {quantity}
            </Typography>
          </div>
        )}
        {(quantity || children ) && (
          <div className={classNames("flex w-6 h-9 items-center")}>
            <Icon name={accordionOpen ? "TopArrow" : "BottomArrow"} />
          </div>
        )}
      </div>
      <div
        className={classNames(
          subClName,
          "pb-0 transition-all duration-300 w-full px-0"
        )}
      >
        {accordionOpen && children}
      </div>
    </div>
  );
}

export { Accordion };
