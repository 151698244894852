import React from "react";
import { IconProps } from "../Types";

const Add = ({ className }: IconProps) => (
  <svg
    className={className}
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9.5" r="9" stroke="#F5F7F5" />
    <path
      d="M6 9.792V8.52H12.936V9.792H6ZM8.748 12.42V6H10.164V12.42H8.748Z"
      fill="#F5F7F5"
    />
  </svg>
);

export default Add;
