import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import { Button } from "../components/button/Button";
import { Icon } from "../components/icon/Icon";
import { Logo } from "../components/logo/Logo";
import { Typography } from "../components/typography";
import { ContactForm } from "../components/contactForm/ContactForm";
import { useForm } from "../components/contactForm/useForm";
import { DataContainerContext } from "../common/context/DataContainerProvider";
import { ResponseType } from "../components/contactForm/Types";
import { generateUID } from "../services/Helpers";
import QRCode from "qrcode.react";
import classnames from "classnames";
import { Image } from "../components/image";
import {
  AccordionEnum,
} from "../services/enums";
import { uid } from "uid";
import { Accordion } from "../components/accordion";

export const OrderPage = () => {
  const { dataContainer } = useContext(DataContainerContext);
  const [response, setResponse] = useState<ResponseType>();
  const location = useLocation();
  const pointViews = [
    {
      name: "Exterior",
      id: uid()
    },
    {
      name: "Interior",
      id: uid()
    }
  ];
  const floorSelected = dataContainer?.findSelectedVariantByComponentName("Floor Plans");
  
  useEffect(() => {
    if (dataContainer) {
      dataContainer.logEvent("page_entered", "Order Page", {
        product: "",
      });
      dataContainer.clearSelectionHooks();
    }
  }, [dataContainer]);
  

  const getUniqueHashForSelections = () => {
    return dataContainer?.generateShareableLink(location.state);
  };

  const getPriceList = () => {
    let total = 0;
    const prices = {};
    pointViews.forEach((pointView) => {
      prices[pointView.name] = dataContainer
        ?.findSelectedVariantByParentComponentName(
          pointView.name,
          floorSelected?.key
        )
        .map((variant) => {
          total += Number(variant.price);
          return { [variant.original_key]: variant.price };
        });
    });

    prices["Total"] = total;

    return JSON.stringify(prices);
  };

  const navigate = useNavigate();

  const successMailMessage = dataContainer?.sectionKey(
    "Translate",
    "success_mail_message"
  );

  const errorMailMessage = dataContainer?.sectionKey(
    "Translate",
    "error_mail_message"
  );

  const contactForm = useForm({
    onSubmit: async (data) => {
      const request = {
        properties: {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          phone_number: data.phoneNumber,
          address: data.address,
          co: data.co,
          zip_code: data.zipCode,
          country: data.country,
          message: data.message,
          configuration_link: getUniqueHashForSelections(),
          prices: getPriceList(),
        },
      };
      dataContainer.submitForm(request, (res) => {
        if (res) {
          setResponse({ message: successMailMessage, success: true });
          navigate("/confirmation");
        } else {
          setResponse({ message: errorMailMessage, success: false });
        }
      });
    },
  });

  const getDescription = (currentVariation, selectedVariations) => {
    const component = dataContainer?.findComponentById(
      currentVariation.component_id
    );
    if (component.name !== AccordionEnum.Model) {
      if(dataContainer.findParentComponentById(currentVariation.component_id).name === AccordionEnum.Fasad_3){
        return dataContainer?.sectionKey(
          "product_descriptions",
          currentVariation.original_key
        ) + AccordionEnum.Fasad_3_Appendix;
      }
      return dataContainer?.sectionKey(
        "product_descriptions",
        currentVariation.original_key
      );
    }
    return dataContainer?.extractModelDescription(selectedVariations);
  };

  return (
    <div className="w-full h-full bg-smoke sm:pt-7 sm:pl-10 sm:pr-20 sm:pb-12 p-4 overflow-auto">
      <div className="flex flex-col sm:items-start items-center justify-center">
        <Logo to="/" className="sm:hidden flex mb-6" />
        <Button
          secondary
          className="mb-9"
          textClassName="ml-3.5"
          icon="Prev"
          label="Tillbaka"
          onClick={() => navigate(-1)}
        />
      </div>
      <Typography
        tag="h1"
        className="text-primary leading-snug mb-3 font-normal sm:text-6xl text-4xl"
        parentClassName="h-auto"
      >
        {dataContainer?.sectionKey("Translate", "order_page_title")}
      </Typography>
      <Typography
        tag="h3"
        className="mb-5 font-normal text-black"
        parentClassName="h-auto"
      >
        {dataContainer?.sectionKey("Translate", "order_page_description")}
      </Typography>
      <div className="flex mb-6 sm:flex-row flex-col">
        <div className="sm:w-7/12 w-full bg-white py-7 px-2.5 mr-5 sm:mb-0 mb-5">
          <Typography tag="h4" className="ml-7 text-xs mb-5 mt-10" parentClassName="h-auto">
            Husmodell & planritning
          </Typography>
          {floorSelected && (
            <div
              key={floorSelected.key}
              className="items flex items-center justify-between border border-gray-mid mb-1 px-2 py-3"
            >
              <div className="flex items-center">
                <Image
                  src={floorSelected.assets?.[0]?.preview_image_url}
                  title={floorSelected.name}
                  className="w-12 h-12 flex relative items-center justify-center object-contain"
                />
                <div className="pl-6">
                  <Typography
                    tag="h4"
                    className="leading-5 mb-1"
                    parentClassName="h-auto"
                  >
                    {floorSelected.key}
                  </Typography>
                  <Typography
                    tag="p"
                    parentClassName="h-auto"
                    className="text-black opacity-70"
                  >
                    {floorSelected && getDescription(floorSelected, null)}
                    {dataContainer?.getIsFloorMirror() && ", (spegelvånd)"}
                  </Typography>
                </div>
              </div>
            </div>
          )}
          <Typography tag="h4" className="ml-7 text-xs mb-5 mt-10" parentClassName="h-auto">
            Exteriöra & interiöra val
          </Typography>
          {
            pointViews.map((accordion) => {
              const selectedVariantsByComponentName = dataContainer?.findSelectedVariantByParentComponentName(accordion.name, floorSelected?.key);

              if(selectedVariantsByComponentName?.length === 1 && selectedVariantsByComponentName[0]?.key === "environment") {
                return;
              }
              return (
                <Accordion
                  id={accordion.id}
                  key={accordion.id}
                  icon={accordion.name.split(" ").join("") || "Circle"}
                  name={dataContainer?.sectionKey(
                    "Translate",
                    accordion.name.toLowerCase().split(" ").join("_")
                  )}
                  className="border py-4 px-2 mb-1 border-[#ECECEC] full-border"
                >
                  {selectedVariantsByComponentName?.map((product) => (
                    <div className="product mb-10" key={generateUID(9)}>
                      <div
                        key={product.key}
                        className="items flex items-center justify-between border border-gray-mid mb-1 px-3 py-2"
                      >
                        <div className="flex items-center">
                          <div className="min-w-12 w-12">
                            <Image
                              src={product.assets?.[0]?.preview_image_url}
                              title={product.name}
                              className="h-12 flex relative items-center justify-center object-contain"
                            />
                          </div>
                          <div className="pl-6 max-w-[80%]">
                            <Typography
                              tag="h4"
                              className="leading-5 mb-1"
                              parentClassName="h-auto"
                            >
                              {product.key}
                            </Typography>
                            <Typography
                              tag="p"
                              parentClassName="h-auto"
                              className=" text-black opacity-70"
                            >
                              {product && `${getDescription(product, null)}`}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </Accordion>
              );
            })
          }
        </div>
        <div className="sm:w-5/12 w-full bg-white p-7 sm:pb-[9.75rem] pb-9">
          <Typography
            tag="h3"
            className="font-normal leading-5 mb-5 text-black"
            parentClassName="h-auto"
          >
            {dataContainer?.sectionKey("Translate", "order_title")}
          </Typography>
          <Typography
            tag="p"
            className="font-normal leading-3 pb-5 border-b border-gray-mid text-black"
            parentClassName="h-auto"
          >
            {dataContainer?.sectionKey("Translate", "order_text")}
          </Typography>
          <div className="flex justify-between mt-5 mb-14">
            <div className="flex flex-col">
              <Typography
                tag="h3"
                className="font-normal mb-6 text-black"
                parentClassName="h-auto"
              >
                {dataContainer?.sectionKey("Translate", "order_links_title")}
              </Typography>
              <a
                href={getUniqueHashForSelections()}
                className="flex items-center sm:text-xs text-3xl text-primary font-normal mb-5"
              >
                {dataContainer?.sectionKey(
                  "Translate",
                  "order_link_3_label"
                )}
                <Icon name="Link" className="ml-2.5" />
              </a>
            </div>
            {dataContainer && (
              <QRCode
                id={getUniqueHashForSelections()}
                value={getUniqueHashForSelections()}
                size={200}
                includeMargin
                className="mt-3.5 mr-4 sm:flex hidden"
              />
            )}
          </div>
          <a
            href={dataContainer?.sectionKey(
              "Translate",
              "order_link_4_website"
            )}
            target="_blank"
            rel="noopener noreferrer"
            className="text-4xl text-primary font-medium sm:font-normal"
          >
            {dataContainer?.sectionKey("Translate", "order_link_4_label")}
          </a>
          <ContactForm
            data={contactForm.data}
            errors={contactForm.errors}
            handleChange={contactForm.handleChange}
            handleSubmit={contactForm.handleSubmit}
            dataContainer={dataContainer}
          />
        </div>
      </div>
      <div className="flex justify-between">
        <Logo to="/" className="sm:flex hidden" />
        <div>
          <Button
            label="KONTAKTA MIG"
            icon="RightGreenArrow"
            secondary
            className="flex-row-reverse bg-white py-3 px-6 text-primary"
            iconClassName="pl-4 stroke-primary"
            onClick={() => contactForm.handleSubmit()}
          />
          {response && (
            <p
              className={classnames(
                "mt-5 text-xs",
                { "text-green-500": response.success },
                { "text-red": !response.success }
              )}
            >
              {response.message}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
