import React from "react";
import { IconProps } from "../Types";

const BackArrow = ({ className }: IconProps) => (
  <svg
    className={className}
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.69497 0.195262C4.96834 0.455612 4.96834 0.877722 4.69497 1.13807L2.38995 3.33333H6.3C10.5527 3.33333 14 6.61648 14 10.6667V11.3333C14 11.7015 13.6866 12 13.3 12C12.9134 12 12.6 11.7015 12.6 11.3333V10.6667C12.6 7.35286 9.7795 4.66667 6.3 4.66667H2.38995L4.69497 6.86193C4.96834 7.12228 4.96834 7.54439 4.69497 7.80474C4.42161 8.06509 3.97839 8.06509 3.70503 7.80474L0.205025 4.4714C-0.0683418 4.21106 -0.0683418 3.78894 0.205025 3.5286L3.70503 0.195262C3.97839 -0.0650874 4.42161 -0.0650874 4.69497 0.195262Z"
      fill="#006564"
    />
  </svg>
);

export default BackArrow;
