import React, { useEffect, useState } from "react";
import { RadioInput } from "../input/RadioInput";
import { Typography } from "../typography";
import { Product } from "./product/product";
import { ColorsGrid } from "./index";
import { ColorRadioInput } from "../input";
import { SubAccordionProps } from "./types";
import { Accordion } from "../accordion";
import { multipleAccordions } from "./MultipleAccordions";
import { useParams } from "react-router";

const SubAccordion = ({
  variant,
  label,
  items,
  id,
  actionTitle,
  dataContainer,
  setSelectionAccordion,
  accordionKey,
  iconKey
}: SubAccordionProps) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [materialId] = useState<number | null>(null);
  const [doorRadioChecked, setDoorRadioChecked] = useState("outside_door");
  const [windowsRadioChecked, setWindowsRadioChecked] =
    useState("outside_window");
  const [granPanel, setGranPanel] = useState(false);
  const { cat, singleID } = useParams();

  const toggleColorVariant = (variant, type) => {
    if (!type) {
      return;
    }
    if (type.includes("doors")) {
      setDoorRadioChecked(variant);
      sessionStorage.setItem("doorColorVariant", variant);
    }
    if (type.includes("windows")) {
      setWindowsRadioChecked(variant);
      sessionStorage.setItem("windowColorVariant", variant);
    }
  };

  useEffect(() => {
    if(dataContainer.findSelectedVariantByComponentNameAndCat("Fasad Type 1", cat)?.key.includes("Gran")){
      setGranPanel(true);
    }
  }, []);
  

  const toggleSelection = (id) => {
    if (materialId && !dataContainer.isVariationSelected(materialId)) {
      dataContainer?.toggleVariationSelection(materialId, null, true);
    }
    dataContainer?.toggleVariationSelection(id);
  };

  const getSelectedColors = () => {
    if (!label) {
      return;
    }

    if (
      doorRadioChecked.includes("inside") ||
      windowsRadioChecked.includes("inside")
    ) {
      return dataContainer?.findComponentByName(`${label} Colors Inside`)
        ?.available_variations;
    }
    if (
      doorRadioChecked.includes("outside") ||
      windowsRadioChecked.includes("outside")
    ) {
      return dataContainer?.findComponentByName(`${label} Colors Outside`)
        ?.available_variations;
    }
  };

  const colorOptions = dataContainer?.findComponentByName(
    `${label} Colors Type`
  )?.available_variations;

  const granPanelColors = dataContainer?.findComponentByName(
    "Fasad Granplanel Colors"
  )?.available_variations;

  const wardrobeOptions = label !== "Windows Sprojs" && dataContainer?.findComponentByName(
    `${label} Type`
  )?.available_variations;

  const windowsSprojs = dataContainer?.findEqualComponentByName(`${label} Sprojs`,cat);
  const roofEntrance = dataContainer?.findEqualComponentByName(`${label} Entrance Type`, cat);
  const doorsSlider = dataContainer?.findEqualComponentByName(`Sliding ${label}`, cat);

  const childrensType = dataContainer
    ?.findComponentsByName(`${label} type`)
    ?.filter(
      (t) => (t.group_id === id || t.group_id === 513 || t.group_id === 514) && 
      !t.name.includes("Pivot Fasad Type") && !t.name.includes("Gable Fasad Type") 
      && 
      (label === "Bathroom" && !singleID?.includes("_b1") && !singleID?.includes("_c1") ? !t.name.includes("Bathroom Type 6") : t) && 
      (singleID?.includes("modern") ? !t.name.includes("Fasad Type 3") : t)
    );

  dataContainer.registerSelectionHook(() => {
    setShouldRefresh(!shouldRefresh);
  });
  
  const renderProduct = (product) => {
    const isSelected = dataContainer.isVariationSelected(product.id);
    const isMultiSelect = dataContainer?.getMultiSelect(label);
    return (
      <Product
        key={product.id}
        name={product.key}
        image={{
          src: product.assets[0]?.preview_image_url,
          title: product.key,
          className: "max-h-[66px]",
          objectFit: "contain",
        }}
        description={dataContainer?.sectionKey(
          "product_descriptions",
          product.original_key
        )}
        removeButton={{
          isShown: ((isSelected && isMultiSelect) || (isSelected && dataContainer?.getIsDefault(label, cat) === false)),
          onClick: toggleSelection.bind(null, product.id),
        }}
        addButton={{
          isShown: true,
          variant: isSelected ? "white" : "black",
          onClick: !isSelected
            ? toggleSelection.bind(null, product.id)
            : undefined,
        }}
      />
    );
  };

  const renderColors = (sub) => (
    <ColorRadioInput
      key={sub.id}
      id={sub.id}
      name={sub.id}
      value={sub.id}
      onChange={() => {
        dataContainer?.toggleVariationSelection(sub.id);
      }}
      checked={dataContainer?.isVariationSelected(sub.id)}
      image={{
        src: sub.assets[0].preview_image_url,
        title: sub.key + " image",
      }}
    />
  );

  const renderProducts = (items) => {
    if (materialId) {
      return items
        ?.filter(
          (item) =>
            item.dependencies &&
            item.dependencies[0]?.depends_on_variation_id === materialId
        )
        .map((item) => renderProduct(item));
    }

    return items?.filter(variant => (
      label === "Heating_electricity" && 
      variant?.original_key?.includes("kamin") &&
       singleID?.includes("_f")) ? !variant.original_key?.includes("kamin") 
      : variant).map((item) => renderProduct(item));
  };

  const renderSubAccordion = () => {
    switch (variant) {
    case "list":
      return (
        <>
          <Typography
            tag="h3"
            className="text-black-secondary font-normal mt-6"
            parentClassName="h-auto"
          >
            {label
              ? dataContainer?.sectionKey(
                "Accordions Descriptions",
                label?.toLowerCase().split(" ").join("_")
              )
              : ""}
          </Typography>
          <div className="flex gap-16 pt-8 pb-8">
            {colorOptions?.map((option) => (
              <RadioInput
                label={dataContainer?.sectionKey("Options Labels", option.original_key)}
                onChange={() => {
                  label?.includes("Doors")
                    ? toggleColorVariant(option.original_key, "doors")
                    : toggleColorVariant(option.original_key, "windows");
                }}
                checked={
                  label?.includes("Doors")
                    ? option.original_key.includes(doorRadioChecked)
                    : option.original_key.includes(windowsRadioChecked)
                }
                value={option.id}
                name={label}
                id={option.id}
                key={option.id}
              />
            ))}
            {wardrobeOptions &&
                wardrobeOptions?.map((option) => (
                  <RadioInput
                    label={dataContainer?.sectionKey("Options Labels", option.original_key)}
                    onChange={() =>
                      dataContainer?.toggleVariationSelection(option?.id)
                    }
                    value={option.id}
                    name={label}
                    id={option.id}
                    key={option.id}
                  />
                ))}
          </div>
          {getSelectedColors()?.length && (
            <div className="pt-10 pb-8">
              <ColorsGrid
                applyBottomBorder={true}
                className="border-t border-white-off"
              >
                {getSelectedColors()?.map((sub) => renderColors(sub))}
              </ColorsGrid>
            </div>
          )}
          {actionTitle?.length && (
            <Typography
              tag="p"
              className="text-xs text-black-secondary pt-8 pb-12 font-normal"
            >
              {actionTitle}
            </Typography>
          )}

          {renderProducts(items)}

          {windowsSprojs && multipleAccordions(windowsSprojs, dataContainer, accordionKey, iconKey, setSelectionAccordion)}
          {doorsSlider && !singleID?.includes("_f") && !singleID?.includes("_e") && !singleID?.includes("_d") && multipleAccordions(doorsSlider, dataContainer, accordionKey, iconKey, setSelectionAccordion)}
          {roofEntrance && !singleID?.includes("modern_a1") && multipleAccordions(roofEntrance, dataContainer, accordionKey, iconKey, setSelectionAccordion)}
        </>
      );
    case "multiple_childrens":
      return (
        childrensType?.length &&
          childrensType.filter(a => !a.name.includes("Options") 
          && !a.name.includes("Materials"))?.sort(
            (c1, c2) => c1.layer_order - c2.layer_order
          ).map((accordion, i) => (
            <Accordion
              key={i}
              name={dataContainer?.sectionKey(
                "Translate",
                accordion.name.toLowerCase().split(" ").join("_")
              )}
              isOpen={
                accordion.id === accordionKey || accordion.id === iconKey
              }
              className="border-b px-0 border-0"
              onChange={() => setSelectionAccordion(accordion.name && accordion.name)}
            >
              <SubAccordion
                filter={accordion?.filter}
                actionTitle={accordion?.actionsTitle}
                id={accordion.id}
                variant={dataContainer?.findVariationType(
                  accordion.name.split(" ").join("_")
                )}
                items={accordion.available_variations}
                options={accordion.available_variations?.options}
                label={accordion.name}
                dimensions={accordion.available_variations?.dimensions}
                dataContainer={dataContainer}
                setSelectionAccordion={setSelectionAccordion}
              />
            </Accordion>
          ))
      );
    case "options":
      return (
        <>
          <Typography
            tag="h3"
            className="text-black-secondary font-normal mt-6"
            parentClassName="h-auto"
          >
            {label
              ? dataContainer?.sectionKey(
                "Accordions Descriptions",
                label?.toLowerCase().split(" ").join("_")
              )
              : ""}
          </Typography>
          <div className="flex gap-16 pt-10 pb-8 flex-wrap">
            {items?.map((option) => {
              const key = `${label?.toLocaleLowerCase().split(" ").join("_")}_${option.original_key}`;
              const translatedName = dataContainer?.sectionKey("Options Descriptions", key);
              return(
                <RadioInput
                  label={dataContainer?.sectionKey("Options Labels", option.original_key)}
                  onChange={() => {
                    dataContainer.toggleVariationSelection(option.id);
                    (label?.includes("Fasad Type 1") && option.key.toLowerCase().includes("granpanel")) ? setGranPanel(true) : setGranPanel(false);
                  }}
                  checked={dataContainer?.isVariationSelected(option.id)}      
                  value={option.id}
                  name={label}
                  id={option.id}
                  key={option.id}
                  description={key === translatedName ? undefined : translatedName}
                />
              );
            })}
            {(label?.includes("Fasad Type 1") && granPanel) && (
              <ColorsGrid
                applyBottomBorder={true}
                className="border-t border-white-off"
              >
                {granPanelColors?.map((sub) => renderColors(sub))}
              </ColorsGrid>
            )}
          </div>
        </>
      );
    case "color":
      return (
        <>
          <Typography
            tag="h3"
            className="text-black-secondary font-normal sm:pb-8"
            parentClassName="h-auto"
          >
            {colorOptions
              ? dataContainer?.sectionKey(
                "Accordions Descriptions",
                label?.toLowerCase().split(" ").join("_")
              )
              : ""}
          </Typography>
          {colorOptions && (
            <div className="flex gap-16 pt-10 pb-8">
              {colorOptions?.map((option) => (
                <RadioInput
                  label={dataContainer?.sectionKey("Options Labels", option.original_key)}
                  onChange={() => {
                    dataContainer.toggleVariationSelection(option.id);
                  }}
                  checked={option.original_key}
                  value={option.id}
                  name={label}
                  id={option.id}
                  key={option.id}
                />
              ))}
            </div>
          )}
          <ColorsGrid
            applyBottomBorder={true}
            className="border-t border-white-off"
          >
            {items?.map((sub) => renderColors(sub))}
          </ColorsGrid>
        </>
      );
    }
  };

  return <>{renderSubAccordion()}</>;
};

export default SubAccordion;
