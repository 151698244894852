import React, { useContext, useEffect, useState } from "react";
import { Icon } from "../components/icon/Icon";
import { List } from "../components/list/List";
import { Logo } from "../components/logo/Logo";
import { Image } from "../components/image";
import { Typography } from "../components/typography";
import { useNavigate, useParams } from "react-router";
import { DataContainerContext } from "../common/context/DataContainerProvider";
import { Link } from "react-router-dom";
import useCheckMobileScreen from "../hooks/CheckMobileScreen";
import classNames from "classnames";

export const FloorPage = () => {
  const navigate = useNavigate();
  const { singleFloor } = useParams();
  const { dataContainer } = useContext(DataContainerContext);
  const floorData = dataContainer?.getFloorData(singleFloor, 0);
  const floorSizeData = dataContainer?.getFloorData(singleFloor, 1);
  const floorPdf = dataContainer?.getFloorPdfData(singleFloor);
  const [floorSelected, setFloorSelected] = useState(null);
  const [floorSizeSelected, setFloorSizeSelected] = useState(null);
  const isTabletLandscape = useCheckMobileScreen(1023);
  const [toggleList, setToggleList] = useState(true);

  const listOnChange = (value) => {
    setFloorSelected(value);
    setFloorSizeSelected(
      floorSizeData.filter((item) =>
        item?.assets?.filter((subItem) =>
          subItem.attribute_values.filter((s) =>
            s.value.includes(value.original_key)
          )
        )
      )
    );
  };

  useEffect(() => {
    if (floorData) {
      setFloorSelected(floorData[0]);
      sessionStorage.setItem("floorSelected", floorData[0].original_key);
    }
  }, [floorData]);

  useEffect(() => {
    if (floorSizeData && floorSelected) {
      setFloorSizeSelected(
        floorSizeData.filter((item) =>
          item?.assets?.filter((subItem) =>
            subItem.attribute_values.filter((s) =>
              s.value.includes(floorSelected.original_key)
            )
          )
        )
      );
    }
  }, [floorSizeData, floorSelected]);

  useEffect(() => {
    if (dataContainer) {
      dataContainer?.clearSelectionHooks();
      dataContainer?.clearSelectedVariations();
      dataContainer.logEvent("page_entered", "Floor Page", {
        product: "",
      });
    }
  }, [dataContainer]);

  return (
    <div className={classNames("relative w-full h-full bg-smoke grid sm:grid-cols-[25%_50%_25%] sm:grid-rows-[10%_65%_25%] grid-cols-1 overflow-hidden overflow-y-auto sm:overflow-y-hidden", {"!overflow-y-hidden" : !toggleList})}>
      <div className="sm:pl-14 px-10 sm:pr-0 sm:row-start-2 sm:col-start-1 sm:row-end-2 sm:col-end-1">
        <div className="flex flex-col">
          <button
            onClick={() => navigate("/")}
            className="flex items-center sm:opacity-40"
          >
            <Icon
              name="LeftArrow"
              className="w-[0.375rem] h-[0.813rem] sm:h-[1.125rem] sm:w-[0.625rem]"
            />
            <Typography
              tag="h4"
              className="text-primary text-xs ml-5 font-normal uppercase"
            >
              {dataContainer?.sectionKey("Translate", "house_model")}
            </Typography>
          </button>
          {floorData && (
            <List
              toggleList={toggleList}
              setToggleList={setToggleList}
              listOnChange={(value) => listOnChange(value)}
              items={floorData}
              title={
                isTabletLandscape
                  ? ""
                  : dataContainer?.sectionKey("Translate", "choose_plan")
              }
              className="mt-10 md:mt-6 sm:mt-10"
              listHidden={isTabletLandscape}
            />
          )}
        </div>
      </div>
      <div className="sm:row-start-2 sm:col-start-2 sm:row-end-2 sm:col-end-2 w-full h-full flex items-center justify-center mt-10 sm:mt-0 mb-8 sm:mb-0 px-10 sm:px-0">
        {floorSelected && (
          <Image
            title="floor"
            objectFit="contain"
            src={floorSelected?.assets?.[0]?.preview_image_url}
            className="sm:h-full"
          />
        )}
      </div>
      <div className="flex flex-col w-full px-10 sm:px-0 mt-6 sm:mt-0 sm:justify-center sm:row-start-2 row-start-3 sm:col-start-3 sm:row-end-2 sm:col-end-3 h-full">
        <div className="w-full">
          <Typography
            tag="h3"
            className="text-primary text-xs sm:text-lg font-normal sm:mb-5 mb-4"
            parentClassName="h-auto "
          >
            {floorSelected?.assets?.[0]?.attribute_values[0].value}
          </Typography>
          <Typography tag="p" parentClassName="h-auto" className="sm:text-xs mb-8 sm:pr-24 pr-4 text-black leading-5">{dataContainer?.sectionKey("Translate", "floor_page_text")}</Typography>
        </div>
      </div>
      <div className="sm:pl-14 sm:row-start-3 row-start-1 row-end-1 sm:col-start-1 sm:row-end-3 sm:col-end-1 h-full flex flex-col justify-start pt-4">
        <Link
          to={`/${singleFloor}/${floorSelected?.original_key}`}
          className="hidden sm:flex text-4xl text-primary font-normal py-3 px-2 border-primary_600 border-0 sm:border justify-center"
        >
          <span className="flex gap-4 hover:opacity-70 text-2xl sm:text-4xl">
            {dataContainer?.sectionKey("Translate", "design_your_house")}
            <Icon
              name="RightArrow"
              stroke="#006564"
              className="w-[0.375rem] h-[0.813rem] sm:h-[1.125rem] sm:w-[0.625rem]"
            />
          </span>
        </Link>
        <Logo
          to="/"
          className="mt-10 hidden left-10 sm:flex absolute bottom-5"
        />
        <Logo to="/" className="mx-auto my-12 sm:hidden" />
      </div>
      <div className="sm:row-start-3 row-start-5 sm:col-start-3 sm:row-end-3 sm:col-end-3 h-full flex flex-col items-center justify-end sm:items-start sm:justify-start md:justify-center mt-6 sm:mt-0 pb-16 sm:pb-0">
        {floorPdf && (
          <div>
            <a
              className="flex items-center sm:mt-8 mt-6"
              href={
                floorPdf[0]?.assets.filter((s) =>
                  s.attribute_values[0]?.value.toLowerCase().includes(
                    floorSelected?.original_key.toLowerCase(),
                  )
                )[0]?.asset_url
              }
            >
              <Icon name="DownloadFloor" />
              <Typography
                tag="p"
                className="text-black sm:text-xs text-3xl font-normal pl-4"
              >
                {dataContainer.sectionKey("Translate", "download_floor_plan")}
              </Typography>
            </a>
          </div>
        )}
      </div>
      <div className="sm:hidden flex items-center justify-center">
        <button
          className="flex text-4xl text-primary font-normal mb-8"
          onClick={() =>
            navigate(`/${singleFloor}/${floorSelected?.original_key}`)
          }
        >
          <span className="flex gap-4 hover:opacity-70 text-3xl sm:text-4xl py-3 px-2 border-primary_600 border">
            {dataContainer?.sectionKey("Translate", "design_your_house")}
            <Icon
              name="RightArrow"
              stroke="#006564"
              className="w-[0.4rem] h-[0.9rem] sm:h-[1.125rem] sm:w-[0.625rem]"
            />
          </span>
        </button>
      </div>
    </div>
  );
};
