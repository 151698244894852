export const handleDefaultHallFloor = (floorSelected, scene, hallFloorSelected) => {
  if(!floorSelected) return;

  const getDefaultHallFloor = scene?.getMeshByName("Floors_Hall");
  const floorSelectedMaterial = floorSelected?.mesh?.loadedMeshes?.[1]?.material;

  if(hallFloorSelected && getDefaultHallFloor){
    getDefaultHallFloor.isVisible = false;
  }

  if(floorSelectedMaterial && getDefaultHallFloor){
    getDefaultHallFloor.material = floorSelectedMaterial;
  }
};