import React from "react";
import "lazysizes";
import "lazysizes/plugins/attrchange/ls.attrchange";
import { ImageProps, SrcSet } from "./types";
import { BREAKPOINTS } from "./constants";
import classnames from "classnames";

export function Image({
  title,
  sources,
  lowResImageSrc,
  src,
  width,
  height,
  objectFit,
  className,
  objectPosition = "50% 50%",
  pictureClassName,
}: ImageProps): JSX.Element {
  const imageWidthClass: string = width || height ? "" : "w-full";

  const styles = {
    objectFit,
    objectPosition,
  };

  const getSrcSets = () => {
    return sources
      ? Object.entries(sources).filter((entry) => entry[1].srcset)
      : [];
  };

  return (
    <picture className={classnames("h-full", pictureClassName)}>
      {getSrcSets().length ? (
        getSrcSets().map((srcSet: SrcSet) => (
          <source
            key={srcSet[0]}
            data-srcset={srcSet[1].srcset}
            media={`(min-width: ${BREAKPOINTS[srcSet[0]]})`}
            type={srcSet[1].type}
          />
        ))
      ) : (
        <source data-srcset={src} />
      )}
      {src && (
        <img
          src={lowResImageSrc ? lowResImageSrc : src}
          data-src={src}
          alt={title}
          className={classnames(
            "lazyload",
            "max-w-full",
            imageWidthClass,
            className
          )}
          style={{ height, width, ...styles }}
        />
      )}
    </picture>
  );
}
