export * from "./HomePage";
export * from "./OrderPage";
export * from "./FloorPage";
export * from "./ConfirmationPage";
export * from "./AugmentedRealityPage";
import BabylonProvider from "../common/context/BabylonProvider";
import { SinglePage } from "./SinglePage";

const SingleProduct = () => (
  <BabylonProvider>
    <SinglePage />
  </BabylonProvider>
);

export { SingleProduct };
