import React, { useEffect, useRef } from "react";

import { Typography } from "../typography";
import { TextInput } from "../input";
import { generateUID } from "src/services/Helpers";
import { Button } from "../button/Button";
import { ModalContactProps } from "./Types";
import useCheckMobileScreen from "../../../src/hooks/CheckMobileScreen";
import classnames from "classnames";

export const ModalContactForm = ({
  dataContainer,
  handleSubmit,
  handleChange,
  data,
  errors,
  isCorrect,
  setIsOpen,
}: ModalContactProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const isMobile = useCheckMobileScreen();

  useEffect(() => {
    const handleOnDocumentClick = (e) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(e.target) &&
        setIsOpen
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("click", handleOnDocumentClick, true);

    return () => {
      document.removeEventListener("click", handleOnDocumentClick);
    };
  }, [modalRef]);

  const handleOnCloseButtonClick = () => setIsOpen && setIsOpen(false);

  return (
    <div
      className={classnames(
        "w-full h-full flex flex-col justify-center items-center absolute z-[20] sm:z-[15]",
        {
          "h-24 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] sm:w-[60%] bg-white":
            isCorrect,
        }
      )}
    >
      <div
        className={classnames(
          {
            "py-0 px-10 flex items-center justify-center": isCorrect,
          },
          "max-w-full max-h-full h-full sm:max-w-[90%] sm:w-[600px] sm:max-h-[70%] overflow-scroll bg-white p-10 relative md:flex md:items-center md:justify-center"
        )}
        ref={modalRef}
      >
        <div
          className={classnames(
            { hidden: isCorrect },
            "absolute sm:right-[2.5rem] sm:top-[2rem] top-5 right-5 bg-white"
          )}
        >
          <Button
            icon={"Close"}
            className="mr-auto"
            onClick={handleOnCloseButtonClick}
          />
        </div>
        {isCorrect ? (
          <h2 className="text-center text-primary text-4xl py-4">
            {dataContainer?.sectionKey(
              "Translate",
              "contact_modal_confirmation"
            )}
          </h2>
        ) : (
          <div className="mt-5">
            <h2 className="text-4xl text-primary mb-5">
              {dataContainer?.sectionKey("Translate", "contact_modal_title")}
            </h2>
            <p className="text-3xl text-black border-b pb-5 border-gray-mid">
              {dataContainer?.sectionKey("Translate", "contact_modal_text")}
            </p>
            <Typography
              tag="h4"
              className="pt-4 font-medium text-black mb-5"
              parentClassName="h-auto"
            >
              {dataContainer?.sectionKey("Translate", "form_label")}
            </Typography>
            <form onSubmit={handleSubmit}>
              <div className="grid sm:grid-cols-2 grid-cols-1 gap-x-4 sm:gap-y-2 gap-y-3 w-full mb-5">
                <TextInput
                  id="firstName"
                  value={data.firstName}
                  placeholder="Förnamn*"
                  onChange={handleChange}
                  error={errors?.firstName}
                  parentClassName="order-1 sm:order-none"
                />
                <TextInput
                  type="email"
                  id="email"
                  value={data.email}
                  placeholder="E-post*"
                  onChange={handleChange}
                  error={errors?.email}
                  parentClassName="order-2 sm:order-none"
                />
                <TextInput
                  id="phoneNumber"
                  value={data.phoneNumber}
                  placeholder="Telefonnummer"
                  onChange={handleChange}
                  error={errors?.phoneNumber}
                  parentClassName="order-3 sm:order-none"
                />
                <TextInput
                  id="country"
                  value={data.country}
                  placeholder="Ort*"
                  onChange={handleChange}
                  error={errors?.country}
                  parentClassName="order-4 sm:order-none"
                />
              </div>
              <div className="mb-12">
                {Object.keys(errors).map(
                  (key) =>
                    errors[key] && (
                      <p
                        className="text-red text-xs mb-3.5 pl-5"
                        key={generateUID(9)}
                      >
                        {errors[key]}
                      </p>
                    )
                )}
              </div>
              <div className="overflow-hidden">
                <label
                  className="text-3xl text-black font-medium mb-3"
                  htmlFor="message"
                >
                  {dataContainer?.sectionKey(
                    "Translate",
                    "contact_modal_help_text"
                  )}
                </label>
                <textarea
                  rows={5}
                  id="message"
                  name="message"
                  placeholder={
                    isMobile
                      ? "Skriv din fråga här."
                      : "Om du har en särskild önskan skriv detta här."
                  }
                  className="w-full sm:p-5 p-3 text-3xl sm:mt-3 mt-5 bg-gray-light resize-none outline-0 placeholder:text-black placeholder:text-3xl block placeholder:font-normal placeholder:opacity-50"
                  value={data.message}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col sm:flex-row sm:mt-5 mt-6">
                <div className="max-w-full sm:max-w-[50%] w-full flex justify-between items-center md:justify-start">
                  <input
                    type="checkbox"
                    name="terms"
                    id="terms"
                    className="flex items-center mr-5"
                    onChange={handleChange}
                  />
                  <label
                    htmlFor="terms"
                    className="sm:text-4xl text-3xl text-black"
                  >
                    {dataContainer?.sectionKey("Translate", "policy_text")}
                    {
                      <a
                        href={dataContainer?.sectionKey(
                          "Translate",
                          "policy_link"
                        )}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="ml-2 text-primary"
                      >
                        policy
                      </a>
                    }
                  </label>
                </div>
                <Button
                  label={dataContainer?.sectionKey(
                    "Translate",
                    "contact_modal_button"
                  )}
                  icon="RightGreenArrow"
                  className={classnames(
                    "flex flex-row-reverse justify-center w-full sm:w-auto bg-white py-3 px-6 text-primary border sm:mt-6 mt-10 ml-auto",
                    {
                      "border-primary": Object.keys(errors).length === 0,
                    }
                  )}
                  iconClassName="pl-4 stroke-primary"
                  onClick={() => ({})}
                />
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
