import React from "react";
import { ColorsGridProps } from "./types";
import classnames from "classnames";

export const ColorsGrid = ({
  applyBottomBorder,
  children,
  className,
}: ColorsGridProps) => {
  return (
    <div
      className={classnames(
        "py-[1.875rem] grid grid-cols-6 md:grid-cols-6 sm:grid-cols-7 gap-4 md:gap-1 w-full",
        {
          "border-b border-white-off": applyBottomBorder,
        },
        className
      )}
    >
      {children}
    </div>
  );
};
