import { AbstractAssetTask, AbstractMesh, MeshAssetTask } from "@babylonjs/core";
import { AccordionEnum } from "src/services/enums";

let allPivotMeshes: AbstractMesh[] = [];
let allGableMeshes: AbstractMesh[] = [];

export const saveGableTaskData = (tasks: AbstractAssetTask[]) => {
  allPivotMeshes = [];
  allGableMeshes = [];

  tasks.forEach((task) => {
    if (task.name.includes(AccordionEnum.Pivot_Type) ||
     task.name.includes("Pivot Fasad Type")) {
      const assetTask = task as MeshAssetTask;
      const taskNameParts = task.name.split("_");
      const variationName = taskNameParts?.[1];
      
      if (!variationName) {
        return;
      }

      assetTask?.loadedMeshes?.forEach((mesh) => {
        allPivotMeshes.push(mesh);
        if (!mesh.metadata) {
          mesh.metadata = {};
        }
        mesh.metadata.type = taskNameParts?.[1];
        mesh.metadata.cat = taskNameParts?.[3];
      });
    }
    if (task.name.includes(AccordionEnum.Gable_Type) || 
      task.name.includes("Side Sliding Type") ||
      task.name.includes("Side Gable Fasad Type") ||
     task.name.includes("Gable Fasad Type")) {
      const assetTask = task as MeshAssetTask;
      const taskNameParts = task.name.split("_");
      const variationName = taskNameParts?.[1];
  
      if (!variationName) {
        return;
      }
  
      assetTask?.loadedMeshes?.forEach((mesh) => {
        allGableMeshes.push(mesh);
  
        if (!mesh.metadata) {
          mesh.metadata = {};
        }
        mesh.metadata.task = taskNameParts?.[2].split(" - ")?.[0];
      });
    }
  });
};

const hideAllPivotMeshes = () => {
  allPivotMeshes?.forEach((mesh) => (mesh.isVisible = false));
};

const showPivotMeshes = (scene, dataContainer, cat) => {
  const fasadh01 = scene?.getMeshByName("Facade_H_Type_01")?.isVisible;
  const fasadh02 = scene?.getMeshByName("Facade_H_Type_02")?.isVisible;
  const fasadv01 = scene?.getMeshByName("Facade_V_Type_01")?.isVisible; 
  const fasadv02 = scene?.getMeshByName("Facade_V_Type_02")?.isVisible; 
  const fasadv03 = scene?.getMeshByName("Facade_V_Type_03")?.isVisible; 
  const fasadv04 = scene?.getMeshByName("Facade_V_Type_04")?.isVisible;
  const wallSelected = dataContainer?.findSelectedVariantByComponentNameAndCat("Walls", cat);
  const sprojsSelected = dataContainer?.findSelectedVariantByComponentNameAndCat("Windows Sprojs", cat);

  const showHideFasadePivot = (mesh) => {
    if(fasadh01 && mesh?.name.includes("_H_Type_01")){
      mesh.isVisible = true;
    }
    if(fasadh02 && mesh?.name.includes("_H_Type_02")){
      mesh.isVisible = true;
    }
    if(fasadv01 && mesh?.name.includes("_V_Type_02")){
      mesh.isVisible = true;
    }
    if(fasadv02 && mesh?.name.includes("_V_Type_02")){
      mesh.isVisible = true;
    }
    if(fasadv03 && mesh?.name.includes("_V_Type_03")){
      mesh.isVisible = true;
    }
    if(fasadv04 && mesh?.name.includes("_V_Type_04")){
      mesh.isVisible = true;
    }
  };
  
  const showHideWallsPivot = (mesh) => {
    if(wallSelected?.original_key?.includes("bjorkplywood")
      && (mesh?.name?.includes("Plywood") || mesh?.name?.includes("Side_Pivot_Walls_Plywood"))){
      mesh.isVisible = true;
    }
    if(wallSelected?.original_key?.includes("innerpanel") 
    && (mesh?.name?.includes("Cladding") || mesh?.name?.includes("Side_Pivot_Walls_Cladding"))){
      mesh.isVisible = true;
      mesh.material = wallSelected?.mesh?.loadedMeshes?.[0].material;
    }
  };

  const showHideSprojs = (mesh) => {
    if(mesh.name.includes("Gable_Pivot_Crossbar") && sprojsSelected){
      mesh.isVisible = true;
    }
  };

  allPivotMeshes.forEach((mesh) => {
    if(!mesh.name.includes("Facade_Gable")){
      mesh.isVisible = true;
    }
    if(
      mesh.name === "Gable_Pivot_Crossbar" 
    || mesh.name.includes("Walls_Gable_Pivot") 
    || mesh.name.includes("_Type_")
    || mesh.name.includes("Walls")
    ){
      mesh.isVisible = false;
    }
    showHideFasadePivot(mesh);
    showHideWallsPivot(mesh);
    showHideSprojs(mesh);
  });
};

const hideAllGableMeshes = () => {
  allGableMeshes?.forEach((mesh) => (mesh.isVisible = false));
};
const showGableMeshes = (dataContainer, cat, scene) => {
  const wallSelected = dataContainer?.findSelectedVariantByComponentNameAndCat("Walls", cat);
  const sprojsSelected = dataContainer?.findSelectedVariantByComponentNameAndCat("Windows Sprojs", cat);
  const gableSprojs = scene.getMeshByName("Gable_Opening_Crossbar");
  const showHideWallsPivot = (mesh) => {
    if(wallSelected?.original_key?.includes("bjorkplywood")&& mesh?.name?.toLowerCase().includes("plywood") ){
      mesh.isVisible = true;
    }
    if(wallSelected?.original_key?.includes("innerpanel") && mesh?.name?.toLowerCase().includes("cladding")){
      mesh.isVisible = true;
      mesh.material = wallSelected?.mesh?.loadedMeshes?.[0].material;
    }
  };

  const showHideSprojs = (mesh) => {
    if(mesh.name.toLowerCase().includes("crossbar") && sprojsSelected){
      mesh.isVisible = true;
    }
    if(gableSprojs && sprojsSelected) gableSprojs.isVisible = true;
  };
  allGableMeshes.forEach((mesh) => {
    mesh.isVisible = true;

    if(
      mesh.name.toLowerCase().includes("crossbar") 
    || mesh.name.toLowerCase().includes("walls") 
    ){
      mesh.isVisible = false;
    }

    if(gableSprojs) gableSprojs.isVisible = false;
    if(mesh.name.toLowerCase()?.includes("crossbar")){
      mesh.isVisible = false;
    }
    showHideWallsPivot(mesh);
    showHideSprojs(mesh);
  });
};

export const handleGable = (roofEntranceType, scene, dataContainer, cat) => {
  if(!roofEntranceType) return;
  
  if(roofEntranceType?.original_key.toLowerCase().includes("gable_opening")){
    hideAllPivotMeshes();
    showGableMeshes(dataContainer, cat, scene);
  }
  if(roofEntranceType?.original_key.toLowerCase().includes("pivot")){
    hideAllGableMeshes();
    showPivotMeshes(scene, dataContainer, cat);
  }
};