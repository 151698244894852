import React, { useContext, useEffect, useState } from "react";
import classnames from "classnames";
import { Logo } from "../logo/Logo";
import { Typography } from "../typography";
import { FooterProps } from "./types";
import { BottomMenu } from "../bottomMenu/BottomMenu";
import { Icon } from "../icon/Icon";
import { BabylonContext } from "../../common/context/BabylonProvider";
import { DataContainerContext } from "../../../src/common/context/DataContainerProvider";

export const Footer = ({
  product,
  menuItems,
  className,
  setAccordionSelected,
  setPointView,
}: FooterProps) => {
  const { state } = useContext(BabylonContext);
  const [active, setActive] = useState();
  const { dataContainer } = useContext(DataContainerContext);

  useEffect(() => {
    if (state.pointView) {
      setActive(product?.items[0]?.name);
    }
  }, []);

  useEffect(() => {
    if (state.pointView) {
      setActive(state.pointView);
    }
  }, [state.pointView]);

  return (
    <div
      className={classnames(
        "flex absolute bottom-0 left-0 w-full sm:justify-between bg-white",
        className
      )}
    >
      <div className="relative flex sm:flex w-full">
        <div className="hidden sm:flex flex-1">
          <div className="hidden md:w-16 sm:mr-0 w-40 sm:flex justify-center items-center md:pl-2 md:pr-2 pt-[1.125rem] pb-[1.188rem]">
            <Logo to="/" />
          </div>
          <div className="w-full sm:w-auto flex">
            {product?.items?.map((cat, key) => (
              <div
                onClick={() => {
                  setPointView(cat, cat.name);
                }}
                key={key}
                className={classnames(
                  "relative flex flex-1 sm:flex-none cursor-pointer items-center sm:px-[1.375rem] sm:pt-[1.313rem] sm:pb-[1.375rem] sm:gap-[1.313rem] justify-center sm:justify-items-start transition ease-in-out delay-150 px-11 py-7",
                  { "text-white bg-secondary": active === cat?.name },
                  { "text-black bg-white": active !== cat?.name },
                  className
                )}
              >
                <div className="flex gap-2 items-center">
                  <Icon
                    name={cat.name}
                    fill={active === cat?.name ? "#fff" : "#333333"}
                  />
                  <Typography
                    tag="h4"
                    className={classnames("text-3xl font-medium leading-3", {
                      "text-white opacity-70": active === cat?.name,
                    })}
                  >
                    {dataContainer.sectionKey(
                      "Translate",
                      cat.name.toLocaleLowerCase()
                    )}
                  </Typography>
                </div>
              </div>
            ))}
          </div>
        </div>
        <BottomMenu
          items={menuItems}
          setAccordionSelected={setAccordionSelected}
          className="sm:flex hidden mx-auto justify-center ml-8"
        />
        <div className="hidden sm:flex flex-col justify-start items-end pr-8 xl:pr-24 py-5 flex-1">
          <Typography tag="h4" className="text-4xl text-primary font-thin">
            {product && product.name}
          </Typography>
          <Typography tag="p" className="text-4xl">
            {product && product.model}
          </Typography>
        </div>
      </div>
    </div>
  );
};
