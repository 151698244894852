import React, { useMemo } from "react";
import { Image } from "../../image";
import { Typography } from "../../typography";
import { ProductProps } from "./types";
import { Button } from "../../button/Button";
import classnames from "classnames";

const buttonVariants = {
  addButton: {
    black: "BlackPlus",
    white: "WhitePlus",
  },
  removeButton: {
    black: "BlackMinus",
  },
};

export const Product = ({
  name,
  description,
  price,
  image,
  addButton,
  removeButton,
  className,
  glassButton,
}: ProductProps) => {
  const getPrice = () => {
    return `${price?.prefix || ""} ${price?.value || ""} ${
      price?.currency || ""
    }`;
  };

  const ImageMemo = useMemo(
    () => (
      <Image
        {...image}
        className={classnames(
          { "opacity-60": glassButton?.isShown },
          image.className
        )}
      />
    ),
    [image.src]
  );

  return (
    <div
      className={`flex py-5 border-b w-full border-white-off justify-between items-center ${className}`}
    >
      <div className="flex gap-4 md:gap-8 md:items-center ">
        <div className="flex relative items-center justify-center w-[3.375rem] h-[3.375rem] md:w-[4.125rem] md:h-[4.125rem]">
          {ImageMemo}
          {glassButton && (
            <Button
              icon="Search"
              className={classnames(
                "absolute h-full w-full flex items-center justify-center",
                { "opacity-100 visible": !glassButton?.isShown },
                { "opacity-0 invisible": glassButton?.isShown }
              )}
              onClick={glassButton?.onClick}
            />
          )}
        </div>
        <div className="flex flex-col md:gap-4 leading-4 w-full">
          <Typography
            tag="h4"
            className="text-4xl font-medium"
          >
            {name}
          </Typography>
          <Typography
            tag="p"
            className="text-3xl mt-3 md:mt-0 leading-3 font-medium"
          >
            {description}
          </Typography>
          <Typography
            tag="p"
            className="text-3xl mt-[1.125rem] md:mt-0 l leading-3 font-medium"
          >
            {getPrice()}
          </Typography>
        </div>
      </div>
      <div className="flex gap-4 px-2">
        {removeButton?.isShown && (
          <Button
            icon={"BlackMinus"}
            onClick={removeButton?.onClick}
            className="hover:opacity-80 active:opacity-90"
          />
        )}
        {addButton?.isShown && (
          <Button
            icon={
              buttonVariants.addButton[
                addButton.variant ? addButton.variant : "BlackPlus"
              ]
            }
            onClick={addButton?.onClick}
            className={classnames(
              "cursor-pointer hover:opacity-80 active:opacity-90",
              { "cursor-not-allowed": addButton.variant === "white" }
            )}
          />
        )}
      </div>
    </div>
  );
};
