import React from "react";
import { generateUID } from "src/services/Helpers";
import { TextInput } from "../input";
import { Typography } from "../typography";
import { UseFormValues } from "./Types";

export const ContactForm = ({
  data,
  errors,
  handleChange,
  handleSubmit,
  dataContainer,
}: UseFormValues) => (
  <div className=" mt-5 border-t border-gray-mid">
    <Typography
      tag="h4"
      className="pt-5 font-medium text-black mb-5"
      parentClassName="h-auto"
    >
      {dataContainer?.sectionKey("Translate", "form_label")}
    </Typography>
    <form onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-4 gap-y-2 w-full mb-5">
        <TextInput
          id="firstName"
          value={data.firstName}
          placeholder="Förnamn*"
          onChange={handleChange}
          error={errors?.firstName}
        />
        <TextInput
          type="email"
          id="email"
          value={data.email}
          placeholder="E-post*"
          onChange={handleChange}
          error={errors?.email}
        />
        <TextInput
          id="lastName"
          value={data.lastName}
          placeholder="Efternamn*"
          onChange={handleChange}
          error={errors?.lastName}
        />
        <TextInput
          id="phoneNumber"
          value={data.phoneNumber}
          placeholder="Telefonnummer"
          onChange={handleChange}
          error={errors?.phoneNumber}
        />
        <TextInput
          id="address"
          value={data.address}
          placeholder="Adress*"
          onChange={handleChange}
          error={errors?.address}
        />
        <TextInput
          id="co"
          value={data.co}
          placeholder="C/O"
          onChange={handleChange}
          error={errors?.co}
        />
        <TextInput
          id="zipCode"
          value={data.zipCode}
          placeholder="Postnummer*"
          onChange={handleChange}
          error={errors?.zipCode}
        />
        <TextInput
          id="country"
          value={data.country}
          placeholder="Ort*"
          onChange={handleChange}
          error={errors?.country}
          parentClassName="col-start-1 col-end-2"
        />
      </div>
      <div className="mb-12">
        {Object.keys(errors).map(
          (key) =>
            errors[key] && (
              <p className="text-red text-xs mb-3.5 pl-5" key={generateUID(9)}>
                {errors[key]}
              </p>
            )
        )}
      </div>
      <div>
        <label
          className="text-3xl text-black font-medium mb-3"
          htmlFor="message"
        >
          {dataContainer?.sectionKey("Translate", "form_message_label")}
        </label>
        <textarea
          rows={5}
          id="message"
          name="message"
          placeholder="Om du har en särskild önskan skriv detta här."
          className="w-full p-5 text-3xl mt-3 bg-gray-light resize-none outline-0 placeholder:text-black placeholder:text-3xl placeholder:font-normal placeholder:opacity-50"
          value={data.message}
          onChange={handleChange}
        />
      </div>
      <div className="flex mt-4">
        <input
          type="checkbox"
          name="terms"
          id="terms"
          className="flex items-center mr-5"
          onChange={handleChange}
        />
        <label htmlFor="terms" className="text-4xl text-[#333]">
          {dataContainer?.sectionKey("Translate", "policy_text")}
          {
            <a
              href={dataContainer?.sectionKey(
                "Translate",
                "policy_link"
              )}
              target="_blank"
              rel="noopener noreferrer"
              className="ml-2 text-primary"
            >
              policy
            </a>
          }
        </label>
      </div>
    </form>
  </div>
);
