export const AccordionEnum = {
  Roof: "Roof",
  Model: "Model",
  Exterior: "Exterior",
  Interior: "Interior",
  Kitchen: "Kitchen",
  Furniture: "Furniture Types 1",
  Furniture_Types_2: "Furniture Types 2",
  RoofEntranceType: "Roof Entrance Type",
  Roof_Entrance: "Roof Entrance",
  Pivot_Type: "Pivot Type",
  Gable_Type: "Gable Type",
  Sliding_Windows: "Sliding Windows",
  Fasad_2: "Fasad Type 2",
  Fasad_3: "Fasad Type 3",
  Fasad_3_Appendix: " - Knutbrädor & Foder"
} as const;

export const CategoryIds = {
  houses: [
    { id: 23, name: "HÅLLBAR" },
    { id: 24, name: "MODERN" },
    { id: 25, name: "KLASSISK" },
    { id: 26, name: "FRAMTID" },
  ],
};

export const AccessoriesCategoryId = 28;

export const SinglePageEnum = {
  Accessories: "Accessories",
};
export const singleAccordionsTypes = ["Size", "Type", "Colors", "Options"];

export const loadingText = "Din husmodell förbereds!";

export const excludedComponents = [
  "camera",
  "environment",
  "exterior handles",
  "kitchen",
  "room handles",
  "bathroom handles",
  "hallway floors - options",
  "default hall floor",
  "loft",
  "roof trim",
  "fasad trim",
  "camera and lights",
  "roof materials",
  "bathroom type 1 materials",
  "floors materials",
  "bathroom type 2 materials",
  "kitchen type 1 materials",
  "walls materials",
  "kitchen type 1 materials",
  "kitchen type 2 materials",
  "kitchen type 3 materials",
  "hallway floors materials",
];

export const DefaultCameraName = "OrbitCamera";

export const Sides = {
  Exterior: "Exterior",
  Interior: "Interior",
};
