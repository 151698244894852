import React from "react";
import { IconProps } from "../Types";

const Walls = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="25"
      height="25"
      viewBox="0 0 19 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10222_30797)">
        <path
          d="M2.38672 16.1676C2.38672 16.6312 2.75573 17.0002 3.21937 17.0002C3.683 17.0002 4.05202 16.6312 4.05202 16.1676C4.05202 15.704 3.21937 13.1587 3.21937 13.1587C3.21937 13.1587 2.38672 15.704 2.38672 16.1676Z"
          fill="#333"
        />
        <path
          d="M6.41772 16.1676C6.41772 16.6312 6.78674 17.0002 7.25038 17.0002C7.71401 17.0002 8.08303 16.6312 8.08303 16.1676C8.08303 15.704 7.25038 13.1587 7.25038 13.1587C7.25038 13.1587 6.41772 15.704 6.41772 16.1676Z"
          fill="#333"
        />
        <path
          d="M10.4485 16.1676C10.4485 16.6312 10.8175 17.0002 11.2811 17.0002C11.7448 17.0002 12.1138 16.6312 12.1138 16.1676C12.1138 15.704 11.2811 13.1587 11.2811 13.1587C11.2811 13.1587 10.4485 15.704 10.4485 16.1676Z"
          fill="#333"
        />
        <path
          d="M17.753 0.470215H11.1202C8.6601 0.470215 6.66363 2.46668 6.66363 4.92679V7.57613C4.40222 7.72752 2.26383 8.91972 0.598527 10.9919C0.484984 11.1338 0.46606 11.3325 0.541755 11.4934C0.617451 11.6542 0.787766 11.7583 0.967542 11.7583H13.3154C13.4951 11.7583 13.6655 11.6542 13.7412 11.4934C13.8169 11.3325 13.7979 11.1338 13.6844 10.9919C12.0191 8.91026 9.88068 7.71806 7.61928 7.57613V4.92679C7.61928 2.99655 9.18996 1.41641 11.1297 1.41641H17.7625C18.0274 1.41641 18.2356 1.20825 18.2356 0.943312C18.2356 0.678377 18.0274 0.470215 17.7625 0.470215H17.753ZM2.02728 10.8216C3.50334 9.31712 5.29165 8.49394 7.14619 8.49394C9.00072 8.49394 10.7796 9.30766 12.2651 10.8216H2.02728Z"
          fill="#333"
        />
      </g>
      <defs>
        <clipPath id="clip0_10222_307978">
          <rect
            width="25"
            height="25"
            fill="white"
            transform="translate(0.503906 0.470215)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Walls;
