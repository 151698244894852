export const handleInteriorDoors = (scene) => {
  if(!scene) return;

  if(scene?.activeCamera?.id?.includes("Bathroom_01")){
    const doorBath1 = scene.getMeshByName("Doors_Bath_01");
    const doorBath2 = scene.getMeshByName("Doors_Bath_02");
    const doorBath3 = scene.getMeshByName("Doors_Bath_03");
    const bathHandle = scene.getMeshByName("Doors_Handles_Bath");
  
    doorBath1 && (doorBath1.isVisible = false);
    doorBath2 && (doorBath2.isVisible = false);
    doorBath3 && (doorBath3.isVisible = false);
    bathHandle && (bathHandle.isVisible = false);
  }
  const door1Room2 = scene.getMeshByName("Doors_Room2_01");
  const door2Room2 = scene.getMeshByName("Doors_Room2_02");
  const door2Room3 = scene.getMeshByName("Doors_Room2_03");
  const door2Handles = scene.getMeshByName("Doors_Handles_Room2");
  if(scene?.activeCamera?.id?.includes("Hall")){
    door2Handles.isVisible = false;
    door1Room2.isVisible = false;
    door2Room2.isVisible = false;
    door2Room3.isVisible = false;
  }

  if(scene?.activeCamera?.id?.includes("Bathroom_02")){
    const doorBath1 = scene.getMeshByName("Doors_Bath_01");
    const doorBath2 = scene.getMeshByName("Doors_Bath_02");
    const doorBath3 = scene.getMeshByName("Doors_Bath_03");
    const bathHandle = scene.getMeshByName("Doors_Handles_Bath");
  
    doorBath1 && (doorBath1.isVisible = false);
    doorBath2 && (doorBath2.isVisible = false);
    doorBath3 && (doorBath3.isVisible = false);
    bathHandle && (bathHandle.isVisible = false);
  }
  
  if(scene?.activeCamera?.id?.includes("Kitchen")){
    const doorRoom1 = scene.getMeshByName("Doors_Room_01");
    const doorRoom2 = scene.getMeshByName("Doors_Room_02");
    const doorRoom3 = scene.getMeshByName("Doors_Room_03");
    const roomHandle = scene.getMeshByName("Doors_Handles_Room");
  
    doorRoom1 && (doorRoom1.isVisible = false);
    doorRoom2 && (doorRoom2.isVisible = false);
    doorRoom3 && (doorRoom3.isVisible = false);
    roomHandle && (roomHandle.isVisible = false);
  }
};