import React, { useState } from "react";
import { ModalProps } from "./Types";
import { ModalContactForm } from "../modalContactForm/ModalContactForm";
import { useForm } from "../modalContactForm/useForm";

export const ContactModal = ({ isOpen, dataContainer, setIsOpen }: ModalProps) => {
  const [isCorrent, setIsCorrect] = useState(false);
  const getUniqueHashForSelections = () => {
    return dataContainer?.generateShareableLink(undefined);
  };

  const contactForm = useForm({
    onSubmit: async (data) => {
      const request = {
        properties: {
          first_name: data.firstName,
          email: data.email,
          phone_number: data.phoneNumber,
          country: data.country,
          configuration_link: getUniqueHashForSelections(),
        },
      };

      dataContainer.submitForm(request, (res) => {
        if (res) {
          setIsCorrect(true);
        }
      });
    },
  });

  return (
    <>
      {isOpen && (
        <ModalContactForm
          data={contactForm.data}
          errors={contactForm.errors}
          handleChange={contactForm.handleChange}
          handleSubmit={contactForm.handleSubmit}
          dataContainer={dataContainer}
          isCorrect={isCorrent}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};
