import { useContext, useEffect, useState } from "react";
import { DataContainerContext } from "../common/context/DataContainerProvider";

const THIRTY_MINUTES = 30 * 60 * 1000;

export const useSession = () => {
  const { dataContainer } = useContext(DataContainerContext);
  const [sessionExpired, setSessionExpired] = useState(false);

  const handleVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      const sessionStart = new Date(localStorage.getItem("sessionStart") || "");
      const sessionEnd = new Date();
      const duration = sessionEnd.getTime() - sessionStart.getTime();
      if (duration > THIRTY_MINUTES) {
        setSessionExpired(true);
        dataContainer.logEvent(
          "session_end",
          "",
          {
            duration,
          },
          true
        );
      }
    }
  };

  const handlePageUnload = () => {
    const sessionStart = new Date(localStorage.getItem("sessionStart") || "");
    const sessionEnd = new Date();
    const duration = sessionEnd.getTime() - sessionStart.getTime();
    dataContainer.logEvent(
      "session_end",
      "",
      {
        duration,
      },
      true
    );
  };

  const startSession = () => {
    const date = new Date();
    localStorage.setItem("sessionStart", date.toString());
    dataContainer?.generateSessionId();
    dataContainer.logEvent(
      "session_start",
      "",
      {
        start_time: date.getTime(),
        source: dataContainer.getDeviceType(),
      },
      true
    );
  };

  useEffect(() => {
    if (!dataContainer) return;

    startSession();

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("beforeunload", handlePageUnload);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("beforeunload", handlePageUnload);
    };
  }, [dataContainer]);

  useEffect(() => {
    if (sessionExpired) {
      startSession();
      setSessionExpired(false);
    }
  }, [sessionExpired]);

  return null;
};
