import React from "react";
import { IconProps } from "../Types";

const MenuArrow = ({ className }: IconProps) => (
  <svg
    className={className}
    width="23"
    height="10"
    viewBox="0 0 23 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M22 9L11.5 1L0.999999 9" stroke="#A2A2A2" strokeLinecap="round" />
  </svg>
);

export default MenuArrow;
