import React, { useState, useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { List } from "../components/list/List";
import { Logo } from "../components/logo/Logo";
import { DataContainerContext } from "../common/context/DataContainerProvider";
import { CategoryIds, loadingText } from "../services/enums";
import "../components/productIntro/style.css";
import { Image } from "../components/image";
import { Icon } from "../components/icon/Icon";
import LoadingModal from "../components/loadingModal/LoadingModal";

function HomePage() {
  const [isSwitch, setIsSwitch] = useState(0);
  const [selectedCategory, setSelectedCategory] =
    useState<{ id: number; name: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const productType = location.pathname === "/" && "houses";
  const { dataContainer } = useContext(DataContainerContext);

  useEffect(() => {
    if (dataContainer) {
      dataContainer
        .getSelectedVariations()
        .forEach((variation) =>
          dataContainer.toggleVariationSelection(variation.id)
        );

      dataContainer.logEvent("page_entered", "Homepage", {
        product: "",
      });
    }
  }, [dataContainer]);

  useEffect(() => {
    const categories = Object.keys(CategoryIds[productType]);
    setSelectedCategory({
      id: CategoryIds[productType][categories[0]].id,
      name: categories[0],
    });
  }, [productType]);

  const products = dataContainer?.getAllModelsVariations([
    selectedCategory?.id,
  ]);

  const productCategories = Object.keys(CategoryIds[productType]).map(
    (productCategory) => {
      const value = {
        id: CategoryIds[productType][productCategory].id,
        value: productCategory,
      };
      return {
        id: value.id,
        name: dataContainer
          ?.sectionKey(
            "Translate",
            CategoryIds[productType][productCategory].name
              .replace(" ", "_")
              .toLowerCase()
          )
          .toUpperCase(),
        value,
      };
    }
  );

  return (
    <div className="w-full h-full bg-smoke flex flex-col sm:flex-row gap-5 overflow-x-hidden">
      {dataContainer ? (
        <>
          <div className="sm:relative w-full sm:w-1/3 sm:h-full h-1/2">
            <div className="pt-6 sm:justify-center justify-start sm:pt-0 sm:fixed sm:top-0 left-0 h-full sm:w-1/5 flex items-start flex-col pb-5">
              <Logo
                to="/"
                className="absolute sm:hidden mt-6 left-1/2 -translate-x-1/2"
              />
              <div className="sm:px-10 px-6 sm:pt-0 pt-8 sm:mb-14">
                <List
                  listOnChange={(item) => {
                    setSelectedCategory({
                      id: item.value?.id,
                      name: item.value?.value,
                    });
                    setIsSwitch(isSwitch ? 0 : 1);
                  }}
                  listUsedOnHomepage
                  items={productCategories}
                  title={dataContainer?.sectionKey(
                    "Translate",
                    "homepage_list_title"
                  )}
                  className="text-4xl sm:text-6xl"
                />
              </div>
              <button
                className="sm:ml-10 ml-5 text-4xl text-primary font-normal mt-4 sm:mt-0 py-3 px-2 border-primary_600 border w-auto"
                onClick={() => navigate(`/floor/${selectedCategory?.id}`)}
              >
                <span className="flex gap-3 hover:opacity-70 text-3xl sm:text-4xl items-center mt-0">
                  {dataContainer?.sectionKey(
                    "Translate",
                    "homepage_button_list"
                  )}
                  <Icon
                    name="RightArrow"
                    stroke="#006564"
                    className="w-[0.375rem] h-[0.813rem] sm:h-[1.125rem] sm:w-[0.625rem]"
                  />
                </span>
              </button>
              <Logo to="/" className="ml-10 hidden sm:flex fixed bottom-5" />
            </div>
          </div>
          <div className="relative sm:w-4/5 w-full sm:overflow-x-hidden mt flex flex-col gap-24 sm:h-full h-1/2">
            {products?.map((product, idx) => (
              <Image
                key={idx}
                title={product?.name}
                src={product?.preview_image}
                className="w-full h-full object-cover"
              />
            ))}
          </div>
        </>
      ) : (
        <div className="h-full w-screen flex items-center justify-center">
          <LoadingModal loadingText={loadingText} />
        </div>
      )}
    </div>
  );
}

export { HomePage };
