/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from "react";
import {
  Vector3,
  SceneLoader,
  Database,
  SceneOptimizerOptions,
  SceneOptimizer,
  SceneLoaderFlags,
  Engine,
  AssetsManager,
  ArcRotateCamera,
  DirectionalLight,
  HardwareScalingOptimization,
} from "@babylonjs/core";
import SceneComponent from "./SceneComponent";
import { GLTFFileLoader } from "@babylonjs/loaders";
import { BabylonContext } from "../../common/context/BabylonProvider";
import { Typography } from "../typography";
import {
  hideMeshes,
  handleNullWardrobe,
  handleWardrobe,
  kitchenAccesories,
  kitchenAccesories2,
  RoofEntrance,
  includeDefaultComponents,
} from "./HideMeshes";
import { useLocation, useNavigate, useParams } from "react-router";
import { handleKitchen } from "./services/HandleKitchenNode";
import Loading from "../loading/Loading";
import { changeCameraofMesh } from "./services/ChangeCamera";
import useCheckMobileScreen from "../../hooks/CheckMobileScreen";
import { useSearchParams } from "react-router-dom";
import { GLTF2Export } from "@babylonjs/serializers/glTF";
import {
  applyColorMaterials,
  saveInsideOutsideMeshes,
} from "./services/HandleMaterials";
import { AccordionEnum, SinglePageEnum } from "src/services/enums";
import {
  handleEntranceRoof,
  saveRoofEntranceData,
} from "./services/HandleEntranceRoof";
import { handleGable, saveGableTaskData } from "./services/HandleGable";
import { handleLights } from "./services/HandleLights";
import {
  handleDishwasher,
  handleKitchenAccessories,
} from "./services/HandleKitchenAccessories";
import { handleInteriorDoors } from "./services/HandleInteriorDoors";
import { handleFridge } from "./services/HandleFridge";
import { handleDefaultHallFloor } from "./services/HandleDefaultHall";
import { handleWallsInBathroom } from "./services/HandleBathroomAccessories";
import {
  handleSlidingSprojs,
  saveSlidingSprojsData,
} from "./services/HandleSlidingSprojs";

SceneLoader.RegisterPlugin(new GLTFFileLoader());
const BabylonComponent = (props) => {
  const { dataContainer, selectionAccordion } = props;
  const { dispatch, state } = useContext(BabylonContext);
  const [loadingState, setLoadingState] = useState(true);
  const { singleID, cat } = useParams();
  const isMobile = useCheckMobileScreen();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  let isAssetsLoaded = false;

  const positionMeshes = (
    meshName,
    m,
    transformNode,
    yrotation,
    yposition,
    parent,
    zposition
  ) => {
    if (!meshName) {
      return;
    }
    const transformNodeMesh = state.scene
      .getTransformNodesById(transformNode)
      .find((node) => node?.parent?.parent?.parent?.parent?.isVisible);

    if (m.name.includes(meshName) && !parent) {
      m?.setParent(transformNodeMesh);
      m.position = new Vector3(
        0,
        yposition ? yposition : 0,
        zposition ? zposition : 0
      );
      m.rotation = new Vector3(
        transformNodeMesh?.rotation.x,
        yrotation ? yrotation : transformNodeMesh?.rotation.y,
        transformNodeMesh?.rotation.z
      );
    }

    if (m.name.includes(meshName) && parent) {
      if (!transformNodeMesh) {
        m.isVisible = false;
      }
      m?.parent?.setParent(transformNodeMesh);
      if (m?.parent) {
        m.parent.position = new Vector3(0, 0, 0);
        m.parent.rotation = new Vector3(
          transformNodeMesh?.rotation?.x,
          transformNodeMesh?.rotation?.y,
          0
        );
      }
    }
  };

  const toggleMeshes = (task) => {
    if (!task) {
      return;
    }
    const data = task.name.split("_");
    const variationId = parseInt(data[0], 10);
    const shouldShow = dataContainer.isVariationSelected(variationId);
    const componentName =
      dataContainer?.findComponentByVariationId(variationId)?.name;

    task.loadedMeshes?.forEach((m) => {
      m.isPickable = false;
      m.doNotSyncBoundingInfo = true;
      m.isVisible =
        shouldShow &&
        !componentName.includes("Color") &&
        !RoofEntrance.includes(m.name);

      if (hideMeshes.includes(m.name)) {
        m.visibility = false;
      }

      if (singleID?.includes("_g") && m.name.includes("Doors_Handles_Room")) {
        m.visibility = false;
      }

      if (
        singleID?.includes("framtid_g_1") &&
        m.name.includes("Wardrobe_Framtid_A")
      ) {
        m.visibility = false;
      }
      positionMeshes(
        "Toilet_03",
        m,
        "Null_Bathroom_Toilet",
        null,
        null,
        false,
        0.4
      );
      positionMeshes(
        "Toilet_01",
        m,
        "Null_Bathroom_Toilet",
        4.7,
        null,
        false,
        0.2
      );
      positionMeshes(
        "Toilet_02",
        m,
        "Null_Bathroom_Toilet",
        4.7,
        null,
        false,
        null
      );
      positionMeshes(
        "Washbasin",
        m,
        "Null_Bathroom_Washbasin",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "Washingmachine",
        m,
        "Null_Bathroom_Washingmachine",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "TowelDryer",
        m,
        "Null_Bathroom_TowelDryer",
        null,
        1,
        false,
        null
      );
      positionMeshes(
        "Showerset",
        m,
        "Null_Bathroom_Showerset",
        null,
        null,
        false,
        null
      );
      positionMeshes(
        "WaterHeater",
        m,
        "Null_Bathroom_Toilet",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "FacadeLight_01",
        m,
        "Null_FacadeLight_001",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "FacadeLight_02_Black",
        m,
        "Null_FacadeLight_001",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "FacadeLight_03_Black",
        m,
        "Null_FacadeLight_001",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "Stove_01",
        m,
        "Null_Heating_Stove",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "ShowerWall",
        m,
        "Null_Bathroom_ShowerWall",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "Sofa_01",
        m,
        "Null_Furniture_Sofa_01",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "Bed_01",
        m,
        "Null_Furniture_Bed_01",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "Bed_02",
        m,
        "Null_Furniture_Bed_03",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "AC-In_01",
        m,
        "Null_Heating_AC-In",
        null,
        2.3,
        false,
        null
      );
      positionMeshes(
        "AC_Out_01",
        m,
        "Null_Heating_AC_Out",
        null,
        null,
        false,
        null
      );
      positionMeshes(
        "Dining",
        m,
        "Null_Furniture_Dining",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "Mirror_02_Mirror",
        m,
        "Null_Bathroom_Mirror",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        "Mirror_01_Mirror",
        m,
        "Null_Bathroom_Mirror",
        null,
        null,
        true,
        null
      );
      positionMeshes(
        handleWardrobe[singleID || "Wardrobe_Framtid_A"],
        m,
        handleNullWardrobe[singleID || "Null_Wardrobe_Framtid_A"],
        null,
        null,
        true,
        null
      );

      if (kitchenAccesories2.includes(m.name)) {
        const kitchen = state.scene
          .getTransformNodesById(handleKitchen[singleID || "Null_Kitchen_F_A"])
          .find((node) => node?.parent?.parent?.parent?.isVisible);

        m?.parent?.parent?.setParent(kitchen);
        if (m?.parent?.parent) {
          m.parent.parent.position = new Vector3(0, 0, 0);
          m.parent.parent.rotation = new Vector3(0, kitchen?.rotation?.y, 0);
        }
      }
      if (kitchenAccesories.includes(m.name)) {
        const kitchen = state.scene
          .getTransformNodesById(handleKitchen[singleID || "Null_Kitchen_F_A"])
          .find((node) => node?.parent?.parent?.parent?.isVisible);

        m?.parent?.parent?.parent?.setParent(kitchen);
        if (m?.parent?.parent?.parent) {
          m.parent.parent.parent.position = new Vector3(0, 0, 0);
          m.parent.parent.parent.rotation = new Vector3(
            0,
            kitchen?.rotation?.y,
            0
          );
        }
      }
      if (!shouldShow) {
        m.isVisible = false;
        return;
      }
    });

    state.scene.unfreezeActiveMeshes();
  };

  const relateDepentedMaterialToVariant = (task) => {
    if (!task) return;

    if (task?.dependencies.length) {
      const variantMaterial = dataContainer?.findVariationBy(
        task?.dependencies[0].depends_on_variation_id
      );
      if (!variantMaterial) {
        return;
      }

      if (!variantMaterial.mesh && variantMaterial.assets.length) {
        loadAssetManager([variantMaterial], () => {
          task?.mesh?.loadedMeshes?.forEach((m) => {
            m.material = variantMaterial?.mesh?.loadedMeshes[1].material;
          });
        });

        return;
      }

      if (variantMaterial?.mesh?.loadedMeshes) {
        task?.mesh?.loadedMeshes?.forEach((m) => {
          m.material = variantMaterial?.mesh?.loadedMeshes[1].material;
        });
      }
    }
  };

  const loadAssetManager = (tasks: any[], callback?: () => void) => {
    const assetsManager = new AssetsManager(state.scene.length && state.scene);
    assetsManager.useDefaultLoadingScreen = false;
    const loadAssetsForTasks = (item, meshTask) => {
      dispatch({ type: "SET_TASKS", payload: (item.mesh = meshTask) });
    };
    for (let idx = 0; idx < tasks.length; idx += 1) {
      const component = dataContainer?.findComponentById(
        tasks[idx].component_id
      );

      const assetUrl =
        tasks[idx]?.assets?.length > 1
          ? tasks[idx].assets.filter((s) =>
            s.attribute_values.some((m) => m.value.toLowerCase() === singleID)
          )[0]?.asset_url
          : tasks[idx].assets[0].asset_url;
      const meshTask = assetsManager.addMeshTask(
        `${tasks[idx].id} - ${tasks[idx].key} - ${component.name}`,
        "",
        "",
        assetUrl
      );
      loadAssetsForTasks(tasks[idx], meshTask);
      meshTask.onSuccess = function (task) {
        toggleMeshes(task);
      };
    }
    assetsManager.load();

    assetsManager.onFinish = (finishedTasks) => {
      tasks.forEach((t: any) => {
        if (t.mesh) {
          saveInsideOutsideMeshes(t.mesh);
          relateDepentedMaterialToVariant(t);
          toggleMeshes(t.mesh);
        }
      });
      !isAssetsLoaded && saveRoofEntranceData(finishedTasks, state.scene);
      !isAssetsLoaded && saveGableTaskData(finishedTasks);
      !isAssetsLoaded && saveSlidingSprojsData(finishedTasks);
      const roofEntrance =
        dataContainer?.findSelectedVariantByComponentNameAndCat(
          AccordionEnum.Roof,
          cat
        );
      const diskMaschin =
        dataContainer?.findSelectedVariantByComponentNameAndCat(
          "Kitchen type 11",
          cat
        );
      const roofEntranceType =
        dataContainer?.findSelectedVariantByComponentNameAndCat(
          AccordionEnum.RoofEntranceType,
          cat
        );
      applyColorMaterials(dataContainer, cat);

      const roofFascia = state.scene.getMeshByName("Roof_Fascia");
      const roof = dataContainer?.findSelectedVariantByComponentNameAndCat(
        "Roof",
        cat
      );

      if (
        (singleID?.includes("hallbar") || singleID?.includes("modern")) &&
        roofFascia &&
        roof
      ) {
        roofFascia.material = roofEntrance?.mesh?.loadedMeshes?.[1]?.material;
      }
      setLoadingState(searchParams.get("ar") === "ok");
      state?.engine?.resize();

      const slidingWindows =
        dataContainer?.findSelectedVariantByComponentNameAndCat(
          "Sliding Windows",
          cat
        );
      const fridgeSelected =
        dataContainer?.findSelectedVariantByComponentNameAndCat(
          "Kitchen Type 4",
          cat
        );
      const floorSelected =
        dataContainer?.findSelectedVariantByComponentNameAndCat("Floors", cat);
      const hallFloorSelected =
        dataContainer?.findSelectedVariantByComponentNameAndCat(
          "Hallway Floors",
          cat
        );
      const ovenSelected =
        dataContainer?.findSelectedVariantByComponentNameAndCat(
          "Kitchen type 8",
          cat
        );
      const windowsSprojs =
        dataContainer?.findSelectedVariantByComponentNameAndCat(
          "Windows Sprojs",
          cat
        );
      const lightSelected =
        dataContainer?.findSelectedVariantByComponentNameAndCat(
          "Tillval Type 5",
          cat
        );
      const toiletSelected = dataContainer?.findSelectedVariantByComponentNameAndCat("Bathroom Type 3", cat);
      !isAssetsLoaded &&
        handleGable(slidingWindows, state.scene, dataContainer, cat);
      handleLights(state.scene);
      !isAssetsLoaded && handleFridge(fridgeSelected, state.scene);
      handleDefaultHallFloor(floorSelected, state.scene, hallFloorSelected);
      !isAssetsLoaded &&
        handleDishwasher(diskMaschin, state.scene, ovenSelected);
      !isAssetsLoaded && handleWallsInBathroom(state.scene,toiletSelected);

      !isAssetsLoaded &&
        handleSlidingSprojs(windowsSprojs, slidingWindows, state.scene, singleID);

      isAssetsLoaded = true;

      handleKitchenAccessories(state.scene);

      if(lightSelected) {
        handleOutsideLights();
      }

      if (callback) {
        callback();
        if (roofEntrance && roofEntranceType) {
          handleEntranceRoof(roofEntranceType, roofEntrance);
        }
      }
    };
  };

  const exportToGLB = () => {
    const options = {
      shouldExportNode: function (node) {
        if (node.skeleton) {
          node.skeleton.dispose();
        }

        if (hideMeshes.includes(node.id)) {
          return false;
        }
        if (node.getClassName() === "Mesh") {
          return (
            node.isVisible &&
            node.id !== "hdrSkyBox" &&
            node.id !== "house_exterior_terrain"
          );
        }

        return true;
      },
    };

    const goToAR = searchParams.get("ar") === "ok";

    GLTF2Export.GLBAsync(state.scene, "model", options).then((glb) => {
      const blob = glb.glTFFiles["model.glb"];
      if (blob instanceof Blob) {
        localStorage.setItem("ar", URL.createObjectURL(blob));
        if (goToAR && dataContainer) {
          navigate("/ar", {
            state: {
              from: dataContainer?.generateShareableLink(
                location.pathname,
                true
              ),
            },
          });
        }
      }
    });
  };

  const onSceneReady = (scene) => {
    const camera = new ArcRotateCamera(
      "OrbitCamera",
      2.0,
      5,
      30,
      new Vector3(0, 0, 0),
      scene
    );
    const canvas = scene.getEngine().getRenderingCanvas();
    const engine = new Engine(canvas, true);
    const light = new DirectionalLight(
      "DirectionalLight",
      new Vector3(-1, -1, -1),
      scene
    );
    light.position = new Vector3(1, 1, 0.5);
    light.direction = new Vector3(1, -1, 0.5);
    light.intensity = 3;
    light.autoCalcShadowZBounds = true;
    light.autoUpdateExtends = true;
    SceneLoaderFlags.ShowLoadingScreen = false;

    camera.attachControl(canvas, true);
    dispatch({ type: "SET_SCENE", payload: scene });
    dispatch({ type: "SET_ENGINE", payload: engine });
    dispatch({ type: "SET_CAMERA", payload: camera });
    dispatch({ type: "SET_CANVAS", payload: canvas });
    Database.IDBStorageEnabled = false;

    const options = new SceneOptimizerOptions(30, 500);
    options.addOptimization(new HardwareScalingOptimization(1, 2));
    const optimizer = new SceneOptimizer(scene, options);

    if (isMobile) {
      optimizer.start();
    }
  };

  const positionLamps = (
    getAllCloneMeshes,
    meshToClone,
    secondNodeOfLamps,
    thirdNodeOfLamps,
  ) => {
    getAllCloneMeshes?.map((m) => (m.isVisible = false));
    const cloneLight = meshToClone.clone("FacadeLight_02_Null");
    const cloneSecondLight = meshToClone.clone("FacadeLight_03_Null");

    cloneLight?.setParent(secondNodeOfLamps);
    cloneLight.position = new Vector3(0, 0, 0);
    cloneLight.rotation = new Vector3(0, 0, Math.PI);
    cloneSecondLight?.setParent(thirdNodeOfLamps);
    cloneSecondLight.position = new Vector3(0, 0, 0);
    cloneSecondLight.rotation = new Vector3(0, 0, Math.PI);
  };

  window.addEventListener(
    "orientationchange",
    (e) => {
      if (e) {
        setTimeout(() => {
          state.scene && state.scene.getEngine().resize();
        }, 10);
      }
    },
    false
  );

  window.addEventListener("resize", () => {
    setTimeout(() => {
      if (typeof state.scene.getEngine !== "undefined") {
        state.scene && state.scene.getEngine().resize();
      }
    }, 10);
  });

  const selectionChanged = (variationId) => {
    const variation = dataContainer?.findVariationBy(variationId[0]);
    const component = dataContainer?.findComponentById(variation.component_id);
    const roofEntrance =
      dataContainer?.findSelectedVariantByComponentNameAndCat("Roof", cat);
    const roofEntranceType =
      dataContainer?.findSelectedVariantByComponentNameAndCat(
        "Roof Entrance Type",
        cat
      );
    const slidingWindows =
      dataContainer?.findSelectedVariantByComponentNameAndCat(
        "Sliding Windows",
        cat
      );
    const fasadSelected =
      dataContainer.findSelectedVariantByComponentNameAndCat(
        "Fasad Type 2",
        cat
      );
    const wallSelected = dataContainer.findSelectedVariantByComponentNameAndCat(
      "Walls",
      cat
    );
    const fridgeSelected =
      dataContainer.findSelectedVariantByComponentNameAndCat(
        "Kitchen Type 4",
        cat
      );
    const floorSelected =
      dataContainer?.findSelectedVariantByComponentNameAndCat("Floors", cat);
    const hallFloorSelected =
      dataContainer?.findSelectedVariantByComponentNameAndCat(
        "Hallway Floors",
        cat
      );
    const lightSelected =
      dataContainer?.findSelectedVariantByComponentNameAndCat(
        "Tillval Type 5",
        cat
      );
    const diskMaschin = dataContainer?.findSelectedVariantByComponentNameAndCat(
      "Kitchen type 11",
      cat
    );
    const windowsSprojs =
      dataContainer?.findSelectedVariantByComponentNameAndCat(
        "Windows Sprojs",
        cat
      );
    const ovenSelected =
      dataContainer?.findSelectedVariantByComponentNameAndCat(
        "Kitchen type 8",
        cat
      );
    const toiletSelected = dataContainer?.findSelectedVariantByComponentNameAndCat("Bathroom Type 3", cat);

    // check variations with assets
    if (
      typeof variation.mesh === "undefined" &&
      variation.assets.length > 0 &&
      variation.assets.find((a) => a.asset_type === "glb")
    ) {
      const dependencies: any[] = [];
      if (variation.dependencies.length > 0) {
        variation.dependencies.forEach((dv) => {
          const tempVariation = dataContainer.findVariationBy(
            dv.depends_on_variation_id
          );
          if (
            typeof tempVariation.mesh === "undefined" &&
            tempVariation.assets.length > 0
          ) {
            dependencies.push(tempVariation);
          }
        });

        if (dependencies.length > 0) {
          loadAssetManager(dependencies, () => {
            selectionChanged(variationId);
          });
          return;
        }
      }
      loadAssetManager([variation], () => {
        selectionChanged(variationId);
      });
      return;
    }

    state.tasks.forEach((t) => {
      toggleMeshes(t.mesh);
    });

    handleGable(slidingWindows, state.scene, dataContainer, cat);

    if (component.name === AccordionEnum.RoofEntranceType && roofEntrance) {
      handleEntranceRoof(variation, roofEntrance);
    }

    if (
      roofEntrance &&
      roofEntranceType &&
      component.name !== AccordionEnum.RoofEntranceType
    ) {
      handleEntranceRoof(roofEntranceType, roofEntrance);
    }

    handleSlidingSprojs(windowsSprojs, slidingWindows, state.scene, singleID);
    const getAllCloneMeshes = state.scene.meshes?.filter((m) =>
      m.id.includes(".FacadeLight")
    );
    if (lightSelected) {
      const getSecondFacadeLight = state.scene.getTransformNodesById(
        "Null_FacadeLight_002"
      )?.[0];
      const getThirdFacadeLight = state.scene.getTransformNodesById(
        "Null_FacadeLight_003"
      )?.[0];
      const getFirstLampMesh = state.scene.getMeshByName("FacadeLight_01 (1)");
      const getSecondlampMesh = state.scene.getMeshByName(
        "FacadeLight_02_Black"
      );
      const getThirdLampNode = state.scene.getTransformNodesById(
        "FacadeLight_03_Null"
      )[0];

      if (getSecondFacadeLight && getThirdFacadeLight) {
        if (getFirstLampMesh?.isVisible) {
          positionLamps(
            getAllCloneMeshes,
            getFirstLampMesh,
            getSecondFacadeLight,
            getThirdFacadeLight
          );
        }
        if (getSecondlampMesh?.isVisible) {
          positionLamps(
            getAllCloneMeshes,
            getSecondlampMesh,
            getSecondFacadeLight,
            getThirdFacadeLight
          );
        }
        if (getThirdLampNode) {
          positionLamps(
            getAllCloneMeshes,
            getThirdLampNode,
            getSecondFacadeLight,
            getThirdFacadeLight,
          );
        }
      }
    }else{
      getAllCloneMeshes.forEach((m) => {
        m.isVisible = false;
      });
    }

    handleInteriorDoors(state.scene);

    if (component.name.includes("Kitchen")) {
      handleKitchenAccessories(state.scene);
    }

    if (fridgeSelected) {
      handleFridge(fridgeSelected, state.scene);
    }

    handleDishwasher(diskMaschin, state.scene, ovenSelected);
    handleWallsInBathroom(state.scene, toiletSelected);

    if (floorSelected) {
      handleDefaultHallFloor(floorSelected, state.scene, hallFloorSelected);
    }

    applyColorMaterials(dataContainer, cat);
    const roofFascia = state.scene.getMeshByName("Roof_Fascia");
    if (
      (singleID?.includes("hallbar") || singleID?.includes("modern")) &&
      roofFascia &&
      roofEntrance
    ) {
      roofFascia.material = roofEntrance?.mesh?.loadedMeshes?.[1]?.material;
    }

    if (isMobile) {
      exportToGLB();
    }
  };

  const handleOutsideLights = () => {
    const getSecondFacadeLight = state.scene.getTransformNodesById(
      "Null_FacadeLight_002"
    )?.[0];
    const getThirdFacadeLight = state.scene.getTransformNodesById(
      "Null_FacadeLight_003"
    )?.[0];
    const getFirstLampMesh = state.scene.getMeshByName("FacadeLight_01 (1)");
    const getSecondlampMesh = state.scene.getMeshByName("FacadeLight_02_Black");
    const getThirdLampNode = state.scene.getTransformNodesById(
      "FacadeLight_03_Null"
    )[0];
    const getAllCloneMeshes = state.scene.meshes?.filter((m) =>
      m.id.includes(".FacadeLight")
    );

    if (getSecondFacadeLight && getThirdFacadeLight) {
      if (getFirstLampMesh?.isVisible) {
        positionLamps(
          getAllCloneMeshes,
          getFirstLampMesh,
          getSecondFacadeLight,
          getThirdFacadeLight
        );
      }
      if (getSecondlampMesh?.isVisible) {
        positionLamps(
          getAllCloneMeshes,
          getSecondlampMesh,
          getSecondFacadeLight,
          getThirdFacadeLight
        );
      }
      if (getThirdLampNode) {
        positionLamps(
          getAllCloneMeshes,
          getThirdLampNode,
          getSecondFacadeLight,
          getThirdFacadeLight
        );
      }
    }
  };

  useEffect(() => {
    if (dataContainer && !state.hookRegistered) {
      const allVariations = dataContainer.findVariationsByAssetType("glb");
      const filteredByProductCategory = allVariations?.filter((variation) => {
        return (
          variation.product_name === SinglePageEnum.Accessories ||
          variation.product_category_id === Number(cat)
        );
      });

      dispatch({ type: "SET_HOOK", payload: true });
      dispatch({
        type: "SET_TASKS",
        payload: filteredByProductCategory,
      });
    }
  }, [dataContainer, state]);

  useEffect(() => {
    if (state.tasks.length) {
      //clear current stored meshes and enforce redownload
      state.tasks.forEach((v) => {
        v.mesh = undefined;
        v.dependencies.forEach((dv) => {
          const tempVariation = dataContainer.findVariationBy(
            dv.depends_on_variation_id
          );
          if (tempVariation) {
            tempVariation.mesh = undefined;
          }
        });
      });

      const tempVariations = state.tasks.filter(
        (v) =>
          dataContainer.isVariationSelected(v.id) ||
          includeDefaultComponents.includes(
            dataContainer.findComponentByVariationId(v.id).name
          )
      );

      loadAssetManager(tempVariations, () => {
        dataContainer.mCanTrack = true;

        const goToAR = searchParams.get("ar") === "ok";
        if (goToAR && isMobile) {
          exportToGLB();
        }
      });
      dataContainer.registerSelectionHook(selectionChanged);
    }
  }, [state.tasks, dataContainer]);

  useEffect(() => {
    if (state.scene?.cameras?.length) {
      changeCameraofMesh(
        state,
        state.scene,
        selectionAccordion,
        isMobile,
        dispatch,
        singleID
      );
    }
  }, [state.pointView, selectionAccordion]);

  useEffect(() => {
    if (isMobile && !loadingState) {
      state.scene.getEngine().resize();
    }
  }, [loadingState]);

  return (
    <>
      {loadingState && (
        <div className="absolute w-full h-full z-50 bg-smoke bg-opacity-100 flex justify-center items-center">
          <Typography
            tag="h2"
            className="flex items-center"
            parentClassName="flex items-center"
          >
            <Loading />
          </Typography>
        </div>
      )}
      <SceneComponent antialias onSceneReady={onSceneReady} />
    </>
  );
};

export { BabylonComponent };
