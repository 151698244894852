import React from "react";
import { Accordion } from "../accordion";
import SubAccordion from "./SubAccordion";

export const multipleAccordions = (item, dataContainer, accordionKey, iconKey, setSelectionAccordion) => {
  return (
    <Accordion
      key={item.id}
      name={dataContainer?.sectionKey(
        "Translate",
        item.name.toLowerCase().split(" ").join("_")
      )}
      isOpen={
        item.id === accordionKey || item.id === iconKey
      }
      className="border-b px-0 border-0"
      onChange={(e) => setSelectionAccordion(
        e && item.name ? item.name : ""
      )}
    >
      <SubAccordion
        filter={item?.filter}
        actionTitle={item?.actionsTitle}
        id={item.id}
        variant={dataContainer?.findVariationType(
          item.name.split(" ").join("_")
        )}
        items={item.available_variations}
        options={item.available_variations?.options}
        label={item.name}
        dimensions={item.available_variations?.dimensions}
        dataContainer={dataContainer}
        setSelectionAccordion={setSelectionAccordion}
      />
    </Accordion>
  );
};
