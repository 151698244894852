import React from "react";
import { IconProps } from "../Types";

const Walls = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="28"
      height="23"
      viewBox="0 0 28 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1H14V6V7L14 14H8L8 7V6V1ZM15 0H14H8H7H1C0.447715 0 0 0.447715 0 1V6V7V22C0 22.5523 0.447715 23 1 23H7H8H14H15H21H22H27C27.5523 23 28 22.5523 28 22V15V14V1C28 0.447715 27.5523 0 27 0H22H21H15ZM21 1H15V6H21V1ZM21 7H15V14V15L15 22H21L21 15V14V7ZM22 15V22H27V15H22ZM22 14L22 7V6V1H27V14H22ZM14 22V15H8L8 22H14ZM7 1V6H1V1H7ZM7 7H1V22H7L7 15V14L7 7Z"
        fill="black"
      />
    </svg>
  );
};

export default Walls;
