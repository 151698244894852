import React from "react";
import { IconProps } from "../Types";

const Wardrobes = ({ className }: IconProps) => {
  return (
    <svg className={className} width="26" height="23" viewBox="0 0 26 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13.5 1H25V20H13.5V1ZM12.5 20V1H1V20H12.5ZM0 21V20V1C0 0.447715 0.447715 0 1 0H25C25.5523 0 26 0.447715 26 1V22C26 22.5523 25.5523 23 25 23H1C0.447716 23 0 22.5523 0 22V21ZM1 21H25V22H1V21ZM15 13.5C15 13.7761 15.2239 14 15.5 14C15.7761 14 16 13.7761 16 13.5V10.5C16 10.2239 15.7761 10 15.5 10C15.2239 10 15 10.2239 15 10.5V13.5ZM10.5 14C10.2239 14 10 13.7761 10 13.5V10.5C10 10.2239 10.2239 10 10.5 10C10.7761 10 11 10.2239 11 10.5V13.5C11 13.7761 10.7761 14 10.5 14Z" fill="black"/>
    </svg>
  );
};

export default Wardrobes;
