import React, { createContext, useReducer } from "react";

const initialState = {
  scene: {},
  camera: {},
  tasks: {},
  engine: {},
  assetUrl:"",
  pointView:"",
  hookRegistered: false,
  menuOpen: false,
  ssao: {},
  canvas: {},
  skyBox: {}
};
export const BabylonContext = createContext(initialState) ;

const Reducer = (state=initialState, action) => {
  switch (action.type) {
  case "SET_SCENE":
    return {
      ...state,
      scene: action.payload,
    };
  case "SET_CAMERA":
    return {
      ...state,
      camera: action.payload,
    };
  case "SET_ASSET":
    return {
      ...state,
      assetManager: action.payload,
    };
  case "SET_TASKS":
    return {
      ...state,
      tasks: action.payload,
    };
  case "SET_HOOK":
    return {
      ...state,
      hookRegistered: action.payload,
    };
  case "SET_ENGINE":
    return {
      ...state,
      engine: action.payload,
    };
  case "SET_MENU_OPEN":
    return{
      ...state,
      menuOpen: action.payload
    };
  case "SET_POINT_VIEW":
    return{
      ...state,
      pointView: action.payload
    };
  case "SET_SSAO":
    return{
      ...state,
      ssao: action.payload
    };
  case "SET_CANVAS":
    return{
      ...state,
      canvas: action.payload
    };
  case "SET_SKYBOX":
    return{
      ...state,
      skyBox: action.payload
    };
  default:
    return state;
  }
};

// eslint-disable-next-line react/prop-types
const BabylonProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (<BabylonContext.Provider value={{state, dispatch}}>{children}</BabylonContext.Provider>);
};

export default BabylonProvider;
