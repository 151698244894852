import React, { useContext, useEffect, useState } from "react";
import { TabProps } from ".";
import { Typography } from "../typography";
import classnames from "classnames";
import { DataContainerContext } from "../../../src/common/context/DataContainerProvider";

const Tabs = ({ data, className, onTabClick, pointView }: TabProps) => {
  const [active, setActive] = useState();
  const { dataContainer } = useContext(DataContainerContext);

  function onTabChange(value, valueID) {
    setActive(value);
    onTabClick(valueID, value);
  }

  useEffect(() => {
    setActive(data?.[0]?.name);
  }, []);

  useEffect(() => {
    setActive(pointView);
  }, [pointView]);

  return (
    <div className={classnames(className, "flex flex-row")}>
      {data?.map((item, key) => (
        <button
          onClick={() => onTabChange(item.name, item.id)}
          key={key}
          className="mr-8 cursor-pointer"
        >
          <Typography
            tag="p"
            className={classnames(
              "text-black text-4xl",
              item.name === active && "pb-2 border-b border-black-secondary"
            )}
          >
            {dataContainer.sectionKey(
              "Translate",
              item.name.toLocaleLowerCase()
            )}
          </Typography>
        </button>
      ))}
    </div>
  );
};

export { Tabs };
