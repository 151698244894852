export const hideMeshes = [
  "01_null",
  "Rotator",
  "skeleton",
  "RotatorL",
  "RotatorR",
  "trim_and_plate01",
  "Kitchen_Splashback_Metro_Aligned_White",
  "Kitchen_Front_White",
  "Kitchen_Countertop_PearlGrey",
  "House_Roof_Bitumen",
  "House_Wall_Tiles_White_Matt_S",
  "House_Floor_Tiles_Anthracite",
  "House_Facade_White_Vit",
  "House_Floor_Antic_Classsic",
  "House_Wall_Cladding_V",
  "House_Floor_Tiles_Grey",
  "Side_Opening_Fix_Crossbar",
  "Gable_Opening_Fix_Crossbar",
  "Side_Opening_Sliding_Crossbar",
  "Side_Opening_Fix_Crossbar_primitive0",
  "Side_Opening_Fix_Crossbar_primitive1",
  "Gable_Opening_Fix_Crossbar_primitive0",
  "Gable_Opening_Fix_Crossbar_primitive1",
  "House_Facade_Light_Gray",
  "House_Floor_Oak_Beige"
];

export const hideLightsCamera = [
  "DirectionalLight",
  "01_Light_Ceiling_01",
  "01_Light_Outside_01",
  "03_Light_Outside_01",
  "02_Light_Outside_01",
  "04_Light_GI_01",
  "04_Light_Outside_01",
  "04_Light_Outside_02",
  "05_Light_Outside_01",
  "05_Light_GI_01",
  "02_Light_Ceiling_01",
  "03_Light_Outside_02",
  "01_Cam_Desktop_Bathroom_01",
  "01_Cam_Desktop_Bathroom_02",
  "02_Cam_Desktop_Kitchen",
  "03_Cam_Desktop_Hall",
  "04_Cam_Desktop_General",
  "OrbitCamera"
];
export const RoofEntrance = [
  "Roof_Entrance_Construction",
  "Roof_Entrance_Support"
];  

export const outsideDoorsMeshes = [
  "Doors_Entrance_UH6_Out",
  "Doors_Entrance_UH6-A_Out",
  "Doors_Entrance_UH6-B_Out",
  "Doors_Entrance_UH6-C_Out",
  "Doors_Entrance_UH6-D_Out",
];

export const insideDoorsMeshes = [
  "Doors_Entrance_UH6_In",
  "Doors_Entrance_UH6-A_In",
  "Doors_Entrance_UH6-B_In",
  "Doors_Entrance_UH6-C_In",
  "Doors_Entrance_UH6-D_In",
];

export const outsideWindowMeshes = [
  "Windows_Out",
  "Windows_Crossbar_primitive0",
  "Side_Opening_Sliding_Crossbar_primitive0",
  "Side_Pivot_Crossbar_primitive0",
  "Gable_Opening_Sliding_Crossbar_primitive0",
  "Gable_Pivot_Crossbar",
  "Gable_Pivot_Out",
  "Gable_Opening_Fix_Crossbar",
  "Gable_Opening_Sliding_Crossbar",
  "Gable_Opening_Out",
  "Gable_Opening_Sliding_Out",
  "Gable_Opening_Crossbar",
  "Side_Pivot_Out",
  "Gable_Opening_Fix_Out",
  "Side_Opening_Out",
  "Side_Opening_Sliding_Out",
  "Side_Opening_Fix_Out",
  "Windows_Crossbars",
  "Windows_Side_Out",
  "Side_Opening_Crossbar",
  "Gable_Opening_Fix_Crossbar_primitive0",
  "Side_Opening_Fix_Crossbar_primitive0"
];

export const insideWindowMeshes = [
  "Windows_In",
  "Gable_Pivot_In",
  "Gable_Opening_In",
  "Gable_Opening_Sliding_In",
  "Windows_Crossbar_primitive1",
  "Side_Opening_Sliding_Crossbar_primitive1",
  "Side_Pivot_Crossbar_primitive1",
  "Gable_Opening_Sliding_Crossbar_primitive1",
  "Side_Opening_Fix_Crossbar_primitive1",
  "Side_Pivot_In",
  "Side_Opening_In",
  "Side_Opening_Sliding_In",
  "Side_Opening_Fix_In",
  "Windows_Side_In",
  "Side_Opening_Crossbar_primitive1",
  "Gable_Opening_Crossbar_primitive1",
  "Gable_Opening_Fix_In",
  "Gable_Opening_Fix_Crossbar_primitive1"
];

export const facadeMeshes = [
  "Facade_H_Type_01",
  "Facade_H_Type_02",
  "Facade_V_Type_01",
  "Facade_V_Type_02",
  "Facade_V_Type_03",
  "Facade_V_Type_04",
  "Facade_V_Type_05",
  "Facade_Gable_Pivot_H_Type_01",
  "Facade_Gable_Pivot_H_Type_02",
  "Facade_Gable_Pivot_V_Type_01",
  "Facade_Gable_Pivot_V_Type_02",
  "Facade_Gable_Pivot_V_Type_03",
  "Facade_Gable_Pivot_V_Type_04",
  "Facade_Side_H_Type_01",
  "Facade_Side_H_Type_02",
  "Facade_Side_V_Type_01",
  "Facade_Side_V_Type_02",
  "Facade_Side_V_Type_03",
  "Facade_Side_V_Type_04",
  "Facade_Side_Pivot_H_Type_01",
  "Facade_Side_Pivot_H_Type_02",
  "Facade_Side_Pivot_V_Type_01",
  "Facade_Side_Pivot_V_Type_02",
  "Facade_Side_Pivot_V_Type_03",
  "Facade_Side_Pivot_V_Type_04",
];

export const includeDefaultComponents = [
  "Roof Entrance",
  "Pivot Type",
  "Pivot Fasad Type",
  "Gable Type",
  "Windows Sprojs Type"
];

export const handleNullWardrobe = {
  framtid_a_1 : "Null_Wardrobe_Framtid_A",
  framtid_g_1 : "wardrobe",
  hallbar_a: "Null_Furniture_Wardrobe_170",
  hallbar_a1: "Null_Furniture_Wardrobe_180",
  hallbar_b: "Null_Wardrobe_B",
  hallbar_b1: "Null_Wardrobe_B1",
  hallbar_c: "Null_Wardrobe_C",
  hallbar_c1: "Null_Wardrobe_C1",
  hallbar_d: "Null_Wardrobe_D",
  hallbar_e: "Null_Wardrobe_E",
  hallbar_f: "Null_Wardrobe_F",
};

export const handleWardrobe = {
  framtid_a_1 : "Wardrobe_Framtid_A",
  framtid_g_1 : "Wardrobe_Framtid_G",
  hallbar_a: "Wardrobe_170_White",
  hallbar_a1: "Wardrobe_180_White",
  hallbar_b: "Wardrobe_170_White",
  hallbar_b1: "Wardrobe_170_White",
  hallbar_c: "Null_Wardrobe_C",
  hallbar_c1: "Null_Wardrobe_C1",
  hallbar_d: "Null_Wardrobe_D",
  hallbar_e: "Null_Wardrobe_E",
  hallbar_f: "Null_Wardrobe_F",
};

export const handleGeneralCamera = {
  framtid_a_1 : "04_Cam_Desktop_General",
  framtid_g_1 : "04_Cam_Desktop_General",
  hallbar_a: "04_Cam_Desktop_General",
  hallbar_a1: "04_Cam_Desktop_General",
  hallbar_b: "04_Cam_Desktop_General",
  hallbar_b1: "04_Cam_Desktop_General",
  hallbar_c: "04_Cam_Desktop_General",
  hallbar_c1: "04_Cam_Desktop_General",
  hallbar_d: "04_Cam_Desktop_General",
  hallbar_e: "04_Cam_Desktop_General",
  hallbar_f: "04_Cam_Desktop_General",
  hallbar_g: "03_Cam_Desktop_General",
  klassisk_a: "03_Cam_Desktop_General",
  klassisk_a1: "04_Cam_Desktop_General",
  klassisk_b: "04_Cam_Desktop_General",
  klassisk_b1: "04_Cam_Desktop_General",
  klassisk_c: "04_Cam_Desktop_General",
  klassisk_c1: "04_Cam_Desktop_General",
  klassisk_d: "04_Cam_Desktop_General",
  klassisk_e: "04_Cam_Desktop_General",
  klassisk_f: "04_Cam_Desktop_General",
  klassisk_g: "03_Cam_Desktop_General",
  modern_a: "03_Cam_Desktop_General",
  modern_a1: "04_Cam_Desktop_General",
  modern_b: "04_Cam_Desktop_General",
  modern_b1: "04_Cam_Desktop_General",
  modern_c: "04_Cam_Desktop_General",
  modern_c1: "04_Cam_Desktop_General",
  modern_d: "04_Cam_Desktop_General",
  modern_e: "04_Cam_Desktop_General",
  modern_f: "04_Cam_Desktop_General",
  modern_g: "03_Cam_Desktop_General",
};

export const handleGeneralMobileCamera = {
  framtid_a_1 : "04_Cam_Mobile_General",
  framtid_g_1 : "04_Cam_Mobile_General",
  hallbar_a: "04_Cam_Mobile_General",
  hallbar_a1: "04_Cam_Mobile_General",
  hallbar_b: "04_Cam_Mobile_General",
  hallbar_b1: "04_Cam_Mobile_General",
  hallbar_c: "04_Cam_Mobile_General",
  hallbar_c1: "04_Cam_Mobile_General",
  hallbar_d: "04_Cam_Mobile_General",
  hallbar_e: "04_Cam_Mobile_General",
  hallbar_f: "04_Cam_Mobile_General",
  hallbar_g: "03_Cam_Mobile_General",
  klassisk_a: "03_Cam_Mobile_General",
  klassisk_a1: "04_Cam_Mobile_General",
  klassisk_b: "04_Cam_Mobile_General",
  klassisk_b1: "04_Cam_Mobile_General",
  klassisk_c: "04_Cam_Mobile_General",
  klassisk_c1: "04_Cam_Mobile_General",
  klassisk_d: "04_Cam_Mobile_General",
  klassisk_e: "04_Cam_Mobile_General",
  klassisk_f: "04_Cam_Mobile_General",
  klassisk_g: "03_Cam_Mobile_General",
  modern_a: "03_Cam_Mobile_General",
  modern_a1: "04_Cam_Mobile_General",
  modern_b: "04_Cam_Mobile_General",
  modern_b1: "04_Cam_Mobile_General",
  modern_c: "04_Cam_Mobile_General",
  modern_c1: "04_Cam_Mobile_General",
  modern_d: "04_Cam_Mobile_General",
  modern_e: "04_Cam_Mobile_General",
  modern_f: "04_Cam_Mobile_General",
  modern_g: "03_Cam_Mobile_General",
};

export const kitchenAccesories = [
  "Front_Plain_Oven_No",
  "Countertop (1)",
  "Countertop",
  "Cooktop-4ring",
  "Cooktop-2ring",
  "Handles_Bowl_Oven_No",
  "Handles_Standard_Microwave_No",
  "Handles_Standard_Oven_Yes",
  "Handles_Standard_Fridge_No",
  "Countertop_Fridge_No",
  "Sink_Steel_primitive1"
];

export const kitchenAccesories2 = [
  "Splashback",
  "Oven",
  "Front_Frame_Oven_Yes",
  "Splashback_Fridge_No",
  "Microwave"
];

export const trimFacade = [
  "Facade_Trim",
  "Side_Pivot_Trim",
  "Roof_Fascia",
];