import React from "react";
import { IconProps } from "../Types";

const AR = ({ className }: IconProps) => (
  <svg
    className={className}
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 0.25C1.35218 0.25 0.970644 0.408035 0.68934 0.68934C0.408035 0.970644 0.25 1.35218 0.25 1.75V12.25C0.25 12.6478 0.408035 13.0294 0.68934 13.3107C0.970644 13.592 1.35218 13.75 1.75 13.75H12.25C12.6478 13.75 13.0294 13.592 13.3107 13.3107C13.592 13.0294 13.75 12.6478 13.75 12.25V1.75C13.75 1.35218 13.592 0.970644 13.3107 0.68934C13.0294 0.408035 12.6478 0.25 12.25 0.25H1.75ZM3.25 4.75H5.5C5.95 4.75 6.25 5.125 6.25 5.5V9.25H5.125V8.125H3.625V9.25H2.5V5.5C2.5 5.125 2.8 4.75 3.25 4.75ZM7.75 4.75H10.375C11.0125 4.75 11.5 5.2375 11.5 5.875V6.625C11.5 7.075 11.2 7.4875 10.825 7.675L11.5 9.25H10.375L9.7375 7.75H8.875V9.25H7.75V4.75ZM3.625 5.875V7H5.125V5.875H3.625ZM8.875 5.875V6.625H10.375V5.875H8.875Z"
      fill="#006564"
    />
  </svg>
);

export default AR;
