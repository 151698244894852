import React, { useState, useContext, useEffect } from "react";
import { BabylonComponent } from "../components/babylon/Babylon";
import { use100vh } from "react-div-100vh";
import { DataContainerContext } from "../common/context/DataContainerProvider";
import { SingleComponents } from "../components/singleComponents";

function SinglePage() {
  const height = use100vh();
  const { dataContainer, dataReady } = useContext(DataContainerContext);
  const [selectionAccordion, setSelectionAccordion] = useState("");
  sessionStorage.setItem("windowColorVariant", "outside_window");
  sessionStorage.setItem("doorColorVariant", "outside_door");

  useEffect(() => {
    if (dataContainer) {
      dataContainer.logEvent("page_entered", "Single Page", {
        product: "",
      });
    }
  }, [dataContainer]);

  return (
    <div style={{ height: height }} className="w-full overflow-hidden">
      <SingleComponents
        dataReady={dataReady}
        dataContainer={dataContainer}
        setSelectionAccordion={setSelectionAccordion}
        selectionAccordion={selectionAccordion}
      />
      <BabylonComponent
        dataContainer={dataContainer}
        selectionAccordion={selectionAccordion}
      />
    </div>
  );
}

export { SinglePage };
