import React from "react";
import { Route, Routes } from "react-router-dom";
import {
  HomePage,
  SingleProduct,
  OrderPage,
  ConfirmationPage,
  FloorPage,
} from "./views";
import { AugmentedRealityPage } from "./views";
import { useSession } from "./hooks/useSession";

function App() {
  useSession();

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/floor/:singleFloor" element={<FloorPage />} />
      <Route path="/:cat/:singleID" element={<SingleProduct />} />
      <Route path="/summary" element={<OrderPage />} />
      <Route path="/confirmation" element={<ConfirmationPage />} />
      <Route path="/ar" element={<AugmentedRealityPage />} />
    </Routes>
  );
}

export default App;
