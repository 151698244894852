import React from "react";
import { IconProps } from "../Types";

const Kitchen = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="27"
      height="27"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10222_30793)">
        <path
          d="M18.5334 8.93521H13.1208V8.21355C13.1208 7.94767 12.9155 7.73877 12.6542 7.73877H2.6223C2.361 7.73877 2.1557 7.94767 2.1557 8.21355V11.2806C2.1557 13.0088 3.22888 14.4806 4.74067 15.0598H0.466601C0.205305 15.0598 0 15.2687 0 15.5346C0 15.8005 0.205305 16.0094 0.466601 16.0094H15.1179C15.3792 16.0094 15.5845 15.8005 15.5845 15.5346C15.5845 15.2687 15.3792 15.0598 15.1179 15.0598H10.5452C12.0476 14.4806 13.1302 13.0088 13.1302 11.2806V9.88476H18.5427C18.804 9.88476 19.0093 9.67586 19.0093 9.40999C19.0093 9.14411 18.804 8.93521 18.5427 8.93521H18.5334ZM12.1876 11.2806C12.1876 12.9898 10.8251 14.3762 9.14538 14.3762H6.13114C4.45138 14.3762 3.0889 12.9898 3.0889 11.2806V8.68832H12.1876V11.2806Z"
          fill="#333"
        />
        <path
          d="M8.39885 5.1181C8.21221 5.30801 8.21221 5.60237 8.39885 5.79228C8.49217 5.88724 8.61348 5.93472 8.72547 5.93472C8.83745 5.93472 8.9681 5.88724 9.05209 5.79228C9.91063 4.91869 9.91063 3.50386 9.05209 2.63027C8.55749 2.127 8.55749 1.31039 9.05209 0.816617C9.23873 0.626706 9.23873 0.332344 9.05209 0.142433C8.86545 -0.0474777 8.57616 -0.0474777 8.38952 0.142433C7.53097 1.01602 7.53097 2.43086 8.38952 3.30445C8.88411 3.80772 8.88411 4.62433 8.38952 5.1181H8.39885Z"
          fill="#333"
        />
        <path
          d="M5.95378 5.1181C5.76714 5.30801 5.76714 5.60237 5.95378 5.79228C6.0471 5.88724 6.16842 5.93472 6.2804 5.93472C6.39238 5.93472 6.52303 5.88724 6.60702 5.79228C7.46557 4.91869 7.46557 3.50386 6.60702 2.63027C6.11242 2.127 6.11242 1.31039 6.60702 0.816617C6.79366 0.626706 6.79366 0.332344 6.60702 0.142433C6.42038 -0.0474777 6.13109 -0.0474777 5.94445 0.142433C5.0859 1.01602 5.0859 2.43086 5.94445 3.30445C6.43904 3.80772 6.43904 4.62433 5.94445 5.1181H5.95378Z"
          fill="#333"
        />
      </g>
      <defs>
        <clipPath id="clip0_10222_30793">
          <rect width="27" height="27" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Kitchen;
