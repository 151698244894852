import classnames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import { Typography } from "../typography";
import "../../../src/assets/scss/style.scss";
import { DataContainerContext } from "../../common/context/DataContainerProvider";
import { Icon } from "../icon/Icon";
import { loadingText } from "src/services/enums";

const Loading = () => {
  const { dataContainer } = useContext(DataContainerContext);
  const [active, setActive] = useState(0);
  const [loadingItems, setLoadingItems] = useState<string[]>([loadingText]);
  const sectionLoadingTexts = dataContainer?.section("Loading Texts");
  const loadingTitle = dataContainer?.sectionKey(
    "Loading Title",
    `title_${active + 1}`
  );
  const loadingTime = dataContainer?.sectionKey(
    "Loading Title",
    "loading_time"
  );
  let timeValidated = 2000;

  if (loadingTime > 1000) {
    timeValidated = loadingTime;
  }

  useEffect(() => {
    if (!sectionLoadingTexts) return;
    const loadingKeys: string[] = Object.values(sectionLoadingTexts);
    setLoadingItems(loadingKeys);
    const interval = setInterval(() => {
      setActive((prev) => {
        const next = prev + 1;
        return next === loadingKeys.length ? 0 : next;
      });
    }, timeValidated);
    return () => {
      clearInterval(interval);
    };
  }, [sectionLoadingTexts]);

  return (
    <>
      {loadingItems.map((item, index) => (
        <div
          key={`loading-item-${index}`}
          className={classnames(
            { "invisible opacity-0 owerflow-hidden w-0 h-0": active != index },
            "flex flex-col items-center justify-center transition-opacity visible opacity-1 duration-1000"
          )}
        >
          <Typography
            tag="h3"
            className="text-[#A99C9C] h-auto sm:text-4xl text-3xl text-center"
          >
            {`title_${active + 1}` === loadingTitle ? "" : loadingTitle}
          </Typography>
          <Typography
            tag="h1"
            className={classnames(
              "text-[#006564] mt-5 sm:text-6xl text-5xl text-center sm:px-0 px-4",
              {
                "mb-7": index != 4 && index != 5,
              }
            )}
          >
            {item}
          </Typography>
          <div
            className={classnames({
              snippet: index != 4 && index != 5,
            })}
          >
            <div className="stage">
              <div
                className={classnames(
                  { "dot-pulse": index != 4 && index != 5 },
                  {
                    "animate-loadingBounce": active === loadingItems.length - 2,
                  },
                  "h-24 w-24"
                )}
              >
                <Icon
                  name={
                    (index === 4 && "Rotate") || (index === 5 && "ZoomArrow")
                  }
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Loading;
