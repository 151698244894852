import React from "react";
import { IconProps } from "../Types";

const DownloadFloor = ({ className }: IconProps) => (
  <svg
    className={className}
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.25 4.75H8.25V0.25H3.75V4.75H0.75L6 10L11.25 4.75ZM0.75 11.5V13H11.25V11.5H0.75Z"
      fill="#006564"
    />
  </svg>
);

export default DownloadFloor;
