import React from "react";
import { IconProps } from "../Types";

const Fasad = ({ className }: IconProps) => (
  <svg className={className} width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.3945 13.4745L14.3945 1.08967L19.5 2.25V13.4745H14.3945ZM14.3945 14.4745V19.4745H19.5V14.4745H14.3945ZM3 20.4745H14H20H25H27.5C27.7761 20.4745 28 20.2506 28 19.9745C28 19.6984 27.7761 19.4745 27.5 19.4745H25V5.4745V4.4745V3.27273C25 2.80583 24.6769 2.40107 24.2216 2.29759L14.2216 0.0248671C14.0757 -0.00828906 13.9243 -0.00828904 13.7784 0.0248672L3.77838 2.29759C3.32309 2.40107 3 2.80583 3 3.27273V19.4745H0.5C0.223857 19.4745 0 19.6984 0 19.9745C0 20.2506 0.223858 20.4745 0.5 20.4745H3ZM24 4.4745V3.27273L20.5 2.47727V4.4745H24ZM20.5 5.4745V19.4745H24V5.4745H20.5ZM13.3945 1.13761V7.4745H8.39453V2.27397L13.3945 1.13761ZM13.3945 19.4745L13.3945 8.4745H8.39453L8.39453 19.4745H13.3945ZM7.39453 17.4745V19.4745H4V17.4745H7.39453ZM7.39453 2.50124L7.39453 16.4745H4V3.27273L7.39453 2.50124Z" fill="black"/>
  </svg>

);

export default Fasad;
