import axios from "axios";
import { AccessoriesCategoryId, AccordionEnum, excludedComponents, Sides } from "./enums";
import lz from "lz-string";

export default {
  _mState: "passive",
  _productId: 35,
  _applicationId: 14,
  _apiKey: "k6nFwsd1mUHyGxeTrrX4yCaAy9Bsb0T3QkvXWHuq",
  _locale: "sv",
  _localeId: "154",
  mLoadedData: null,
  mComponents: null,
  mAllComponents: null,
  mVariations: null,
  mComponentGroups: [],
  mImages: {},
  mProducts: null,
  mAllProducts: null,
  mAccessories: null,
  mCustomDimensions: null,
  mHierarchical: null,
  mSelectedVariations: [],
  mSelectionHooks: [],
  mApplicationDataChangeHooks: [],
  mInitLoadedScripts: false,
  mLoadedScripts: {},
  mVariationJsAssetsCount: 0,
  mApplicationData: {},
  mSessionId: null,
  mVisitorId: null,
  mFloorMirror: false,
  mCanTrack:false,
  instance() {
    if (!window.dataContainer) {
      window.dataContainer = this;
    }
    if (!window.onScriptLoaded) {
      window.onScriptLoaded = (variationIframeKey) => {
        const variationId = variationIframeKey.split("_")[0];
        const object = document
          .getElementById(variationIframeKey)
          .contentWindow.instance();
        const copyOfObject = {};
        const keys = Object.keys(object);
        for (let idx = 0; idx < keys.length; idx += 1) {
          copyOfObject[keys[idx]] = object[keys[idx]];
        }
        window.dataContainer.appendScript(variationId, copyOfObject);
      };
    }
    return this;
  },
  appendScript(variationId, object) {
    this.mLoadedScripts[variationId] = object;
    if (this.mInitLoadedScripts) {
      this.initVariationScripts();
    }
  },
  initVariationScripts() {
    const jsKeys = Object.keys(this.mLoadedScripts);
    if (jsKeys.length === this.mVariationJsAssetsCount) {
      for (let idx = 0; idx < jsKeys.length; idx += 1) {
        this.mLoadedScripts[jsKeys[idx]].onInit(
          parseInt(jsKeys[idx], 10),
          this
        );
      }
    }
  },
  registerSelectionHook(callback) {
    this.mSelectionHooks.push(callback);
  },
  registerApplicationDataChangeHook(callback) {
    this.mApplicationDataChangeHooks.push(callback);
  },
  loadApplicationData(callback) {
    axios
      .get(
        `https://one-app.onecx.rapidimages.com/api/v1/applications/locale/${this._localeId}`,
        {
          headers: {
            "X-Header-ApiKey": this._apiKey,
            "X-Header-AppId": this._applicationId,
          },
        }
      )
      .then((r) => {
        if (r.data.success) {
          this.mApplicationData = r.data.data;
          if (callback) {
            callback();
          }
        }
      });
  },
  loadData(callback) {
    if (this._mState !== "passive") {
      return;
    }
    this._mState = "fetching";
    axios
      .get(
        `https://one-prod.onecx.rapidimages.com/api/v1/products/data/sv?ignoreComponents=true`,
        {
          headers: {
            "X-Header-ApiKey": this._apiKey,
            "X-Header-AppId": this._applicationId,
          },
        }
      )
      .then(async (r) => {
        if (!r.data.success) {
          this._mState = "passive";
          if (callback) {
            callback(null);
          }
        } else {
          this._mState = "fetched";

          const initalProducts = r.data.data;
          const promises = [];

          initalProducts.forEach((product) => {
            if (product["cached_url"]) {
              promises.push(axios.get(product["cached_url"]));
            }
          });

          const results = await Promise.allSettled(promises);

          const resolvedResults = results.filter(
            (r) => r.status === "fulfilled"
          );

          const productsData = resolvedResults.map((r) => r.value.data);
          
          this.mLoadedData = productsData;
          this.extractComponentsAndVariations();
          this.loadScriptAssetsForVariations();

          this.loadApplicationData(() => {
            if (callback) {
              callback({
                instance: this,
                plainData: this.mLoadedData,
                variations: this.mVariations,
                components: this.mComponents,
              });
            }
          });
        }
      });
  },
  getAllProducts() {
    return this.mAllProducts;
  },
  loadScriptAssetsForVariations() {
    this.mVariations.forEach((v) => {
      if (v.assets.length > 0) {
        v.assets.forEach((vA) => {
          if (vA.asset_type === "js") {
            this.mVariationJsAssetsCount += 1;
            this.loadInstanceForVariation(v, vA.asset_url);
          }
        });
      }
    });
  },
  loadInstanceForVariation(variation, scriptUrl) {
    const iFrameHtml =
      `${"<html>" + "<head>" + "<script src='"}${scriptUrl}'>` +
      "</script>" +
      "</head>" +
      "</html>";

    const iFrameId = `${variation.id}_ifr_${Math.random()}`;

    // eslint-disable-next-line no-unused-vars
    const iframeSource = `<iframe onload='window.onScriptLoaded("${iFrameId}")' id="${iFrameId}" srcdoc="${iFrameHtml}"></iframe>`;
    if (document.getElementById("temp_iframes")) {
      setTimeout(() => {
        document
          .getElementById("temp_iframes")
          .insertAdjacentHTML("beforeend", iframeSource);
      }, 1500);
    } else {
      throw new DOMException("temp_iframes doesn't exist!");
    }
  },
  sectionKey(sectionName, key) {
    if (!this.mApplicationData || !this.mApplicationData.sections) {
      return `${key}`;
    }
    const section = this.mApplicationData.sections.find(
      (s) => s.section_name === sectionName
    );

    if (!section) {
      return `${key}`;
    }

    if (!Object.keys(section.key_values).includes(key)) {
      return `${key}`;
    }

    return section.key_values[key];
  },
  section(sectionName) {
    if (!this.mApplicationData || !this.mApplicationData.sections) {
      return `${sectionName}`;
    }
    const section = this.mApplicationData.sections.find(
      (s) => s.section_name === sectionName
    );

    if (!section) {
      return `${sectionName}`;
    }

    return section.key_values;
  },
  getAllVariations() {
    return this.mVariations;
  },
  getSingleAccordions(id, singleID) {
    let singleAccordions = [];

    for (const key in this.mLoadedData) {
      const obj = this.mLoadedData[key];
      if (obj.category_id === Number(id)) {
        const components = obj.component_groups.map((m) => m.components);
        const sortedAccordion = Array.prototype.concat.apply([], components);
        singleAccordions = sortedAccordion.sort(
          (c1, c2) => c1.layer_order - c2.layer_order
        );
      }
    }

    for (const key in this.mLoadedData) {
      const obj = this.mLoadedData[key];
      if (obj.name.includes("Accessories")) {
        const components = obj.component_groups.map((m) => m.components);
        singleAccordions.push(...Array.prototype.concat.apply([], components));
      }
    }

    return this.filterSingleAccordionByIDandName(singleAccordions, singleID);
  },
  filterSingleAccordionByIDandName(singleAccordions, singleID){
    return singleAccordions.filter(item => {
      return (singleID?.includes("_f") ? !item.name.includes("Hallway Floors") && !item.name.includes("Kitchen") : item)
      && (singleID?.includes("_e") ? !item.name.includes("Kitchen") : item)
    });
  },
  getCurrentModel() {
    const allVariations = this.getAllModelsVariations().map(
      (product) =>
        product.component_groups[0]?.components[0]?.available_variations[0]
      );
    const allModelsVariations = allVariations.map(
      (variation) => variation && variation.id
    );
    return this.mSelectedVariations.find((variation) =>
      allModelsVariations.includes(variation)
    );
  },
  getConfigurations() {
    const houses = this.mSelectedVariations.map((configuration) => {
      return configuration.variations.map((variant) =>
        this.findVariationBy(variant)
      );
    });

    return houses;
  },
  getModelAccordions(catId) {
    let modelAccordion = {};
    const modelVariant = [];
    let modelParentId = null;

    for (const key in this.mLoadedData) {
      const obj = this.mLoadedData[key];
      if (obj.category_id === catId) {
        modelAccordion = Object.assign(
          modelAccordion,
          obj.component_groups[0].components[0]
        );
        modelParentId = obj.component_groups[0].components[0]?.id;
        modelVariant.push(
          obj.component_groups[0].components[0].available_variations[0]
        );
      }
    }

    modelVariant.forEach((item) => {
      item.component_id = modelParentId;
      return item;
    });
    modelAccordion.available_variations = modelVariant;
    return modelAccordion;
  },
  unSelectCurrentModel() {
    this.mSelectedVariations.forEach((variationId) => {
      const variation = this.findVariationBy(variationId);
      const component = this.findComponentById(variation.component_id);
      if (component.name === "Model") {
        this.toggleVariationSelection(variationId);
      }
    });
  },
  getAllComponents() {
    for (const key in this.mLoadedData) {
      const obj = this.mLoadedData[key];

      const components = obj.component_groups;
      this.mAllComponents = Array.prototype.concat([], components);
    }

    return this.mAllComponents;
  },
  getImagesVariations(productType) {
    return this.mImages[productType]?.component_groups[0].components[0]
      .available_variations;
  },
  extractComponentsAndVariations() {
    this.mComponents = [];
    this.mVariations = [];
    this.mProducts = [];
    this.mAccessories = [];

    for (const key in this.mLoadedData) {
      const obj = this.mLoadedData[key];
      this.mProducts.push(obj);
      obj?.component_groups?.forEach((componentGroup) => {
        this.mComponentGroups.push({
          ...componentGroup,
          product_category_id: obj.category_id,
        });
        componentGroup.components.forEach((component) => {
          const tempComponent = {};
          Object.keys(component).forEach((k) => {
            tempComponent[k] = component[k];
          });
          this.mComponents.push({
            ...tempComponent,
            product_category_id: obj.category_id,
          });
          component.available_variations.forEach((v) => {
            this.mVariations.push({
              ...v,
              product_id: obj.id,
              product_category_id: obj.category_id,
              product_name: obj.name
            });
          });
        });
      });
    }
  },
  loadedData() {
    return this.mLoadedData;
  },
  findComponentByName(name) {
    const components = this.mComponents;

    return components.find((component) => component.name.includes(name));
  },
  findEqualComponentByName(name, cat){
    const components = this.mComponents;

    return components.find((component) => component.name === name && component.product_category_id === Number(cat));
  },
  findComponentsByName(name) {
    const components = this.mComponents;

    return components.filter((component) => component.name.toLowerCase().includes(name.toLowerCase()));
  },
  findModelByName(name) {
    return this.mProducts.find((product) => product.name === name);
  },
  findVariationsByAssetType(assetType) {
    return this.mVariations.filter(
      (variation) => variation.assets[0]?.asset_type === assetType
    );
  },
  findModelByVariationId(variationId) {
    return this.mProducts.find(
      (product) =>
        product.component_groups[0].components[0].available_variations[0].id ===
        variationId
    );
  },
  getModelVariation(modelName) {
    const model = this.findModelByName(modelName);
    return model.component_groups[0].components[0].available_variations[0];
  },
  getAllModelsVariations(categories) {
    let products = this.mProducts;
    if (categories) {
      products = this.mProducts.filter((product) =>
        categories.includes(product.category_id)
      );
    }

    return products;
  },
  findSelectedVariantByComponentName(name) {
    const variations = []
    this.mSelectedVariations.forEach(v => variations.push(this.findVariationBy(v)));
    
    return variations.find((variation) =>
      this.findComponentById(variation.component_id).name.includes(name)
    );
  },
  findSelectedVariantByParentComponentName(name, floorSelected) {
    const allComponents = this?.mLoadedData.filter(x => floorSelected?.toLowerCase().includes(x.name.toLowerCase()));
    const filterComponents = [];
    const filterVariations = [];

    allComponents.forEach((item) =>
      item.component_groups.forEach((i) => filterComponents.push(i))
    );

    const sideComponentGroup = filterComponents.find((el) => el.name.includes(name));
    const selectedVariations = this.getSelectedVariations();

    selectedVariations.forEach((variation) => {
      if(!sideComponentGroup) {
        return;
      }

      const component = this.findComponentById(variation.component_id);
      const componentGroup = this.findComponentGroupById(component?.group_id);

      if (
        sideComponentGroup.id ===
        component?.group_id ||
        (name === componentGroup?.name &&
          variation.product_category_id === AccessoriesCategoryId)
      ) {
        filterVariations.push(variation);
      }
    });

    return filterVariations;
  },
  findVariationType(componentName) {
    componentName = componentName.toLowerCase();
    const key = `component_${componentName}_subaccordion_type`;
    return this.mApplicationData.sections[1].key_values[key];
  },
  findVariationBy(id) {
    return this.mVariations.find((v) => v.id === id);
  },
  findVariationByKey(key) {
    return this.mVariations.find((v) => v.original_key === key);
  },
  findComponentById(id) {
    return this.mComponents.find((c) => c.id === id);
  },
  findParentComponentById(grID) {
    return this.mComponents.find((c) => c.id === grID);
  },
  findComponentVariations(id) {
    return this.mVariations.filter((v) => v.component_id === id);
  },
  findComponentByVariationId(id) {
    const variation = this.findVariationBy(id);
    if (!variation) {
      return null;
    }
    return this.findComponentById(variation.component_id);
  },
  findChildrenForVariation(id) {
    const childrenVariations = [];
    this.mVariations.forEach((v) => {
      if (v.dependencies.find((d) => d.depends_on_variation_id === id)) {
        childrenVariations.push(v);
      }
    });
    return childrenVariations;
  },
  findChildrenComponentsForVariation(variationId) {
    const variations = this.findChildrenForVariation(variationId);
    if (variations.length === 0) {
      return [];
    }

    const componentIds = [...new Set(variations.map((v) => v.component_id))];
    if (!componentIds) {
      return [];
    }
    const components = [];
    componentIds.forEach((cId) => components.push(this.findComponentById(cId)));

    return components;
  },
  isVariationSelected(id) {
    return this.mSelectedVariations.includes(id);
  },
  toggleVariationSelection(id, action, ignoreHooks = false) {
    const oldSelectionState = [];
    if (!ignoreHooks) {
      this.mSelectedVariations.forEach((v) => oldSelectionState.push(v));
    }
    const index = this.mSelectedVariations.indexOf(id);
    const variation = this.findVariationBy(id);
    const component = this.findComponentById(variation?.component_id);
    let event;

    if (action !== "add" && (index !== -1 || action === "delete")) {
      if (index > -1) {
        if (
          !ignoreHooks &&
          component.required_choice &&
          this.isVariationSelected(id)
        ) {
          // if the component has only two variations switch to the other variations
          const componentVariations = this.mVariations.filter(
            (v) => v.component_id === component.id && v.id !== id
          );
          if (componentVariations.length === 1) {
            this.mSelectionHooks.forEach((cb) => {
              cb([id], "unselect");
            });
            this.mSelectedVariations.splice(index, 1);
            if (typeof this.mLoadedScripts[variation.id] !== "undefined") {
              this.mLoadedScripts[variation.id].onUnselect(variation, this);
            }
            this.toggleVariationSelection(componentVariations[0].id);
          } else {
            this.mSelectionHooks.forEach((cb) => {
              cb([id], "select");
            });
          }
          return;
        }
        this.mSelectedVariations.splice(index, 1);
        event = "unselect";
        if (typeof this.mLoadedScripts[variation.id] !== "undefined") {
          this.mLoadedScripts[variation.id].onUnselect(variation, this);
        }
      }
      if (!component.multiple && !ignoreHooks) {
        this.clearSameComponentSelection(id);
      }
      this.removeNestedVariationSelection(id);
    } else if (!action || action === "add") {
      this.mSelectedVariations.push(id);
      if (typeof this.mLoadedScripts[variation.id] !== "undefined") {
        this.mLoadedScripts[variation.id].onSelect(variation, this);
      }
      event = "select";
      if (!component.multiple) {
        this.clearSameComponentSelection(id);
        this.selectNestedDefaultVariation(id);
      }
      
      let selectedVariation = this.getmLoadedDataSingleProduct(variation);
      if(selectedVariation.name === "Accessories"){
        selectedVariation = this.getmLoadedDataSingleProduct(this.findVariationBy(this.getCurrentModel()));
      }
      if(selectedVariation){
        this.logEvent("variation", variation.id, {
          product_id: selectedVariation.id,
          description: selectedVariation.product_info.description,
        })
      }
    }
    if (!ignoreHooks) {
      this.mSelectionHooks.forEach((cb) => {
        cb([id], event);
      });

      const newSelectionState = [];
      this.mSelectedVariations.forEach((v) => newSelectionState.push(v));

      const jsKeys = Object.keys(this.mLoadedScripts);
      if (jsKeys.length > 0) {
        for (let idx = 0; idx < jsKeys.length; idx += 1) {
          this.mLoadedScripts[jsKeys[idx]].onSelectionChanged(
            id,
            oldSelectionState,
            newSelectionState,
            this
          );
        }
      }
    }
  },
  isVariationDependentOn(variation, onVariation) {
    return variation.dependencies.some(
      (v) => v.depends_on_variation_id === onVariation.id
    );
  },
  clearSameComponentSelection(id) {
    const selectedVariation = this.findVariationBy(id);
    const deletedVariationIds = [];
    for (let idx = 0; idx < this.mSelectedVariations.length; idx += 1) {
      if (this.mSelectedVariations[idx] !== id) {
        const variation = this.findVariationBy(this.mSelectedVariations[idx]);
        if (
          selectedVariation.component_id === variation.component_id &&
          !this.isVariationDependentOn(selectedVariation, variation)
        ) {
          this.mSelectedVariations.splice(idx - deletedVariationIds.length, 1);
          deletedVariationIds.push(variation.id);
        }
      }
    }
    deletedVariationIds.forEach((v) =>
      this.removeNestedVariationSelection(v, id)
    );
  },
  removeNestedVariationSelection(id, excludeVariation = 0) {
    const childrenComponents = this.findChildrenComponentsForVariation(id);

    if (childrenComponents) {
      childrenComponents.forEach((c) =>
        this.mVariations
          .filter((v) => v.component_id === c.id)
          .forEach((variationToDelete) => {
            if (
              this.mSelectedVariations.includes(variationToDelete.id) &&
              excludeVariation !== variationToDelete.id
            ) {
              this.toggleVariationSelection(
                variationToDelete.id,
                "delete",
                true
              );
            }
          })
      );
    }
  },
  selectNestedDefaultVariation(id) {
    const childrenComponents = this.findChildrenComponentsForVariation(id);

    if (childrenComponents) {
      childrenComponents.forEach((c) => {
        if (c.required_choice) {
          const componentVariations = this.findComponentVariations(c.id);
          componentVariations.forEach((cv) => {
            if (cv.is_default && !this.isVariationSelected(cv.id)) {
              this.toggleVariationSelection(cv.id);
            }
          });
        }
      });
    }
  },
  getSelectedVariations() {
    const productCategoryId = this.findSelectedVariantByComponentName("Floor")?.product_category_id
    const selectedVariations = [];
    this.mSelectedVariations.forEach((vId) =>
      selectedVariations.push(this.findVariationBy(vId))
    );

    const filteredVariations = selectedVariations?.filter(variation => {
      return variation.product_category_id === Number(productCategoryId)  || variation.product_category_id === AccessoriesCategoryId
    })

    const uniqueFilterVariations = filteredVariations.filter(variation => {
      const component = this.findComponentById(variation.component_id);
     
      let shouldShowGranplanel = true;

      if(component.name === "Fasad Type 1" && variation.original_key === "granpanel") {
        shouldShowGranplanel = false;
      } else if (
        this.findSelectedVariantByComponentName("Fasad Type 1")?.original_key !== "granpanel" && component.name === "Fasad Granplanel Colors"
      ) {
        shouldShowGranplanel = false;
      } else {
        shouldShowGranplanel = true;
      }

      return !excludedComponents.includes(component.name.toLowerCase()) && shouldShowGranplanel;
    });

    return uniqueFilterVariations;
  },
  hasSubstring(substrings, variation) {
    const component = this.findComponentById(variation.component_id);
    return substrings.some((componentNameSubstring) =>
      component.name.includes(componentNameSubstring)
    );
  },
  findVariationByComponentName(variations, cName) {
    return variations.find((variation) => {
      const component = variation.component_id
        ? this.findComponentById(variation.component_id)
        : this.findComponentById(this.findVariationBy(variation).component_id);
      return component?.name === cName;
    });
  },
  extractModelDescription(variations) {
    if (!variations) {
      return "";
    }
    const descriptionItems = [];

    const colorOutsideVariation = this.findVariationByComponentName(
      variations,
      AccordionEnum.Color
    );
    const colorInsideVariation = this.findVariationByComponentName(
      variations,
      AccordionEnum.Color_Inside
    );
    if (colorInsideVariation) {
      descriptionItems.push(
        this.sectionKey(
          "product_descriptions",
          colorInsideVariation.original_key
        )
      );
    }

    if (colorOutsideVariation) {
      descriptionItems.push(
        this.sectionKey(
          "product_descriptions",
          colorOutsideVariation.original_key
        )
      );
    }

    return descriptionItems.join(", ");
  },
  clearCustomDimensions() {
    this.mCustomDimensions = null;
  },
  generateShareableLink(baseLink, isArLink = false) {
    const base = baseLink || window.location.href?.split("?")[0];
    const ARPart = isArLink ? "&ar=ok" : "";
    return `${base}?s=${this.generateUniqueHashForSelections()}${ARPart}`
  },
  generateUniqueHashForSelections() {
    const selections = this.mSelectedVariations.filter((value, index, self) => self.indexOf(value) === index);
    return lz.compressToBase64(
      JSON.stringify({ selections, floor: sessionStorage.getItem("floorSelected") })
    );
  },
  getFloorData(singleFloor, order) {
    const allFloors = this.mLoadedData;
    return allFloors
      ?.filter((m) => m.category_id === Number(singleFloor))[0]
      .component_groups.find((m) => m.name === "Floor")?.components[order]
      ?.available_variations;
  },
  getFloorPdfData(singleFloor) {
    const allFloors = this.mLoadedData;
    return allFloors
      ?.filter((m) => m.category_id === Number(singleFloor))[0]
      .component_groups.find((m) => m.name === "Floor")?.components?.find(subComp => subComp?.name === "Floor Pdf")
      ?.available_variations;
  },
  getProductByCatId(catId) {
    const productFromCatId = this.mLoadedData;
    return productFromCatId?.filter((m) => m.category_id === Number(catId));
  },
  loadSelectionFromArray(content){
    this.mSelectedVariations = [];
    if(content.floor) {
      sessionStorage.setItem("floorSelected", content.floor);
    }

    if (content.selections) {
      content.selections?.forEach((s) =>
        this.mSelectedVariations.push(s)
      );
      this.mSelectionHooks.forEach((cb) =>
        cb(this.mSelectedVariations, "select")
      );
    }
  },
  loadSelectionFromHash(hash) {
    try {
      const content = JSON.parse(atob(hash));
      this.mSelectedVariations = [];
      this.mSelectedVariations = content.selections;
      if (content.selections) {
        content.selections?.forEach((s) =>
          this.mSelectedVariations.push(s)
        );
        this.mSelectionHooks.forEach((cb) =>
          cb(this.mSelectedVariations, "select")
        );
      }
    } catch (e) {
      // silient exceptioon
    }
  },
  submitForm(data, callback) {
    axios
      .post("https://onecx.rapidimages.com/api/v1/contacts", data, {
        headers: {
          "X-Header-ApiKey": this._apiKey,
          "X-Header-AppId": this._applicationId,
        },
      })
      .then((r) => {
        if (callback) {
          callback(r);
        }
      })
      .catch((err) => {
        if (callback) {
          callback(err);
        }
      });
  },
  uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    );
  },
  findSelectedVariantByComponentNameAndCat(name, cat) {
    const selectedVariations = [];

    this.mSelectedVariations.forEach((id) =>
      selectedVariations.push(this.findVariationBy(id))
    );

    return selectedVariations.find(
      (variation) =>
        this.findComponentById(variation.component_id).name.toLowerCase() === name.toLowerCase() &&
        variation.product_category_id === Number(cat)
    );
   
  },
  findSelectedVariantByComponentId(name){
    const selectedVariations = [];

    this.mSelectedVariations.forEach((id) =>
      selectedVariations.push(this.findVariationBy(id))
    );

    return selectedVariations.find(
      (variation) =>
        this.findComponentById(variation.component_id).name === name
    );
  },
  logEvent(event, variation, taglist, forceLog = false) {
    if (!this.mCanTrack && !forceLog) {
      return;
    }

    fetch(
      "https://one-ins.onecx.rapidimages.com/api/logs/analytics/analyticTrack",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(
          {
            e: event,
            e_v: variation,
            a: this._applicationId,
            s: this.getSessionId(),
            v: this.getVisitorId(),
            tags: taglist,
          }
        ),
        keepalive: true,
      }
    );
  },
  getDeviceType() {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    }
    if (
      /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  },
  getProductById(id) {
    return this.mLoadedData.find((x) => x.id === id);
  },
  setIsFloorMirror(value){
    this.mFloorMirror = value;
  },
  getIsFloorMirror(){
    return this.mFloorMirror;
  },
  getMultiSelect(label){
    return this.findComponentByName(label).multiple
  },
  getmLoadedDataSingleProduct(variation) {
    return this.mLoadedData.find((x) => x.id === variation?.product_id);
  },
  getIsDefault(label, cat){
    let is_default = false;

    const data = this.findEqualComponentByName(label, cat)?.available_variations;
    data.map((variation) => {
      if(variation.is_default){
        is_default = true;
      }
    })
    return is_default;
  },
  clearSelectionHooks() {
    this.mSelectionHooks = [];
  },
  clearSelectedVariations() {
    this.mSelectedVariations = [];
  },
  findComponentGroupById(id) {
    return this.mComponentGroups.find((cg) => cg.id === id);
  },
  getVisitorId() {
    if (this.mVisitorId) {
      return this.mVisitorId;
    }

    this.mVisitorId = localStorage.getItem("visitorId");

    if (!this.mVisitorId) {
      const visitorId = this.uuidv4();
      localStorage.setItem("visitorId", visitorId);
      this.mVisitorId = visitorId;
    }

    return this.mVisitorId;
  },
  getSessionId() {
    if (!this.mSessionId) {
      this.generateSessionId();
    }

    return this.mSessionId;
  },
  generateSessionId() {
    this.mSessionId = this.uuidv4();
  },
};
