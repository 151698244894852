import React from "react";
import { LogoProps } from "./Types";
import LogoImg from "../../assets/images/logo.svg";
import classnames from "classnames";

export const Logo = ({ to, className, parentClassName }: LogoProps) => {
  return (
    <a href={to} className={parentClassName}>
      <img
        src={LogoImg}
        alt="Logo"
        className={classnames("w-[5.5rem] h-[2.6rem]", className)}
      />
    </a>
  );
};
