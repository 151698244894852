import { Engine, Scene } from "@babylonjs/core";
import React, {useEffect, useRef } from "react";
import { SceneComponentProps } from "../../common/interfaces/scene.interfaces";

const SceneComponent: React.FC<SceneComponentProps> = (props) => {
  const reactCanvas = useRef(null);
  const { antialias, engineOptions, sceneOptions, onSceneReady,  ...rest } = props;

  useEffect(() => {
    if (reactCanvas.current) {
      const engine = new Engine(reactCanvas.current, antialias, engineOptions, true);
      const scene = new Scene(engine, sceneOptions);
      scene.skipFrustumClipping = true;
      scene.autoClear = false;
      scene.autoClearDepthAndStencil = false;
      scene.blockMaterialDirtyMechanism = true;
      scene.getAnimationRatio();
     
      if (scene.isReady()) {
        onSceneReady(scene);
      } else {
        scene.onReadyObservable.addOnce((sceneObservable) => onSceneReady(sceneObservable));
      }
  
      engine.runRenderLoop(() => {
        scene.render();
      });
  
      const resize = () => {
        scene.getEngine().resize();
      };
  
      if (window) {
        window.addEventListener("resize", resize);
      }

      engine.enableOfflineSupport = false;
      scene.clearCachedVertexData();
      scene.cleanCachedTextureBuffer();
  
      return () => {
        scene.getEngine().dispose();
  
        if (window) {
          window.removeEventListener("resize", resize);
        }
      };
      
    }
  }, [reactCanvas]);

  return (
    <canvas
      id="woodCanvas"
      className="h-full transition-all duration-500 w-full"
      ref={reactCanvas}
      {...rest}
    />
  );
};

export default SceneComponent;
