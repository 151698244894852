import React from "react";
import { IconProps } from "../Types";

const Profile = ({ className }: IconProps) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="7.5" y="7.5" width="25" height="25" rx="0.5" stroke="black" />
    <mask fill="white">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29 17.1229C29 17.6752 28.5523 18.1229 28 18.1229L27.7967 18.1229C27.2604 18.1229 26.8231 18.5461 26.7475 19.0771C26.3379 21.9531 24.5297 26.2518 19.6573 26.6706C19.0977 26.7187 18.6321 27.1634 18.6321 27.7251L18.6321 29C18.6321 29.5523 18.1844 30 17.6321 30L8.00001 30C7.44773 30 7.00001 29.5523 7.00001 29L7.00001 8C7.00001 7.44772 7.44773 7 8.00001 7L28 7C28.5523 7 29 7.44772 29 8L29 17.1229Z"
      />
    </mask>
    <path
      d="M28 7L28 6L28 7ZM8.00001 7L8.00001 8L8.00001 7ZM19.6573 26.6706L19.743 27.6669L19.6573 26.6706ZM26.7475 19.0771L27.7375 19.2181L26.7475 19.0771ZM27.7967 19.1229L28 19.1229L28 17.1229L27.7967 17.1229L27.7967 19.1229ZM19.743 27.6669C22.4985 27.4301 24.4159 26.0793 25.6766 24.3944C26.9166 22.7373 27.5164 20.7709 27.7375 19.2181L25.7575 18.9361C25.5691 20.2593 25.0599 21.8803 24.0753 23.1962C23.1114 24.4844 21.6886 25.4923 19.5717 25.6743L19.743 27.6669ZM19.6321 29L19.6321 27.7251L17.6321 27.7251L17.6321 29L19.6321 29ZM17.6321 29L8.00001 29L8.00001 31L17.6321 31L17.6321 29ZM8.00001 29L8.00001 8L6.00001 8L6.00001 29L8.00001 29ZM8.00001 8L28 8L28 6L8.00001 6L8.00001 8ZM28 8L28 17.1229L30 17.1229L30 8L28 8ZM28 8L28 8L30 8C30 6.89544 29.1046 6 28 6L28 8ZM8.00001 8L8.00001 6C6.89545 6 6.00001 6.89543 6.00001 8L8.00001 8ZM8.00001 29L6.00001 29C6.00001 30.1046 6.89544 31 8.00001 31L8.00001 29ZM17.6321 29L17.6321 29L17.6321 31C18.7367 31 19.6321 30.1046 19.6321 29L17.6321 29ZM19.5717 25.6743C18.5687 25.7605 17.6321 26.5735 17.6321 27.7251L19.6321 27.7251C19.6321 27.7243 19.6322 27.7246 19.632 27.7255C19.6318 27.7264 19.6316 27.7267 19.6318 27.7263C19.6321 27.7255 19.635 27.7194 19.644 27.7104C19.6641 27.6901 19.6997 27.6706 19.743 27.6669L19.5717 25.6743ZM28 19.1229C29.1046 19.1229 30 18.2275 30 17.1229L28 17.1229L28 17.1229L28 19.1229ZM27.7967 17.1229C26.7061 17.1229 25.8951 17.9704 25.7575 18.9361L27.7375 19.2181C27.7428 19.181 27.7608 19.1514 27.779 19.1344C27.7873 19.1267 27.7932 19.124 27.7949 19.1233C27.7958 19.1229 27.7956 19.1229 27.7967 19.1229L27.7967 17.1229Z"
      fill="black"
      mask="url(#path-2-inside-1_3463_6585)"
    />
  </svg>
);

export default Profile;
