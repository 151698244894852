/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/prop-types */
import classnames from "classnames";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { Button } from "../button/Button";
import { Header } from "../header/Header";
import { Menu } from "../menu";
import SubAccordion from "../subAccordions/SubAccordion";
import { Accordion } from "../accordion";
import { Modal } from "../modal/Modal";
import { Footer } from "../footer";
import { Logo } from "../logo/Logo";
import { IconName } from "../icon/Types";
import { useNavigate, useParams } from "react-router";
import { BabylonContext } from "../../common/context/BabylonProvider";
import useCheckMobileScreen from "../../hooks/CheckMobileScreen";
import { AccessoriesCategoryId, AccordionEnum } from "src/services/enums";
import { ContactModal } from "../contactModal/ContactModal";
import Submenu from "../submenu/Submenu";

const SingleComponents = (props) => {
  const {
    dataReady,
    dataContainer,
    setSelectionAccordion,
    selectionAccordion,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const [scrollIndex, setScrollIndex] = useState("");
  const [accordion, setAccordion] = useState(false);
  const [iconKey, setIconKey] = useState<string | null>(null);
  const [accordionKey, setAccordionKey] = useState<number | null>();
  const { cat, singleID } = useParams();
  const [isProductModalOpen, setIsProductModalOpen] = useState(false);
  const { state, dispatch } = useContext(BabylonContext);
  const houseModel = dataContainer?.getProductByCatId(cat)[0].name;
  const menuOptions = dataContainer
    ?.getProductByCatId(cat)[0]
    ?.component_groups?.filter(
      (m) =>
        m.name === AccordionEnum.Exterior || m.name === AccordionEnum.Interior
    );
  const [defaultPointView, setDefaultPointView] = useState(
    menuOptions && menuOptions[0]?.id
  );
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const filteredAccordions = dataContainer?.section("Filtered Components");

  const accordionItems = dataContainer
    ?.getSingleAccordions(cat, singleID)
    .filter((item) => {
      return (
        !Object.values(filteredAccordions).find((el) =>
          item.name.includes(el)
        ) && item
      );
    });

  const isMobile = useCheckMobileScreen();
  const isTabletLandscape = useCheckMobileScreen(1023);
  
  useEffect(() => {
    if(singleID?.includes("klassisk_d")){
      dataContainer?.toggleVariationSelection(dataContainer?.findVariationByKey("gable_opening").id);
    }
  }, [dataContainer]);

  const generateARLink = () => {
    if(!dataContainer || !dataContainer.mSelectedVariations){
      return;
    }

    return dataContainer?.generateShareableLink(undefined, true);
  };

  const getMenuItems = () => {
    const items = accordionItems
      ?.filter(
        (m) =>
          m.group_id === defaultPointView ||
          (state?.pointView?.includes(AccordionEnum.Interior)
            ? m.group_id === 514
            : m.group_id === 513)
      )
      .map(
        (item: {
          id: string;
          name: IconName;
          onClick: React.Dispatch<React.SetStateAction<string | null>>;
        }) => {
          return {
            id: item.id,
            icon: item.name
              .split(" ")
              .join("_")
              .replace(/ä/, "a")
              .replace(/_&/, ""),
            onClick: setIconKey,
          };
        }
      );

    items.push({ id: "300", icon: "OpenMenu", onClick: setIconKey });

    return items;
  };
  const closeMenuHandler = () => {
    setMenuOpen(false);
    dispatch({ type: "SET_MENU_OPEN", payload: !state.menuOpen });
    setIconKey(null);
    if(!isMobile){
      setSelectionAccordion("");
      setScrollIndex("");
    }
    setAccordionKey(null);
  };

  useEffect(() => {
    if (iconKey) {
      setMenuOpen(true);
      dispatch({ type: "SET_MENU_OPEN", payload: !state.menuOpen });
      setScrollIndex(iconKey);
    }
  }, [iconKey]);

  useEffect(() => {
    if (menuOptions) {
      setDefaultPointView(menuOptions[0]?.id);
      dispatch({ type: "SET_POINT_VIEW", payload: menuOptions[0]?.name });
    }
  }, [dataContainer]);

  useEffect(() => {
    if (dataContainer) {
      const variations = dataContainer?.mVariations.filter(variation => variation.product_category_id === Number(cat) || variation.product_category_id === AccessoriesCategoryId);
      const selectedVariations = [...dataContainer.mSelectedVariations];

      variations?.map((v) => {
        const component = dataContainer?.findComponentById(v.component_id);
        const selectedVariationByComponentName =
          dataContainer.findSelectedVariantByComponentNameAndCat(
            component?.name,
            cat
          );
        if (!v?.is_default && selectedVariations.includes(v.id)) {
          if (selectedVariationByComponentName) {
            dataContainer.toggleVariationSelection(
              selectedVariationByComponentName.id,
              "delete",
              true
            );
            dataContainer.toggleVariationSelection(v?.id, "add");
            return;
          }
          dataContainer.toggleVariationSelection(v?.id, "add");
        } else if (v?.is_default && !selectedVariationByComponentName) {
          dataContainer.toggleVariationSelection(v?.id, "add");
        }
      });
    }
  }, [dataContainer]);

  useEffect(() => {
    if (singleID && dataContainer) {
      const toggleVariant = dataContainer?.findVariationByKey(singleID);
      if( !dataContainer.isVariationSelected(toggleVariant?.id) ) {
        dataContainer?.toggleVariationSelection(toggleVariant?.id, "add");
      }
    }
  }, [singleID, dataContainer]);

  const shouldAccordionHide = (component: {id: number}) => {
    const selectedComponent = dataContainer?.findComponentById(accordionKey);

    return (
      isMobile &&
      selectionAccordion &&
      component?.id !== accordionKey &&
      selectedComponent?.group_id === defaultPointView
    );
  };
  return (
    <Fragment>
      <Header
        secondary={true}
        className={classnames(
          {
            "opacity-100 visible": !menuOpen || !isModalOpen,
          },
          { "opacity-0 invisible": menuOpen },
          "absolute w-full z-20 bg-transparent transition-all duration-200"
        )}
        toogleModal={() => setIsModalOpen(!isModalOpen)}
        toogleInfo={() => setIsProductModalOpen(!isProductModalOpen)}
        dataContainer={dataContainer}
        onBackClick={() => navigate(`/floor/${cat}`)}
      />
      <Logo
        to="/"
        className="sm:hidden flex absolute top-6 left-1/2 -translate-x-1/2"
      />
      <div className="sm:flex absolute sm:h-full sm:w-0 w-full bottom-8 sm:bottom-0 sm:justify-end justify-center right-0 flex items-center transition-all duration-300 z-10">
        <Button
          icon={isTabletLandscape ? "MenuArrow" : "LeftLarge"}
          iconClassName="w-24 h-8 sm:w-10 sm:h-10 flex justify-center items-center"
          onClick={() => {
            setMenuOpen(!menuOpen);
            dispatch({ type: "SET_MENU_OPEN", payload: !state.menuOpen });
          }}
        />
      </div>
      <ContactModal
        dataContainer={dataContainer}
        isOpen={isContactModalOpen}
        setIsOpen={setIsContactModalOpen}
      />
      <Menu
        className={classnames(
          {
            "sm:right-0 overflow-x-hidden bottom-0 opacity-100 visible":
              menuOpen,
          },
          { "sm:-right-full bottom-0 invisible opacity-0": !menuOpen }
        )}
        option={menuOptions}
        catName="Attefallshus"
        name={houseModel}
        onClose={closeMenuHandler}
        pointView={state.pointView}
        scrollIndex={scrollIndex}
        onTabChange={(e, name) => {
          setDefaultPointView(e);
          dispatch({ type: "SET_POINT_VIEW", payload: name });
        }}
        dataContainer={dataContainer}
        isAccordionOpen={selectionAccordion !== ""}
      >
        {accordionItems !== undefined &&
          accordionItems
            ?.filter(
              (m) =>
                m.group_id === defaultPointView ||
                (state.pointView?.includes(AccordionEnum.Interior)
                  ? m.group_id === 514
                  : m.group_id === 513)
            )
            .map(
              (accordion) =>
                accordion.name && (
                  <Accordion
                    id={accordion.id}
                    key={accordion.id}
                    icon={accordion.name
                      .split(" ")
                      .join("_")
                      .replace(/ä/, "a")
                      .replace(/_&/, "")}
                    name={dataContainer?.sectionKey(
                      "Translate",
                      accordion.name.toLowerCase().split(" ").join("_")
                    )}
                    isOpen={
                      accordion.id === accordionKey || accordion.id === iconKey
                    }
                    isHidden={shouldAccordionHide(accordion)}
                    onChange={(e) => {
                      setAccordionKey(accordion.id);
                      setAccordion(e);
                      accordion && setIconKey(null);
                      setSelectionAccordion(
                        e && accordion.name && accordion.name
                      );
                    }}
                  >
                    <SubAccordion
                      filter={accordion?.filter}
                      actionTitle={accordion?.actionsTitle}
                      id={defaultPointView}
                      variant={dataContainer?.findVariationType(
                        accordion.name
                          .split(" ")
                          .join("_")
                          .replace(/ä/, "a")
                          .replace(/_&/, "")
                      )}
                      items={accordion.available_variations}
                      options={accordion.available_variations?.options}
                      label={accordion.name}
                      dimensions={accordion.available_variations?.dimensions}
                      dataContainer={dataContainer}
                      setSelectionAccordion={setSelectionAccordion}
                      accordionKey={accordionKey || 0}
                      iconKey={iconKey || ""}
                    />
                  </Accordion>
                )
            )}
      </Menu>
      <Modal
        isOpen={isModalOpen}
        close={() => {
          setIsModalOpen(false);
        }}
        className="Modal backdrop-blur-sm z-50 justify-center bottom-1/2 right-1/2 sm:h-full sm:w-full h-3/5 w-11/12 translate-y-1/2 translate-x-2/4 bg-white absolute flex items-center transition-all duration-300"
        text={
          <p className="pl-5 text-center">
            {dataContainer?.sectionKey("Translate", "modal_text")}
          </p>
        }
        QRLink={generateARLink()}
      />
      {!menuOpen && !isContactModalOpen && (
        <div className="absolute p-6 bg-transparent bg-opacity-100 sm:bottom-[6rem] bottom-2 flex justify-center items-end z-[15] h-max">
          <Button
            icon="QuestionMark"
            iconClassName={classnames("!fill-white")}
            secondary
            textClassName="text-black ml-3.5 flex transition-all duration-200sm:text-xs text-3xl"
            label="Fråga oss"
            onClick={() => setIsContactModalOpen(!isContactModalOpen)}
          />
        </div>
      )}

      {!menuOpen && state?.pointView === "Interior" && (
        <Submenu
          isSubmenuOpen={isSubmenuOpen}
          setIsSubmenuOpen={setIsSubmenuOpen}
          setSelectionAccordion={setSelectionAccordion}
          singleID={singleID}
          dataContainer={dataContainer}
        />
      )}
      {dataReady && (
        <Footer
          className={classnames(
            { "opacity-0 invisible": menuOpen },
            { "opacity-100 visible": !menuOpen },
            "transition-all duration-200 z-10"
          )}
          menuItems={getMenuItems()}
          setAccordionSelected={setSelectionAccordion}
          setPointView={(e, name) => {
            setDefaultPointView(e.id);
            dispatch({ type: "SET_POINT_VIEW", payload: name });
          }}
          product={{
            name: "Attefallshus",
            model: houseModel,
            items: menuOptions,
          }}
        />
      )}
      <Logo
        className={classnames(
          {
            "sm:block md:hidden hidden opacity-100 absolute left-10 bottom-5":
              accordion || iconKey,
          },
          { hidden: !accordion && !iconKey }
        )}
        to="/"
      />
    </Fragment>
  );
};

export { SingleComponents };
