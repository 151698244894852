import React from "react";
import { IconProps } from "../Types";

const Interior = ({ className, fill }: IconProps) => (
  <svg
    className={className}
    width="29"
    height="21"
    viewBox="0 0 29 21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.1052 3.29823L14.1052 1.0255L4.10523 3.29823V17.5H7.96237V16.4762H6.10523V12.2857L6.67665 12.2857C6.99225 12.2857 7.24808 12.5415 7.24808 12.8571C7.56345 12.8569 7.81902 12.6011 7.81902 12.2857V11.7143C7.81902 10.7675 8.58653 10 9.5333 10H18.6762C19.6229 10 20.3904 10.7675 20.3904 11.7143V12.2857C20.3904 12.6013 20.6463 12.8571 20.9619 12.8571L20.9624 12.8571C20.9624 12.5415 21.2182 12.2857 21.5338 12.2857L22.1052 12.2856V16.4762H20.2481V17.5H24.1052V3.29823ZM19.1052 17.5V16.4762H9.10523L9.10522 17.5H19.1052ZM3.10523 17.5V18.5V19.5H0.605225C0.329083 19.5 0.105226 19.7239 0.105226 20C0.105226 20.2761 0.329083 20.5 0.605226 20.5H3.10523H25.1052H27.6052C27.8814 20.5 28.1052 20.2761 28.1052 20C28.1052 19.7239 27.8814 19.5 27.6052 19.5H25.1052V18.5V17.5V3.36866L27.5113 3.88425C27.7813 3.94211 28.0471 3.77013 28.105 3.50011C28.1628 3.2301 27.9908 2.96431 27.7208 2.90645L14.3145 0.0336618C14.2645 0.0229502 14.2139 0.0161153 14.1631 0.0131571L14.1052 0L14.0475 0.0131147C13.9965 0.0160496 13.9457 0.0228986 13.8954 0.0336618L0.489088 2.90645C0.219076 2.96431 0.0470922 3.2301 0.104952 3.50012C0.162812 3.77013 0.428606 3.94211 0.698618 3.88425L3.10523 3.36855V17.5ZM24.1052 19.5V18.5H4.10523V19.5H24.1052ZM9.5333 11.1429C9.21771 11.1429 8.96187 11.3987 8.96187 11.7143V12.2857C8.96187 13.2323 8.19463 13.9997 7.24808 14V15.3333H20.9624V14H20.9619C20.0151 14 19.2476 13.2325 19.2476 12.2857V11.7143C19.2476 11.3987 18.9917 11.1429 18.6762 11.1429H9.5333Z"
      fill={fill}
    />
  </svg>
);

export default Interior;
