/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Typography } from "../typography/Typography";
import classnames from "classnames";
import { Icon } from "../icon/Icon";
import useCheckMobileScreen from "src/hooks/CheckMobileScreen";

export const List = ({ title, items, className, listOnChange, listHidden, listUsedOnHomepage = false, toggleList = true, setToggleList }) => {
  const [isActive, setIsActive] = useState(items[0]?.id);
  const [name, setName] = useState(items[0]?.key);

  useEffect(() => {
    if (!items.some((item) => item.id === isActive)) {
      setIsActive(items[0].id);
      setName(items[0].key);
    }
  }, [items]);

  const isTabletLandscape = useCheckMobileScreen(1023);

  return (
    <div
      className={classnames(
        {
          "border-b border-primary pb-5": isTabletLandscape && listHidden && toggleList,
        },
        "sm:mt-0 mt-6"
      )}
    >
      {title && (
        <Typography
          tag="h1"
          className={classnames(
            "mb-9 mt-8 md:mb-5 leading-6 tracking-wider text-primary font-normal ml-0",
            className
          )}
          parentClassName="h-auto"
        >
          {title}
        </Typography>
      )}
      {items && (
        <>
          {listHidden && (
            <div className="flex">
              <button className="flex items-center" onClick={() => setToggleList(!toggleList)}>
                <p className="text-primary text-xs pr-3">{name}</p>
                <Icon
                  name={toggleList ? "BottomArrow" : "TopArrow"}
                  stroke="#ffffff"
                />
              </button>
            </div>
          )}
          <div className="max-h-auto">
            <ul
              className={classnames(
                "sm:h-auto",
                {
                  "flex flex-col pt-5 left-0 ml-10 absolute w-full h-full bg-smoke z-30":
                    !toggleList,
                },
                {"!list-disc" : listUsedOnHomepage},
                { "hidden list_hidden": listHidden && toggleList }
              )}
            >
              {items.map((item, index) => (
                <li
                  key={`list-item-${item.id}-${index}`}
                  className={classnames(
                    "flex text-3xl sm:text-xs relative mb-5 sm:ml-4 cursor-pointer justify-start text-primary before:content-[''] before:w-1 before:h-1 before:bg-primary before:rounded-full before:absolute before:-left-3 before:top-1/2 before:-translate-y-1/2 before:opacity-0",
                    { "text-2xl ": listHidden && toggleList },
                    { "before:hidden !text-xs mb-6": !toggleList },
                    { hidden: isActive === item.id && !toggleList },
                    {"!list-disc" : listUsedOnHomepage},
                    (isActive === item.id &&
                      "opacity-100 before:opacity-100 !text-xs") ||
                      "opacity-40",
                    item.className
                  )}
                  onClick={() => {
                    setIsActive(item.id);
                    setName(item?.key);
                    listOnChange(item);
                    (!listUsedOnHomepage && isTabletLandscape) && setToggleList(!toggleList);
                  }}
                >
                  {item?.name}
                  {item?.key}
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
