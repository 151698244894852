import { Mesh, MeshAssetTask } from "@babylonjs/core";
import { facadeMeshes, insideDoorsMeshes, insideWindowMeshes, outsideDoorsMeshes, outsideWindowMeshes, trimFacade } from "../HideMeshes";

const inDoorMeshes: Array<Mesh> = [];
const outDoorMeshes: Array<Mesh> = [];
const inWindowMeshes: Array<Mesh> = [];
const outWindowMeshes: Array<Mesh> = [];
const allFasadeMeshes: Array<Mesh> = [];
const trimFasadeMeshes: Array<Mesh> = [];

export const applyColorMaterials = (dataContainer, cat) => {
  const outsideColorVariation = dataContainer?.findSelectedVariantByComponentId("Doors Colors Outside");
  const insideColorVariation = dataContainer?.findSelectedVariantByComponentId("Doors Colors Inside");
  const outsideWindowsColorVariation = dataContainer?.findSelectedVariantByComponentId("Windows Colors Outside");
  const insideWindowsColorVariation = dataContainer?.findSelectedVariantByComponentId("Windows Colors Inside");
  const fasadeColorVariation = dataContainer?.findSelectedVariantByComponentNameAndCat("Fasad Type 1", cat);
  const fasadeGranPanelColorVariation = dataContainer?.findSelectedVariantByComponentId("Fasad Granplanel Colors");
  const trimFasadeColorVariation = dataContainer?.findSelectedVariantByComponentNameAndCat("Fasad Type 3", cat);
    
  outDoorMeshes.forEach(mesh => {
    if(!mesh.material) {
      return;
    }

    if(!outsideColorVariation) {
      mesh.material = mesh.metadata?.colorMaterial;
    }

    const outsideColorMaterialMesh = outsideColorVariation?.mesh?.loadedMeshes?.[1];

    if(outsideColorMaterialMesh) {
      mesh.material = outsideColorMaterialMesh.material;
    }
  });

  inDoorMeshes.forEach(mesh => {
    if(!mesh.material) {
      return;
    }

    if(!insideColorVariation) {
        
      if(!outsideColorVariation) {
        mesh.material = mesh.metadata?.colorMaterial;
      }

      const outsideColorMaterialMesh = outsideColorVariation?.mesh?.loadedMeshes?.[1];

      if(outsideColorMaterialMesh) {
        mesh.material = outsideColorMaterialMesh.material;
      }
    }

    const insideColorMaterialMesh = insideColorVariation?.mesh?.loadedMeshes?.[1];

    if(insideColorMaterialMesh) {
      mesh.material = insideColorMaterialMesh.material;
    }
      
  });

  outWindowMeshes.forEach(mesh => {
    if(!mesh.material) {
      return;
    }

    if(!outsideWindowsColorVariation) {
      mesh.material = mesh.metadata?.colorMaterial;
    }

    const outsideColorMaterialMesh = outsideWindowsColorVariation?.mesh?.loadedMeshes?.[1];

    if(outsideColorMaterialMesh) {
      mesh.material = outsideColorMaterialMesh.material;
    }
  });

  inWindowMeshes.forEach(mesh => {
    if(!mesh.material) {
      return;
    }

    if(!insideWindowsColorVariation) {
        
      if(!outsideWindowsColorVariation) {
        mesh.material = mesh.metadata?.colorMaterial;
      }

      const outsideColorMaterialMesh = outsideWindowsColorVariation?.mesh?.loadedMeshes?.[1];

      if(outsideColorMaterialMesh) {
        mesh.material = outsideColorMaterialMesh.material;
      }
    }

    const insideColorMaterialMesh = insideWindowsColorVariation?.mesh?.loadedMeshes?.[1];

    if(insideColorMaterialMesh) {
      mesh.material = insideColorMaterialMesh.material;
    }
      
  });

  allFasadeMeshes.forEach(mesh => {
    if(!mesh.material) {
      return;
    }

    if(fasadeColorVariation && fasadeColorVariation?.original_key?.toLowerCase() !== "granpanel") {
      mesh.material = fasadeColorVariation?.mesh?.loadedMeshes?.[1]?.material;
    }
    if(fasadeGranPanelColorVariation && fasadeColorVariation?.original_key?.toLowerCase() === "granpanel") {
      mesh.material = fasadeGranPanelColorVariation?.mesh?.loadedMeshes?.[1]?.material;
    }
  });

  trimFasadeMeshes.forEach(mesh => {
    if(!mesh.material) {
      return;
    }
    if(trimFasadeColorVariation) {
      mesh.material = trimFasadeColorVariation?.mesh?.loadedMeshes?.[1]?.material;
    }
  });

};

export const saveInsideOutsideMeshes = (task: MeshAssetTask) => {
  if(!task.loadedMeshes?.length) {
    return;
  }

  for(let i = 0; i < task.loadedMeshes.length; i++) {
    const mesh = task.loadedMeshes[i] as Mesh;
    if(mesh.material) {
      mesh.metadata.colorMaterial = mesh.material;
      if(outsideDoorsMeshes.some((outsideMesh) => mesh.name.includes(outsideMesh))) {
        outDoorMeshes.push(mesh);
      }

      if(insideDoorsMeshes.some((insideMesh) => mesh.name.includes(insideMesh))) {
        inDoorMeshes.push(mesh);
      }

      if(outsideWindowMeshes.some((outsideMesh) => mesh.name.includes(outsideMesh))) {
        outWindowMeshes.push(mesh);
      }

      if(insideWindowMeshes.some((insideMesh) => mesh.name.includes(insideMesh))) {
        inWindowMeshes.push(mesh);
      }

      if(facadeMeshes.some((facade) => mesh.name.includes(facade))) {
        allFasadeMeshes.push(mesh);
      }

      if(trimFacade.some((trim) => mesh.name.includes(trim))){
        trimFasadeMeshes.push(mesh);
      }
        
    }
  }
};