export const handleKitchen = {
  framtid_a_1 : "Null_Kitchen_F_A",
  framtid_g_1 : "Null_Kitchen_F_G",
  hallbar_a: "Null_Kitchen_A",
  hallbar_a1: "Null_Kitchen_A1",
  hallbar_b: "Null_Kitchen_B",
  hallbar_b1: "Null_Kitchen_B1",
  hallbar_c: "Null_Kitchen_C1",
  hallbar_c1: "Null_Kitchen_C1",
  hallbar_d: "Null_Kitchen_D",
  hallbar_e: "Null_Kitchen_E",
  hallbar_f: "Null_Kitchen_F",
  hallbar_g: "Null_Kitchen_G",
  klassisk_a: "Null_Kitchen_A",
  klassisk_a1: "Null_Kitchen_A1",
  klassisk_b: "Null_Kitchen_B",
  klassisk_b1: "Null_Kitchen_B1",
  klassisk_c: "Null_Kitchen_C1",
  klassisk_c1: "Null_Kitchen_C1",
  klassisk_d: "Null_Kitchen_D",
  klassisk_e: "Null_Kitchen_E",
  klassisk_f: "Null_Kitchen_F",
  klassisk_g: "Null_Kitchen_G",
  modern_a: "Null_Kitchen_A",
  modern_a1: "Null_Kitchen_A1",
  modern_b: "Null_Kitchen_B",
  modern_b1: "Null_Kitchen_B1",
  modern_c: "Null_Kitchen_C1",
  modern_c1: "Null_Kitchen_C1",
  modern_d: "Null_Kitchen_D",
  modern_e: "Null_Kitchen_E",
  modern_f: "Null_Kitchen_F",
  modern_g: "Null_Kitchen_G",
};