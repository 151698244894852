import classnames from "classnames";
import React from "react";
import { HeaderProps } from "./Types";
import { Button } from "../button/Button";
import { useLocation, useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Logo } from "../logo/Logo";
import { Icon } from "../icon/Icon";

const menuPrimary = [
  {
    link: "/",
    name: "DÖRRAR",
  },
  {
    link: "/windows",
    name: "FÖNSTER",
  },
];
const menuSecondary = [
  {
    link: "/models/doors/single",
    name: "ENKELDÖRRAR",
  },
  {
    link: "/models/doors/double",
    name: "PARDÖRRAR",
  },
];

export const Header = ({
  secondary,
  className,
  toggleMenu,
  toogleModal,
  isMenuOpen = false,
  closeButton,
  isMenuVisible = true,
  menuClassName,
  dataContainer,
  onBackClick,
}: HeaderProps) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const menu = isMenuOpen ? menuSecondary : menuPrimary;
  const isFromMenu = searchParams.get("isFromMenu") === "true";
  const generateARLink = () => {
    if(!dataContainer){
      return;
    }
    
    return dataContainer?.generateShareableLink(undefined, true);
  };

  const redirectToAR = (e) => {
    e.preventDefault();
    window.location.href = generateARLink() || window.location.href;
    window.location.reload();
  };

  return (
    <div
      className={classnames(
        "Header p-6 bg-smoke sm:bg-inherit z-20 h-11",
        className
      )}
    >
      {secondary ? (
        <div className="relative secondary flex justify-between items-center">
          <div className="secondary-left flex sm:flex-row flex-row-reverse">
            <Button
              secondary
              textClassName="sm:ml-3.5 sm:flex hidden"
              icon="Prev"
              label="Tillbaka"
              onClick={() => onBackClick ? onBackClick() : navigate(-1)}
            />
          </div>
          <div className="secondary-right">
            <Button
              secondary
              icon="AR"
              label="Visa i AR"
              iconClassName="w-11 h-11 flex items-center justify-center bg-white z-10"
              className="flex-row-reverse sm:flex hidden"
              textClassName="mr-3 text-black sm:flex hidden"
              onClick={toogleModal}
            />
            <a
              href="#"
              onClick={redirectToAR}
              className="rounded-full flex-row-reverse w-11 h-11 flex items-center justify-center bg-white z-10 mr-3 text-primary sm:hidden"
            >
              <Icon name="AR" />
            </a>
          </div>
        </div>
      ) : (
        <>
          <div
            className={classnames("sm:flex hidden main items-center", {
              "justify-between": closeButton?.isShown,
            })}
          >
            {!closeButton?.isShown && (
              <div className="main-left mr-12">
                <Button
                  icon={isMenuOpen ? "HamburgerClose" : "Hamburger"}
                  onClick={toggleMenu}
                  className="flex"
                />
              </div>
            )}
            <div className="menu flex">
              <ul
                className={classnames("flex", {
                  invisible: !isMenuVisible,
                })}
              >
                {menu.map((menuItem, index) => {
                  return (
                    <li
                      key={`menu-item-${menuItem.name}-${index}`}
                      className={
                        menuClassName &&
                        "flex sm:justify-start justify-center sm:first:mb-0 first:mb-5"
                      }
                    >
                      <Button
                        to={`${menuItem.link}${
                          isFromMenu ? "?isFromMenu=true" : ""
                        }`}
                        label={menuItem.name}
                        className={classnames(
                          "pr-4 text-xs text-primary uppercase",

                          location.pathname === menuItem.link
                            ? "active opacity-100"
                            : "opacity-40",
                          index > 0 && "pl-4",
                          index === menu.length - 1 && "pr-0",
                          index < menu.length - 1 &&
                            "sm:border-r sm:border-primary",
                          menuClassName &&
                            "border-r-0 sm:px-4 pr-0 pl-0 justify-start"
                        )}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
            {closeButton?.isShown && (
              <Button icon="Close" onClick={closeButton.onClick} />
            )}
          </div>
          <div className="fixed sm:hidden bg-smoke sm:bg-transparent block top-0 left-0 w-full py-3 px-6 z-20 h-16">
            <div
              className={classnames(
                "Mobile sm:hidden main flex items-center relative",
                {
                  "justify-between": closeButton?.isShown,
                }
              )}
            >
              {!closeButton?.isShown && (
                <div className="main-left mr-12">
                  <Button
                    icon={isMenuOpen ? "HamburgerClose" : "Hamburger"}
                    onClick={toggleMenu}
                    className={classnames(
                      { "sm:pt-0 pt-10": menuClassName },
                      { "sm:pt-4 pt-4": isMenuOpen },
                      { "sm:pt-0 pt-2.5": !isMenuOpen },
                      "flex"
                    )}
                  />
                </div>
              )}
              <div className="menu flex">
                <Logo
                  to="/"
                  className="absolute top-0 left-1/2 -translate-x-1/2"
                />
              </div>
              {closeButton?.isShown && (
                <Button icon="Close" onClick={closeButton.onClick} />
              )}
            </div>
          </div>

          <div className="flex sm:hidden justify-center sm:pt-12 pt-24 sm:mb-0 mb-12">
            <ul
              className={classnames("flex", menuClassName, {
                invisible: !isMenuVisible,
              })}
            >
              {menu.map((menuItem, index) => {
                return (
                  <li
                    key={`menu-item-${menuItem.name}-${index}`}
                    className={
                      menuClassName &&
                      "flex sm:justify-start justify-center sm:first:mb-0 first:mb-5"
                    }
                  >
                    <Button
                      to={`${menuItem.link}${
                        isFromMenu ? "?isFromMenu=true" : ""
                      }`}
                      label={menuItem.name}
                      className={classnames(
                        "pr-4 text-xs text-primary uppercase",

                        location.pathname === menuItem.link
                          ? "active opacity-100"
                          : "opacity-40",
                        index > 0 && "pl-4",
                        index === menu.length - 1 && "pr-0",
                        index < menu.length - 1 && "border-r border-primary",
                        menuClassName &&
                          "border-none sm:px-4 pr-0 pl-0 justify-start"
                      )}
                      textClassName={classnames(
                        location.pathname !== menuItem.link && menuClassName
                          ? "sm:text-xs text-3xl pr-0 pl-0"
                          : ""
                      )}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};
