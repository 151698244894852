export const filterSubMenu = {
  framtid_a_1: ["Kok", "Sovrum", "Badrum", "Loft", "Sofa"],
  framtid_g_1: ["Kok", "Sovrum", "Badrum", "Loft", "Sofa"],
  hallbar_a: ["Kok", "Sovrum", "Badrum", "Loft", "Sofa"],
  hallbar_a1: ["Kok", "Sovrum", "Badrum", "Loft", "Sofa"],
  hallbar_b: ["Kok", "Badrum", "Loft", "Sofa"],
  hallbar_b1: ["Kok", "Badrum", "Loft", "Sofa"],
  hallbar_c: ["Kok", "Badrum", "Sofa"],
  hallbar_c1: ["Kok", "Badrum", "Sofa"],
  hallbar_d: ["Kok", "Badrum", "Loft", "Sofa"],
  hallbar_e: ["Badrum", "Sofa"],
  hallbar_f: ["Badrum", "Sofa"],
  hallbar_g: ["Kok", "Sovrum", "Badrum", "Loft", "Sofa"],
  modern_a: ["Kok", "Sovrum", "Badrum", "Loft", "Sofa"],
  modern_a1: ["Kok", "Sovrum", "Badrum", "Loft", "Sofa"],
  modern_b: ["Kok", "Badrum", "Loft", "Sofa"],
  modern_b1: ["Kok", "Badrum", "Loft", "Sofa"],
  modern_c: ["Kok", "Badrum", "Sofa"],
  modern_c1: ["Kok", "Badrum", "Sofa"],
  modern_d: ["Kok", "Badrum", "Loft", "Sofa"],
  modern_e: ["Kok", "Badrum", "Sofa"],
  modern_f: ["Kok", "Badrum", "Sofa"],
  modern_g: ["Kok", "Sovrum", "Badrum", "Loft", "Sofa"],
  klassisk_a: ["Kok", "Sovrum", "Badrum", "Loft", "Sofa"],
  klassisk_a1: ["Kok", "Sovrum", "Badrum", "Loft", "Sofa"],
  klassisk_b: ["Kok", "Badrum", "Loft", "Sofa"],
  klassisk_b1: ["Kok", "Badrum", "Loft", "Sofa"],
  klassisk_c: ["Kok", "Badrum", "Sofa"],
  klassisk_c1: ["Kok", "Badrum", "Sofa"],
  klassisk_d: ["Kok", "Badrum", "Loft", "Sofa"],
  klassisk_e: ["Kok", "Badrum", "Sofa"],
  klassisk_f: ["Kok", "Badrum", "Sofa"],
  klassisk_g: ["Kok", "Sovrum", "Badrum", "Loft", "Sofa"],
};