import React, { useContext, useEffect } from "react";
import { DataContainerContext } from "../common/context/DataContainerProvider";
import { Icon } from "../components/icon/Icon";
import { Logo } from "../components/logo/Logo";
import { Typography } from "../components/typography";

export const ConfirmationPage = () => {
  const { dataContainer } = useContext(DataContainerContext);

  useEffect(() => {
    if (dataContainer) {
      dataContainer.logEvent("page_entered", "Confirmation Page", {
        product: "",
      });
    }
  }, [dataContainer]);

  return (
    <div className="p-10 w-full h-full bg-smoke">
      <div className="flex justify-center items-center w-full h-full bg-white">
        <div className="flex flex-col justify-center items-center text-center">
          <Typography
            tag="h1"
            className="text-primary font-normal leading-5 mb-8"
          >
            {dataContainer?.sectionKey(
              "Translate",
              "confirmation_page_title"
            )}
          </Typography>
          <Typography tag="p" className="mb-24 text-black px-8">
            {dataContainer?.sectionKey(
              "Translate",
              "confirmation_page_text"
            )}
          </Typography>
          <a
            href={dataContainer?.sectionKey(
              "Translate",
              "confirmation_page_link"
            )}
            className="flex text-primary font-normal text-xs leading-4"
          >
            {dataContainer?.sectionKey("Translate", "confirmation_page_btn")}
            <Icon name="RightGreenArrow" className="ml-4" />
          </a>
        </div>
        <Logo to="/" className="absolute bottom-16 left-1/2 -translate-x-1/2" />
      </div>
    </div>
  );
};
