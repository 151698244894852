import React from "react";
import { IconProps } from "../Types";

const Heating_electricity = ({ className }: IconProps) => {
  return (
    <svg className={className} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M33.5 13C34.3284 13 35 13.6716 35 14.5C35 15.3284 34.3284 16 33.5 16L7.5 16C6.67157 16 6 15.3284 6 14.5C6 13.6716 6.67157 13 7.5 13L33.5 13Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7.5 15L33.5 15C33.7761 15 34 14.7761 34 14.5C34 14.2239 33.7761 14 33.5 14L7.5 14C7.22386 14 7 14.2239 7 14.5C7 14.7761 7.22386 15 7.5 15ZM35 14.5C35 13.6716 34.3284 13 33.5 13L7.5 13C6.67157 13 6 13.6716 6 14.5C6 15.3284 6.67157 16 7.5 16L33.5 16C34.3284 16 35 15.3284 35 14.5Z" fill="black"/>
      <path d="M6.5 12C7.32843 12 8 12.6716 8 13.5L8 15.5C8 16.3284 7.32843 17 6.5 17C5.67157 17 5 16.3284 5 15.5L5 13.5C5 12.6716 5.67157 12 6.5 12Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M7 15.5L7 13.5C7 13.2239 6.77614 13 6.5 13C6.22386 13 6 13.2239 6 13.5L6 15.5C6 15.7761 6.22386 16 6.5 16C6.77614 16 7 15.7761 7 15.5ZM8 13.5C8 12.6716 7.32843 12 6.5 12C5.67157 12 5 12.6716 5 13.5L5 15.5C5 16.3284 5.67157 17 6.5 17C7.32843 17 8 16.3284 8 15.5L8 13.5Z" fill="black"/>
      <path d="M33.5 25C34.3284 25 35 25.6716 35 26.5C35 27.3284 34.3284 28 33.5 28L10.5 28C9.67157 28 9 27.3284 9 26.5C9 25.6716 9.67157 25 10.5 25L33.5 25Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.5 27L33.5 27C33.7761 27 34 26.7761 34 26.5C34 26.2239 33.7761 26 33.5 26L10.5 26C10.2239 26 10 26.2239 10 26.5C10 26.7761 10.2239 27 10.5 27ZM35 26.5C35 25.6716 34.3284 25 33.5 25L10.5 25C9.67157 25 9 25.6716 9 26.5C9 27.3284 9.67157 28 10.5 28L33.5 28C34.3284 28 35 27.3284 35 26.5Z" fill="black"/>
      <path d="M9 11C9 9.89543 9.89543 9 11 9C12.1046 9 13 9.89543 13 11V29C13 30.1046 12.1046 31 11 31C9.89543 31 9 30.1046 9 29V11Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 29V11C12 10.4477 11.5523 10 11 10C10.4477 10 10 10.4477 10 11V29C10 29.5523 10.4477 30 11 30C11.5523 30 12 29.5523 12 29ZM11 9C9.89543 9 9 9.89543 9 11V29C9 30.1046 9.89543 31 11 31C12.1046 31 13 30.1046 13 29V11C13 9.89543 12.1046 9 11 9Z" fill="black"/>
      <path d="M14 11C14 9.89543 14.8954 9 16 9C17.1046 9 18 9.89543 18 11V29C18 30.1046 17.1046 31 16 31C14.8954 31 14 30.1046 14 29V11Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M17 29V11C17 10.4477 16.5523 10 16 10C15.4477 10 15 10.4477 15 11V29C15 29.5523 15.4477 30 16 30C16.5523 30 17 29.5523 17 29ZM16 9C14.8954 9 14 9.89543 14 11V29C14 30.1046 14.8954 31 16 31C17.1046 31 18 30.1046 18 29V11C18 9.89543 17.1046 9 16 9Z" fill="black"/>
      <path d="M19 11C19 9.89543 19.8954 9 21 9C22.1046 9 23 9.89543 23 11V29C23 30.1046 22.1046 31 21 31C19.8954 31 19 30.1046 19 29V11Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M22 29V11C22 10.4477 21.5523 10 21 10C20.4477 10 20 10.4477 20 11V29C20 29.5523 20.4477 30 21 30C21.5523 30 22 29.5523 22 29ZM21 9C19.8954 9 19 9.89543 19 11V29C19 30.1046 19.8954 31 21 31C22.1046 31 23 30.1046 23 29V11C23 9.89543 22.1046 9 21 9Z" fill="black"/>
      <path d="M24 11C24 9.89543 24.8954 9 26 9C27.1046 9 28 9.89543 28 11V29C28 30.1046 27.1046 31 26 31C24.8954 31 24 30.1046 24 29V11Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M27 29V11C27 10.4477 26.5523 10 26 10C25.4477 10 25 10.4477 25 11V29C25 29.5523 25.4477 30 26 30C26.5523 30 27 29.5523 27 29ZM26 9C24.8954 9 24 9.89543 24 11V29C24 30.1046 24.8954 31 26 31C27.1046 31 28 30.1046 28 29V11C28 9.89543 27.1046 9 26 9Z" fill="black"/>
      <path d="M29 11C29 9.89543 29.8954 9 31 9C32.1046 9 33 9.89543 33 11V29C33 30.1046 32.1046 31 31 31C29.8954 31 29 30.1046 29 29V11Z" fill="white"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M32 29V11C32 10.4477 31.5523 10 31 10C30.4477 10 30 10.4477 30 11V29C30 29.5523 30.4477 30 31 30C31.5523 30 32 29.5523 32 29ZM31 9C29.8954 9 29 9.89543 29 11V29C29 30.1046 29.8954 31 31 31C32.1046 31 33 30.1046 33 29V11C33 9.89543 32.1046 9 31 9Z" fill="black"/>
    </svg>
  );
};

export default Heating_electricity;
