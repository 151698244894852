import React from "react";
import { IconProps } from "../Types";

const Hamburger = ({ className }: IconProps) => (
  <svg
    className={className}
    width="22"
    height="5"
    viewBox="0 0 22 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="22" height="4.5" fill="#658481" />
  </svg>
);

export default Hamburger;
