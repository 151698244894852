export const handleWallsInBathroom = (scene, variant) => {
  if (!scene) return;

  const waterHeaterWall = scene.getMeshesById("Walls_Bath_Toilet");
  if (variant.original_key.includes("vagghangd_toalettstol")) {
    if (waterHeaterWall) waterHeaterWall.map((item) => item.isVisible = true);
  }else{
    if (waterHeaterWall) waterHeaterWall.map((item) => item.isVisible = false);
  }
};
