import React from "react";
import { Typography } from "../typography";
import { LoadingComponentProps } from "./type";

const Loading = ({ loadingText }: LoadingComponentProps) => {
  return (
    <div>
      <Typography
        tag="h1"
        className={"text-[#006564] mt-5 sm:text-6xl text-5xl"}
      >
        {loadingText}
      </Typography>
      <div className="snippet mt-7 flex justify-center">
        <div className="stage">
          <div className="dot-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
