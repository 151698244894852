import { CubeTexture } from "@babylonjs/core";
import { AccordionEnum, DefaultCameraName } from "src/services/enums";
import { handleGeneralCamera, handleGeneralMobileCamera } from "../HideMeshes";
import { firstBathroomCamera, livingRoomCamera, secondBathroomCamera } from "./Animation";
import { handleInteriorDoors } from "./HandleInteriorDoors";
import { handleLights } from "./HandleLights";

export const changeCameraofMesh = (state, scene, selectionAccordion, isMobile, dispatch, singleId) => {
  const doorBath1 = scene.getMeshByName("Doors_Bath_01");
  const doorBath2 = scene.getMeshByName("Doors_Bath_02");
  const doorBath3 = scene.getMeshByName("Doors_Bath_03");
  const doorRoom1 = scene.getMeshByName("Doors_Room_01");
  const doorRoom2 = scene.getMeshByName("Doors_Room_02");
  const doorRoom3 = scene.getMeshByName("Doors_Room_03");
  const door2Room1 = scene.getMeshByName("Doors_Room2_01");
  const door2Room2 = scene.getMeshByName("Doors_Room2_02");
  const door2Room3 = scene.getMeshByName("Doors_Room2_03");
  const door2Room4 = scene.getMeshByName("Doors_Room2_04");
  const door2Room4Glass = scene.getMeshByName("Doors_Room2_04_Glass");
  const room2Handle = scene.getMeshByName("Doors_Handles_Room2");
  const roomHandle = scene.getMeshByName("Doors_Handles_Room");
  const bathHandle = scene.getMeshByName("Doors_Handles_Bath");
  const camera = state.camera;
  const pointView = state.pointView;
  const cameraBathrom1 = scene?.getCameraByName(isMobile ? "01_Cam_Mobile_Bathroom_01" : "01_Cam_Desktop_Bathroom_01");
  const cameraBathrom2 = scene?.getCameraByName(isMobile ? "01_Cam_Mobile_Bathroom_02" : "01_Cam_Desktop_Bathroom_02");

  if(pointView?.includes(AccordionEnum.Interior)){
    if(selectionAccordion && selectionAccordion.includes(AccordionEnum.Kitchen)){
      const cameraKitchen =  scene?.getCameraByName(isMobile ? "02_Cam_Mobile_Kitchen" : "02_Cam_Desktop_Kitchen");
      scene.activeCamera = cameraKitchen;
      scene.lights.forEach(m => {
        if(!m.name.includes("DirectionalLight")){
          m.setEnabled(false);
        }
        if(m.name.includes("02")){
          m.setEnabled(true);
        }
      });

      if(doorBath1) doorBath1.isVisible = true;
      if(doorBath2) doorBath2.isVisible = true;
      if(doorBath3) doorBath3.isVisible = true;

      if(roomHandle) roomHandle.isVisible = false;

      if(doorRoom1) doorRoom1.isVisible = false;
      if(doorRoom2) doorRoom2.isVisible = false;
      if(doorRoom3) doorRoom3.isVisible = false;

      return camera;
    }
    if(selectionAccordion && selectionAccordion.includes(AccordionEnum.Furniture)){
      scene.lights.forEach(m => {
        if(!m.name.includes("DirectionalLight")){
          m.setEnabled(false);
        }
        if(m.name.includes("05")){
          m.setEnabled(true);
        }
      });

      const cameraFurniture = scene?.getCameraByName(isMobile ? "05_Cam_Mobile_Loft" : "05_Cam_Desktop_Loft");
      scene.activeCamera = cameraFurniture;
      return camera;
    }
    if(selectionAccordion && selectionAccordion.includes(AccordionEnum.Furniture_Types_2)){
      const cameraFurniture2 = scene?.getCameraByName(isMobile ? "06_Cam_Mobile_Bedroom" : "06_Cam_Desktop_Bedroom");
      scene.activeCamera = cameraFurniture2;
      scene.lights.forEach(m => {
        if(!m.name.includes("DirectionalLight")){
          m.setEnabled(false);
        }
        if(m.name.includes("06")){
          m.setEnabled(true);
        }
      });

      return camera;
    }
    if(livingRoomCamera && livingRoomCamera.includes(selectionAccordion)){
      const cameraLivingRoom = scene?.getCameraByName(isMobile ? "04_Cam_Mobile_LivingRoom" : "04_Cam_Desktop_Livingroom");
      if(cameraLivingRoom) {
        scene.activeCamera = cameraLivingRoom;
        scene.lights.forEach(m => {
          if(!m.name.includes("DirectionalLight")){
            m.setEnabled(false);
          }
          if(m.name.includes("04")){
            m.setEnabled(true);
          }
        });
        return camera;
      }
    }
    if(firstBathroomCamera && firstBathroomCamera.includes(selectionAccordion)){
      scene.activeCamera = cameraBathrom1;
      if(singleId.includes("hallbar_b1") && (selectionAccordion.includes("Bathroom Type 5") || selectionAccordion.includes("Bathroom Type 9") || selectionAccordion.includes("Bathroom Type 7"))){
        scene.activeCamera = cameraBathrom2;
      }
      if((singleId.includes("klassisk_b1") || singleId.includes("klassisk_f")) && selectionAccordion.includes("Bathroom Type 3")){
        scene.activeCamera = cameraBathrom2;
      }
      if(doorBath1) doorBath1.isVisible = false;
      if(doorBath2) doorBath2.isVisible = false;
      if(doorBath3) doorBath3.isVisible = false;
      if(bathHandle) bathHandle.isVisible = false;

      scene.lights.forEach(m => {
        if(!m.name.includes("DirectionalLight")){
          m.setEnabled(false);
        }
        if(m.name.includes("01")){
          m.setEnabled(true);
        }
      });

      return camera;
    }
    if(secondBathroomCamera && secondBathroomCamera.includes(selectionAccordion)){
      scene.activeCamera = cameraBathrom2;
      if(singleId.includes("klassisk_b1") && selectionAccordion.includes("Bathroom Type 6")){
        scene.activeCamera = cameraBathrom1;
      }
      if(doorBath1) doorBath1.isVisible = true;
      if(doorBath2) doorBath2.isVisible = true;
      if(doorBath3) doorBath3.isVisible = true;
      if(bathHandle) bathHandle.isVisible = false;
      handleInteriorDoors(scene);

      if(singleId.includes("hallbar_b1") && (selectionAccordion.includes("Bathroom Type 4") || selectionAccordion.includes("Bathroom Type 8"))){
        scene.activeCamera = cameraBathrom1;
      }
      scene.lights.forEach(m => {
        if(!m.name.includes("DirectionalLight")){
          m.setEnabled(false);
        }
        if(m.name.includes("01")){
          m.setEnabled(true);
        }
      });
   
      return camera;
    }

    if(selectionAccordion && selectionAccordion?.includes("Hallway Floors")){
      let cameraBathrom2 = scene?.getCameraByName(isMobile ? "03_Cam_Mobile_Hall" : "03_Cam_Desktop_Hall");
      if(!cameraBathrom2) {
        cameraBathrom2 = scene?.getCameraByName(isMobile ? "04_Cam_Mobile_Hall" : "04_Cam_Desktop_Hall");
      } 
      scene.activeCamera = cameraBathrom2;
      if(doorBath1?.parent?.parent?.isVisible) doorBath1.isVisible = true;
      if(doorBath2?.parent?.parent?.isVisible) doorBath2.isVisible = true;
      if(doorBath3?.parent?.parent?.isVisible) doorBath3.isVisible = true;
      if(door2Room4) door2Room4.isVisible = false;
      if(door2Room3) door2Room3.isVisible = false;
      if(door2Room2) door2Room2.isVisible = false;
      if(door2Room1) door2Room1.isVisible = false;
      if(room2Handle) room2Handle.isVisible = false;
      if(door2Room4Glass) door2Room4Glass.isVisible = false;
      if(bathHandle) bathHandle.isVisible = true;

      scene.lights.forEach(m => {
        if(!m.name.includes("DirectionalLight")){
          m.setEnabled(false);
        }
        if(m.name.includes("03")){
          m.setEnabled(true);
        }
      });

      return camera;
    }

    const cameraInterior = scene?.getCameraByName(isMobile ? handleGeneralMobileCamera[singleId || "04_Cam_Mobile_General"] : handleGeneralCamera[singleId || "04_Cam_Desktop_General"]);
    const hdrTexture = new CubeTexture(`${process.env.REACT_APP_PUBLIC_URL || "/" }textures/woodworks_interior.env`, scene);
    dispatch({ type: "SET_SKYBOX", payload: scene.createDefaultSkybox(hdrTexture, true, 1000) });

    scene.activeCamera = scene.activeCamera.name === DefaultCameraName || selectionAccordion ? cameraInterior : scene.activeCamera;
    
    const cameraNumber = scene.activeCamera?.name?.split("_")?.[0];
    scene.lights.forEach(m => {
      if(!m.name.includes("DirectionalLight")){
        m.setEnabled(false);
      }
      if(m.name.includes(cameraNumber || "04")){
        m.setEnabled(true);
      }
    });

    cameraInterior.attachControl(state.canvas, true);
    const rotation = camera.rotation.x;
    scene.onBeforeRenderObservable.add(() => {
      cameraInterior.rotation.x = rotation;
      if(cameraInterior.rotation.y > 3.40){
        cameraInterior.rotation.y = 3.40;
      }
      if(cameraInterior.rotation.y < 2.8){
        cameraInterior.rotation.y = 2.8;
      }
    });


    if(scene.activeCamera?.name?.includes("_Bathroom_01") || scene.activeCamera?.name?.includes("Kitchen")) {
      return camera;
    }

    doorBath1 && (doorBath1.isVisible = true);
    doorBath2 && (doorBath2.isVisible = true);
    doorBath3 && (doorBath3.isVisible = true);

    if(roomHandle) roomHandle.isVisible = true;

    if(doorRoom1) doorRoom1.isVisible = true;
    if(doorRoom2) doorRoom2.isVisible = true;
    if(doorRoom3) doorRoom3.isVisible = true;
    
    return camera;
  }

  if (pointView?.includes(AccordionEnum.Exterior)) {
    scene.activeCamera = scene?.getCameraByName("OrbitCamera");
    camera.wheelPrecision = 100;
    camera.minZ = 0.5;
    camera.speed = 1;
    camera.lowerRadiusLimit = 11.0;
    camera.upperRadiusLimit = 30.0;
    camera.upperBetaLimit = 1.4;
    camera.lowerBetaLimit = 1.4;
    camera.radius = 20;
    camera.alpha = 2.5;
    camera.beta = 1.1;
    state.scene.lights.length > 1 && handleLights(state.scene);

    if(scene.textures.filter(texture => texture.name.includes("/textures/woodworks.env")).length === 0){
      const hdrTexture = new CubeTexture(`${process.env.REACT_APP_PUBLIC_URL || "/" }textures/woodworks.env`, scene);
      dispatch({ type: "SET_SKYBOX", payload: scene.createDefaultSkybox(hdrTexture, true, 100) });
    }
    scene.textures.filter(texture => texture.name.includes("/textures/woodworks.env"))[0].level = -2;

    return camera;
  }
};