import React from "react";
import { IconProps } from "../Types";

const Doors = ({ className }: IconProps) => (
  <svg
    width="22"
    className={className}
    height="36"
    viewBox="0 0 22 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.97917 3.25161C2.97917 2.99507 3.18437 2.7871 3.4375 2.7871H18.5625C18.8156 2.7871 19.0208 2.99507 19.0208 3.25161V13.471C19.0208 13.7275 18.8156 13.9355 18.5625 13.9355H3.4375C3.18437 13.9355 2.97917 13.7275 2.97917 13.471V3.25161ZM3.89583 13.0065V3.71613H18.1042V13.0065H3.89583Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.4375 22.2968C3.18437 22.2968 2.97917 22.5047 2.97917 22.7613V32.9806C2.97917 33.2372 3.18437 33.4452 3.4375 33.4452H18.5625C18.8156 33.4452 19.0208 33.2372 19.0208 32.9806V22.7613C19.0208 22.5047 18.8156 22.2968 18.5625 22.2968H3.4375ZM3.89583 23.2258V32.5161H18.1042V23.2258H3.89583Z"
      fill="#333333"
    />
    <path
      d="M19.7083 16.0258C19.7083 15.7693 19.5031 15.5613 19.25 15.5613C18.9969 15.5613 18.7917 15.7693 18.7917 16.0258V20.2065C18.7917 20.463 18.9969 20.671 19.25 20.671C19.5031 20.671 19.7083 20.463 19.7083 20.2065V16.0258Z"
      fill="#333333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0.464516C0 0.207971 0.205203 0 0.458333 0H21.5417C21.7948 0 22 0.207971 22 0.464516V35.5355C22 35.792 21.7948 36 21.5417 36H0.458333C0.205202 36 0 35.792 0 35.5355V0.464516ZM0.916667 35.071V0.929032H21.0833V35.071H0.916667Z"
      fill="#333333"
    />
  </svg>
);

export default Doors;
