import React from "react";
import { IconProps } from "../Types";

const Sofa = ({ className, fill }: IconProps) => (
  <svg
    className={className}
    width="16"
    height="13"
    viewBox="0 0 16 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.5756 1.95444L11.5 2.25669H12.75H14L13.9244 1.95444C13.675 0.956665 13.7785 1.00028 12.75 1.00028C11.7215 1.00028 11.825 0.956665 11.5756 1.95444Z"
      fill={fill}
    />
    <path
      d="M12.75 2.25669H14L13.9244 1.95444C13.675 0.956665 13.7785 1.00028 12.75 1.00028C11.7215 1.00028 11.825 0.956665 11.5756 1.95444L11.5 2.25669H12.75ZM12.75 2.25669V4.37608"
      stroke={fill}
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 4.75684C2.67157 4.75684 2 5.42841 2 6.25684V6.75684C2.82843 6.75684 3.5 7.42841 3.5 8.25684V8.75684H7.5V4.75684H3.5ZM8 3.75684H12C13.3807 3.75684 14.5 4.87612 14.5 6.25684V6.84219C15.0826 7.04811 15.5 7.60373 15.5 8.25684V8.75684V9.25684V11.2568H15V11.7568C15 12.033 14.7761 12.2568 14.5 12.2568C14.2239 12.2568 14 12.033 14 11.7568V11.2568H1.5V11.7568C1.5 12.033 1.27614 12.2568 1 12.2568C0.723858 12.2568 0.5 12.033 0.5 11.7568V11.2568H0V9.25684V8.75684V8.25684C0 7.60373 0.417404 7.04811 1 6.84219V6.25684C1 4.87612 2.11929 3.75684 3.5 3.75684H8ZM8.5 4.75684V8.75684H12V8.25684C12 7.42841 12.6716 6.75684 13.5 6.75684V6.25684C13.5 5.42841 12.8284 4.75684 12 4.75684H8.5ZM8 9.75684H3H1V10.2568H14.5V9.75684H12.5H8ZM2.5 8.25684V8.75684H1V8.25684C1 7.98069 1.22386 7.75684 1.5 7.75684H2C2.27614 7.75684 2.5 7.98069 2.5 8.25684ZM14.5 8.25684V8.75684H13V8.25684C13 7.98069 13.2239 7.75684 13.5 7.75684H14C14.2761 7.75684 14.5 7.98069 14.5 8.25684Z"
      fill={fill}
    />
  </svg>
);

export default Sofa;
