/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { createContext, useEffect, useState } from "react";
import DataContainer from "../../services/DataContainer";
import lz from "lz-string";
import { useLocation } from "react-router";

const initialState:{
  dataContainer: any,
  dataReady: boolean;
} = {
  dataContainer: null,
  dataReady: false,
};

export const DataContainerContext = createContext(initialState);

export const DataContainerProvider = ({
  children,
}: React.PropsWithChildren<unknown>) => {
  const [dataContainer, setDataContainer] = useState(null);
  const [dataReady, setDataReady] = useState(false);
  const location = useLocation();

  useEffect(() => {
    DataContainer.loadData(() => {
      const urlSearchParams = new URLSearchParams(location.search);
      const params = Object.fromEntries(urlSearchParams.entries());
      if (params.s) {
        const decompressed = lz.decompressFromBase64(params.s.replaceAll(" ", "+").replaceAll(/-/g, "+").replaceAll(/_/g, "/"));
        if(decompressed){
          DataContainer.loadSelectionFromArray(JSON.parse(decompressed));
        }
      }
      setDataContainer(DataContainer.instance());
      setDataReady(true);
    });
  }, []);

  return (
    <DataContainerContext.Provider value={{ dataContainer, dataReady }}>
      {children}
    </DataContainerContext.Provider>
  );
};
