import React from "react";
import { IconProps } from "../Types";

const Floors = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33 10H7V31H33V10ZM7 9C6.44772 9 6 9.44772 6 10V31C6 31.5523 6.44771 32 7 32H33C33.5523 32 34 31.5523 34 31V10C34 9.44772 33.5523 9 33 9H7Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 10C6 9.44772 6.44772 9 7 9H20V21H6V10ZM7 10V20H19V10H7Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.17676 20.1191L19.1768 9.11914L19.8227 9.88253L6.8227 20.8825L6.17676 20.1191Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.18457 15.6113L14.1846 9.11133L14.8152 9.88744L6.81516 16.3874L6.18457 15.6113Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6729 20.1211L19.1729 13.6211L19.8278 14.3768L12.3278 20.8768L11.6729 20.1211Z"
        fill="black"
      />
      <path d="M19.5 31.5V20.5H33.5" stroke="black" />
      <path d="M33.5 20.5L19.5 31.5" stroke="black" />
      <path d="M33.5 25L25.5 31.5" stroke="black" />
      <path d="M27 20.5L19.5 26.5" stroke="black" />
    </svg>
  );
};

export default Floors;
