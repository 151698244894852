import { ShadowGenerator  } from "@babylonjs/core/Lights/Shadows/shadowGenerator";

export const handleLights = (scene) => {
  const cameraNumber = scene.activeCamera?.name?.split("_")?.[0];
  scene.lights.forEach(m => {
    if(!m.name.includes("DirectionalLight")){
      m.setEnabled(false);
    }
    if(m.name.includes(cameraNumber || "04")){
      m.setEnabled(true);
    }
    const lightGenerator = new ShadowGenerator(1024, m);
    scene.meshes.forEach((mesh) => {
      if (mesh.name !== "hdrSkyBox" && mesh.name !== "house_exterior_terrain") {
        lightGenerator?.addShadowCaster(mesh);
        mesh.receiveShadows = true;
      }
    });
    lightGenerator.filter = ShadowGenerator.FILTER_PCF;
    lightGenerator.filteringQuality = ShadowGenerator.QUALITY_HIGH;
    lightGenerator.normalBias = 0;
    lightGenerator.darkness = 0;
    lightGenerator.bias = 0.0100;
  });
  const terrainMesh = scene.getMeshByName("house_exterior_terrain");
  terrainMesh.receiveShadows = true;
};