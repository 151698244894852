export const livingRoomCamera = [
  "Furniture Types 3",
  "Furniture Types 4",
  "Heating_electricity",
  "Heating",
];

export const firstBathroomCamera = [
  "Bathroom Type 1",
  "Bathroom Type 2",
  "Bathroom Type 3",
  "Bathroom Type 5",
  "Bathroom Type 7",
  "Bathroom Type 9",
  "Bathroom",
];

export const secondBathroomCamera = [
  "Bathroom Type 4",
  "Bathroom Type 6",
  "Bathroom Type 8",
];
