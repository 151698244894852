import React from "react";

import { IconProps } from "../Types";

const Sovrum = ({ className, fill="#fff" }: IconProps) => ( 
  <svg className={className} width="21" height="15" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill={fill} fillRule="evenodd" clipRule="evenodd" d="M4.11765 1.17647C3.4679 1.17647 2.94118 1.70319 2.94118 2.35294C2.94118 2.55263 2.91577 2.7502 2.86646 2.94118H7.05882V1.17647H4.11765ZM7.64706 0H4.11765C2.81815 0 1.76471 1.05345 1.76471 2.35294C1.76471 2.60749 1.68214 2.85518 1.52941 3.05882L1 3.76471C0.350889 4.63019 0 5.68285 0 6.76471V8.82353H0.588235V9.41177C0.588235 9.73664 0.851597 10 1.17647 10C1.50134 10 1.76471 9.73664 1.76471 9.41177V8.82353H13.5294V9.41177C13.5294 9.73664 13.7928 10 14.1176 10C14.4425 10 14.7059 9.73664 14.7059 9.41177V8.82353H15.2941V6.76471C15.2941 5.68285 14.9432 4.63019 14.2941 3.76471L13.7647 3.05882C13.612 2.85518 13.5294 2.60749 13.5294 2.35294C13.5294 1.05345 12.476 0 11.1765 0H7.64706ZM8.23529 1.17647V2.94118H12.4277C12.3783 2.7502 12.3529 2.55263 12.3529 2.35294C12.3529 1.70319 11.8262 1.17647 11.1765 1.17647H8.23529ZM7.64706 4.11765H2.35294C2.30637 4.11765 2.26106 4.11224 2.21761 4.10201L1.94118 4.47059C1.62416 4.89328 1.40016 5.37436 1.27967 5.88235H14.0144C13.894 5.37436 13.67 4.89328 13.3529 4.47059L13.0765 4.10201C13.0331 4.11223 12.9877 4.11765 12.9412 4.11765H7.64706ZM14.1176 7.64706V7.05882H1.17647V7.64706H14.1176Z" />
  </svg>
  
);

export default Sovrum;
