import React from "react";

import { IconProps } from "../Types";

const ZoomArrow = ({ className }: IconProps) => (
  <svg
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 700 700"
    xmlSpace="preserve"
  >
    <path
      className="st0"
      fill="#006564"
      d="M374,405.1l-19.2,15.8v-72.5c0,0,0,0,0,0v-69.3l19.2,15.8c1.9,1.6,5.1,1.2,6.7-0.7c1.7-2,1.3-5.1-0.7-6.7
	l-27-22.2c-2-1.4-4.1-1.3-6.1,0h0l-27,22.2c-2.1,1.8-2.3,4.8-0.7,6.7c1.6,1.9,4.8,2.3,6.7,0.7l19.2-15.8v72.5c0,0,0,0,0,0v69.3
	L326,405.1c-1.9-1.6-5.1-1.2-6.7,0.7c-1.7,2-1.3,5.1,0.7,6.7l27,22.2c2,1.4,4.1,1.3,6.1,0h0l27-22.2c2.1-1.8,2.3-4.8,0.7-6.7
	C379.1,403.8,375.9,403.5,374,405.1z"
    />
  </svg>
);

export default ZoomArrow;
