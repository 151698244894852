import React, { useEffect, useRef, useState } from "react";
import { Button } from "../button/Button";
import { Typography } from "../typography/Typography";
import { MenuComponentProps } from "./type";
import { Tabs } from "../input/Tabs";
import classnames from "classnames";
import { Accordion } from "../accordion";
import { useNavigate } from "react-router";

function Menu({
  option,
  name,
  children,
  onClose,
  className,
  scrollIndex,
  dataContainer,
  onTabChange,
  catName,
  pointView
}: MenuComponentProps) {
  const ref = useRef<HTMLInputElement | null>(null);
  const accordionRef = useRef<HTMLInputElement | null>(null);
  const [selectedVariations, setSelectedVariations] = useState([]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const navigate = useNavigate();

  const getSelectedVariations = () => {
    setSelectedVariations(dataContainer.getSelectedVariations());
  };

  dataContainer?.registerSelectionHook(() => {
    setShouldRefresh(!shouldRefresh);
  });

  useEffect(() => {
    if (scrollIndex) {
      const aRef = accordionRef.current;
      const index = scrollIndex.toString();
      const accordionElements = Array.from(aRef?.children || []);

      if (index && aRef && ref.current) {
        const top = accordionElements
          ?.find((accordion) => accordion.id === index)
          ?.getBoundingClientRect()?.top;
        ref.current.scrollTop = top ? top - 149 : 0;
      }
    }
  }, [scrollIndex]);

  useEffect(() => {
    if (dataContainer) {
      getSelectedVariations();
    }
  }, [dataContainer, shouldRefresh]);

  return (
    <div
      ref={ref}
      className={classnames(
        className,
        "bg-white sm:h-full h-[60vh] sm:w-[35%] fixed right-0 sm:top-0 bottom-0 overflow-y-auto w-full sm:px-10 pb-9 z-10 transition-all duration-500"
      )}
    >
      <div className="sticky sm:px-0 px-7 top-0 z-50 bg-white py-9 after:absolute after:bottom-0 after:block after:w-full after:shadow-sm after:border-b after:border-[rgba(222,222,222,0.1)]">
        <div className="flex flex-row w-full relative">
          <Tabs data={option} onTabClick={(e, name) => onTabChange(e, name)} pointView={pointView}/>
          <Button
            icon="Close"
            className="absolute right-0 z-10 w-7 h-7"
            onClick={() => onClose()}
          />
        </div>
        <div className="w-full mt-8">
          <Typography
            tag="h4"
            className="text-black text-xs uppercase sm:leading-4 leading-6 sm:w-full w-1/2 font-normal"
          >
            {catName}
          </Typography>
        </div>
        <div className="w-full mt-1">
          <Typography
            tag="h4"
            className="text-primary capitalize text-xs sm:leading-4 leading-6 sm:w-full w-1/2 font-normal"
          >
            {name}
          </Typography>
        </div>
      </div>
      <div
        ref={accordionRef}
        className={classnames("w-full overflow-hidden grid grid-cols-1")}
      >
        {children}
      </div>
      <div className="w-full sm:px-0 px-7 pt-16 pb-2.5">
        <Accordion
          className="bg-smoke pt-5 px-7 pb-4 border-none"
          name={catName || "Attefallshus"}
          subname={name}
          quantity={
            selectedVariations.length > 0 ? selectedVariations.length : ""
          }
          icon="House"
          onChange={() => ({})}
        >
          {selectedVariations.map((product: { key: string; id: number }) => (
            <div
              key={product.id}
              className="flex items-center justify-between pb-3.5 mb-6 border-gray-darker border-b-[0.5px]"
            >
              <Typography tag="h3" className="font-normal">
                {product.key}
              </Typography>
            </div>
          ))}
        </Accordion>
      </div>
      <div className="sm:px-0 px-7">
        <Button
          onClick={() => navigate("/summary", { state: location.href.split("?")?.[0] })}
          label={dataContainer?.sectionKey(
            "Translate",
            "configuration_btn_label"
          )}
          className="flex flex-row-reverse items-center px-5 py-7 w-full justify-center text-white bg-primary"
          iconClassName="sm:flex hidden ml-4"
          icon="Right"
        />
      </div>
    </div>
  );
}

export { Menu };
