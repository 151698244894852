import React from "react";
import { Button } from "../button/Button";
import { BottomMenuProps } from "./Types";
import classnames from "classnames";

export const BottomMenu = ({
  items,
  className,
  setAccordionSelected,
}: BottomMenuProps) => {
  return (
    <div className={classnames("Bottom-menu flex items-center", className)}>
      {items?.map((item, index) => (
        <Button
          key={`button-${item.id}-${index}`}
          icon={item.icon}
          onClick={() => {
            item.onClick && item.onClick(item.id);
            setAccordionSelected(item.icon);
          }}
          className={classnames(
            "hidden xl-block",
            "xl-block",
            index > 0 && "pl-6",
            { "md:block": index < 1 },
            { "sm:block": index < 5 },
            { "sm:block": index === items.length - 1 }
          )}
          iconClassName="w-10 h-10 flex items-center justify-center"
          to={item.link}
          active={item.active === "menu-open"}
        />
      ))}
    </div>
  );
};
