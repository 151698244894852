import React from "react";
import { IconProps } from "../Types";

const House = ({ className }: IconProps) => (
  <svg
    className={className}
    width="29"
    height="21"
    viewBox="0 0 29 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0115 1.0255L24.0115 3.29823V17.5H23.0115V7.5C23.0115 6.94772 22.5638 6.5 22.0115 6.5H18.0115C17.4592 6.5 17.0115 6.94771 17.0115 7.5V17.5H16.0115V7.5C16.0115 6.94772 15.5638 6.5 15.0115 6.5H6.01148C5.45919 6.5 5.01148 6.94771 5.01148 7.5V17.5H4.01148V3.29823L14.0115 1.0255ZM3.01148 18.5V17.5V3.36855L0.604868 3.88425C0.334856 3.94211 0.0690624 3.77013 0.0112024 3.50012C-0.0466578 3.2301 0.125326 2.96431 0.395338 2.90645L13.8017 0.0336618C13.8519 0.0228986 13.9028 0.0160496 13.9538 0.0131147L14.0115 0L14.0694 0.0131571C14.1201 0.0161153 14.1707 0.0229502 14.2207 0.0336618L27.6271 2.90645C27.8971 2.96431 28.0691 3.2301 28.0112 3.50011C27.9533 3.77013 27.6875 3.94211 27.4175 3.88425L25.0115 3.36866V17.5V18.5V19.5H27.5115C27.7876 19.5 28.0115 19.7239 28.0115 20C28.0115 20.2761 27.7876 20.5 27.5115 20.5H25.0115H3.01148H0.511476C0.235333 20.5 0.0114756 20.2761 0.0114756 20C0.0114756 19.7239 0.235333 19.5 0.511475 19.5H3.01148V18.5ZM24.0115 18.5V19.5H4.01148V18.5H5.01148H16.0115H17.0115H23.0115H24.0115ZM6.01148 17.5H15.0115V7.5H6.01148V17.5ZM18.0115 17.5H22.0115V7.5H18.0115V17.5Z"
      fill="black"
    />
  </svg>
);

export default House;
