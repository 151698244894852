import { AbstractAssetTask, AbstractMesh, MeshAssetTask } from "@babylonjs/core";
import { AccordionEnum } from "src/services/enums";

let allMeshes: AbstractMesh[] = [];
let roofMesh: AbstractMesh[] = [];

export const saveRoofEntranceData = (tasks: AbstractAssetTask[], scene) => {
  allMeshes = [];
  roofMesh = [];

  roofMesh.push(scene.getMeshByName("Roof_Entrance_Construction"),
    scene.getMeshByName("Roof_Entrance_Support"));
  
  tasks.forEach((task) => {
    if (task.name.includes(AccordionEnum.Roof_Entrance)) {
      const assetTask = task as MeshAssetTask;
      const taskNameParts = task.name.split("_");
      const variationName = taskNameParts?.[1];

      if (!variationName) {
        return;
      }

      assetTask?.loadedMeshes?.forEach((mesh) => {
        allMeshes.push(mesh);

        if (!mesh.metadata) {
          mesh.metadata = {};
        }
        mesh.metadata.task = `${taskNameParts?.[2].split(" - ")?.[0]} ${taskNameParts?.[3]?.split(" - ")?.[0]}`;
      });
    }
  });

  roofMesh?.forEach((mesh) => (mesh && (mesh.isVisible = false)));
};

const hideAllMeshes = () => {
  allMeshes?.forEach((mesh) => (mesh && (mesh.isVisible = false)));
  roofMesh?.forEach((mesh) => (mesh && (mesh.isVisible = false)));
};

const showMeshes = (roofVariation, material) => {
  allMeshes.forEach((mesh) => {
    const meshTaskName = mesh?.metadata?.task?.split(" ")?.[0];
    if (roofVariation.includes(meshTaskName)) {
      mesh.isVisible = true;
      mesh.material = material;
    }
  });
  roofMesh?.forEach((mesh) => (mesh && (mesh.isVisible = true)));
};

export const handleEntranceRoof = (roofEntranceType, roof) => {
  if(!roofEntranceType) return;
  const roofMaterial = roof?.mesh?.loadedMeshes?.[1]?.material;
  
  if(roofEntranceType.original_key.toLowerCase().includes("ja")){
    showMeshes(roof.key.replace(/,/, "").toLowerCase().replace(/å/, "a"), roofMaterial);
  }else{
    hideAllMeshes();
  }
};