import React from "react";
import { ColorInputProps } from "./Types";
import classnames from "classnames";
import { Image } from "../image";

export const ColorRadioInput = ({
  name,
  id,
  value,
  uniqueKey,
  onChange,
  applyBorder,
  disabled,
  image,
  checked
}: ColorInputProps) => {
  return (
    <div className="flex">
      <label
        htmlFor={id}
        className="flex flex-col items-center relative cursor-pointer"
      >
        <input
          type="radio"
          className="absolute w-0 h-0 opacity-0 peer"
          id={id}
          value={value}
          name={name}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          data-unique-key={uniqueKey}
        />
        <div className="inline-block w-[2.625rem] h-[2.625rem] p-1 md:w-14 md:h-14 md:p-2 border border-transparent peer-checked:border-black-secondary peer-disabled:cursor-not-allowed">
          <div
            className={classnames("w-full h-full", {
              "border border-gray-secondary": applyBorder,
            })}
            style={{
              border: applyBorder ? "" : "1px solid transparent",
            }}
          >
            <Image {...image} src={image?.src} title={image?.title} />
          </div>
        </div>
      </label>
    </div>
  );
};
