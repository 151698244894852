export const handleKitchenAccessories = (scene) => {
  if (!scene) return;

  const getMicrowave = scene?.getMeshByName("Microwave")?.isVisible;
  const getUgn = scene?.getMeshesById("Oven");

  const getKitchenMicroFront = scene.getMeshesById("Front_Plain_Microwave_No");
  const getKitchenUgnFront = scene?.getMeshesById("Front_Plain_Oven_No");
  const getKitchenMicroFrontFrame = scene.getMeshesById("Front_Frame_Microwave_No");
  const getKitchenUgnFrontFrame = scene?.getMeshesById("Front_Frame_Oven_No");

  const getStandarMicroHandtags = scene?.getMeshByName("Handles_Standard_Microwave_No");
  const getBowlMicroHandtags = scene?.getMeshByName("Handles_Bowl_Microwave_No");
  const getStandarUgnHandtags = scene?.getMeshByName("Handles_Standard_Oven_No");
  const getBowlUgnHandtags = scene?.getMeshByName("Handles_Bowl_Oven_No");
  const getStandarNoOventags = scene?.getMeshByName("Handles_Standard_Oven_Yes");

  if (getMicrowave && (getKitchenMicroFront.length > 0 || getKitchenMicroFrontFrame.length > 0)) {
    getKitchenMicroFront.forEach(mesh => {
      mesh.isVisible = false;
    });
    getKitchenMicroFrontFrame.forEach(mesh => {
      mesh.isVisible = false;
    });

    if (getStandarMicroHandtags?.isVisible) {
      getStandarMicroHandtags.isVisible = false;
    }
    if (getBowlMicroHandtags?.isVisible) {
      getBowlMicroHandtags.isVisible = false;
    }
  }

  if (getUgn.length > 0
    && (getKitchenUgnFront.length > 0 || getKitchenUgnFrontFrame.length > 0)
    && (getUgn?.[0]?.isVisible || getUgn?.[1]?.isVisible)
  ) {
    getKitchenUgnFront.forEach(mesh => {
      mesh.isVisible = false;
    });
    getKitchenUgnFrontFrame.forEach(mesh => {
      mesh.isVisible = false;
    });

    if (getStandarUgnHandtags?.isVisible) {
      getStandarUgnHandtags.isVisible = false;
    }
    if (getBowlUgnHandtags?.isVisible) {
      getBowlUgnHandtags.isVisible = false;
    }
    if(getStandarNoOventags && getStandarNoOventags?.parent?.parent?.isVisible) getStandarNoOventags.isVisible = true;
  }
};

export const handleDishwasher = (variant, scene, ovenSelected) => {
  if (!scene) return;

  const getStandarNoOventags = scene?.getMeshByName("Handles_Standard_Oven_Yes");
  const getBowlNoOventags = scene?.getMeshByName("Handles_Bowl_Oven_Yes");
  const getStandarNoDishHandtags = scene?.getMeshByName("Handles_Standard_Dishwasher_No");
  const getStandarYesDishHandtags = scene?.getMeshByName("Handles_Standard_Dishwasher_Yes");
  const getBowlDishNoHandtags = scene?.getMeshByName("Handles_Bowl_Dishwasher_No");
  const getBowlDishYesHandtags = scene?.getMeshByName("Handles_Bowl_Dishwasher_Yes");

  if (variant?.original_key.includes("diskmaskin")) {
    if (getStandarNoDishHandtags) getStandarNoDishHandtags.isVisible = false;
    if (getStandarYesDishHandtags && getStandarYesDishHandtags.parent.parent.isVisible) getBowlDishNoHandtags.isVisible = true;
    if (getBowlDishNoHandtags) getBowlDishNoHandtags.isVisible = false;
    if (getBowlDishYesHandtags && getBowlDishYesHandtags.parent.parent.isVisible) getBowlDishYesHandtags.isVisible = true;
  } else {
    if (getStandarNoDishHandtags && getStandarNoDishHandtags.parent.parent.isVisible) getStandarNoDishHandtags.isVisible = true;
    if (getBowlDishNoHandtags && getBowlDishNoHandtags.parent.parent.isVisible) getBowlDishNoHandtags.isVisible = true;
    if (getBowlDishYesHandtags) getBowlDishYesHandtags.isVisible = false;
    if (getStandarYesDishHandtags) getStandarYesDishHandtags.isVisible = false;
  }

  if(!ovenSelected){
    if(getStandarNoOventags) getStandarNoOventags.isVisible = false;
    if(getBowlNoOventags) getBowlNoOventags.isVisible = false;
  }else{
    if(getStandarNoOventags && getStandarNoOventags.parent?.parent?.isVisible) getStandarNoOventags.isVisible = true;
    if(getBowlNoOventags) getBowlNoOventags.isVisible = false;
  }
};
