import React from "react";

import { IconProps } from "../Types";

const Loft = ({ className, fill }: IconProps) => (
  <svg
    className={className}
    width="19"
    height="15"
    viewBox="0 0 19 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_10222_30773)">
      <path
        d="M18.5243 1.46216H14.0811L14.2524 0.556504C14.2999 0.301787 14.1287 0.056504 13.8718 0.00933421C13.6149 -0.0378356 13.3676 0.131976 13.32 0.386693L13.1107 1.46216H8.96244L9.1337 0.556504C9.18127 0.301787 9.01002 0.056504 8.75313 0.00933421C8.49624 -0.0378356 8.24887 0.131976 8.2013 0.386693L7.99199 1.46216H0.475714C0.209314 1.46216 0 1.66971 0 1.93386C0 2.19801 0.209314 2.40556 0.475714 2.40556H7.81122L5.48973 14.4433C5.44216 14.698 5.61342 14.9433 5.87031 14.9905C5.89885 14.9905 5.92739 14.9905 5.95593 14.9905C6.17476 14.9905 6.37456 14.8301 6.42213 14.6037L6.75513 12.8867H10.9034L10.6084 14.4244C10.5608 14.6791 10.7321 14.9244 10.989 14.9716C11.0175 14.9716 11.0461 14.9716 11.0746 14.9716C11.2934 14.9716 11.4932 14.8112 11.5408 14.5848L13.9004 2.40556H18.5243C18.7907 2.40556 19 2.19801 19 1.93386C19 1.66971 18.7907 1.46216 18.5243 1.46216ZM12.1022 6.70745H7.95393L8.27742 5.0282H12.4256L12.1022 6.70745ZM11.5979 9.33009H7.44967L7.77316 7.65084H11.9214L11.5979 9.33009ZM6.94542 11.9527L7.2689 10.2735H11.4171L11.0936 11.9527H6.9359H6.94542ZM12.6064 4.08481H8.45819L8.78167 2.40556H12.9299L12.6064 4.08481Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_10222_30773">
        <rect width="19" height="15" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Loft;
