import React from "react";

import { IconProps } from "../Types";

const Badrum = ({ className }: IconProps) => (
  <svg
    className={className}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 700 700"
    xmlSpace="preserve"
  >
    <path
      fill="#006564"
      className="st0"
      d="M264.9,404.8c-42.2-12.5-68.4-33.5-68.4-54.8c0-35.9,70.3-66.3,153.5-66.3c2.8,0,5.7,0.1,8.5,0.1l-9.7,18.8
	c-2.1,4.1-0.5,9.2,3.6,11.3c1.2,0.6,2.6,0.9,3.8,0.9c3,0,6-1.7,7.5-4.5l15.7-30.4c0.2-0.5,0.4-1,0.6-1.5c0,0,0,0,0,0
	c0.1-0.3,0.2-0.7,0.2-1.1c0-0.2,0.1-0.4,0.1-0.5v-0.1c0-0.1,0-0.1,0-0.2c0-0.2,0-0.4,0-0.6c0-0.2,0-0.5,0-0.7c0,0,0-0.1,0-0.1
	c0-0.3-0.1-0.7-0.2-1c0-0.2-0.1-0.4-0.1-0.5c0,0,0-0.1,0-0.1c-0.1-0.3-0.2-0.6-0.4-0.9c-0.1-0.2-0.1-0.3-0.2-0.5l-15.7-30.5
	c-2.1-4.1-7.2-5.7-11.3-3.6c-4.1,2.1-5.7,7.2-3.6,11.3l9.1,17.7c-2.7-0.1-5.3-0.1-8-0.1c-95.5,0-170.3,36.5-170.3,83.1
	c0,29.4,30.1,55.9,80.4,70.9c0.8,0.2,1.6,0.3,2.4,0.3c3.6,0,7-2.4,8.1-6C271.9,410.7,269.3,406.1,264.9,404.8L264.9,404.8z
	 M520.3,350c0-29.4-30.1-55.9-80.4-70.9c-4.4-1.3-9.1,1.2-10.4,5.7c-1.3,4.4,1.2,9.1,5.7,10.4c42.2,12.5,68.4,33.5,68.4,54.8
	c0,35.9-70.3,66.3-153.5,66.3c-2.8,0-5.7-0.1-8.5-0.1l9.7-18.8c2.1-4.1,0.5-9.2-3.6-11.3c-4.1-2.1-9.2-0.5-11.3,3.6l-15.7,30.5
	c0,0,0,0.1,0,0.1c-0.1,0.2-0.2,0.5-0.3,0.7c-0.1,0.2-0.2,0.4-0.2,0.6c0,0,0,0,0,0c-0.1,0.2-0.1,0.5-0.2,0.7v0
	c-0.1,0.3-0.1,0.5-0.1,0.8v0.1c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5v0c0,0.3,0,0.5,0,0.8c0,0,0,0.1,0,0.1c0,0.3,0.1,0.6,0.2,1
	c0,0.2,0.1,0.4,0.1,0.5c0,0,0,0.1,0,0.1c0.1,0.3,0.2,0.6,0.3,0.9c0.1,0.2,0.1,0.3,0.2,0.5l15.7,30.5c1.5,2.9,4.4,4.5,7.5,4.5
	c1.3,0,2.6-0.3,3.8-0.9c4.1-2.1,5.7-7.2,3.6-11.3l-9.1-17.7c2.7,0.1,5.3,0.1,8,0.1C445.5,433.1,520.3,396.6,520.3,350L520.3,350z"
    />
  </svg>
);

export default Badrum;
