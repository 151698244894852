import React, { useState } from "react";
import { Icon } from "../icon/Icon";
import { filterSubMenu } from "./FilterSubMenu";
import { SubmenuProps } from "./Types";

const Submenu = ({isSubmenuOpen, setIsSubmenuOpen, setSelectionAccordion, singleID}:SubmenuProps) => {
  const [current, setCurrent] = useState("Sofa");  

  const onSubmenuChange = (e) => { 
    setCurrent(e.currentTarget.id);

    if(e.currentTarget.id === "Kok") {
      setSelectionAccordion("Kitchen");
    }
    if(e.currentTarget.id === "Sofa") {
      setSelectionAccordion("General");
    }
    else if(e.currentTarget.id === "Sovrum") {
      setSelectionAccordion("Furniture Types 2");
    }
    else if(e.currentTarget.id === "Badrum") {
      setSelectionAccordion("Bathroom");
    }
    else if(e.currentTarget.id === "Loft") {
      setSelectionAccordion("Furniture Types 1");
    }
  };

  const subMenuElements = filterSubMenu[singleID || "framtid_a_1"];

  return (
    <div className="absolute p-6 top-[6rem] right-[1.5rem] z-[15] w-[20px] h-[20px] flex justify-center items-center cursor-pointer">
      <div className="flex flex-col items-end relative bg-secondary" style={{borderRadius: isSubmenuOpen ? "50% 50% 0 0" : "50%"}} onClick={() => setIsSubmenuOpen(!isSubmenuOpen)}>
        <div className="bg-[#fff] rounded-full w-[45px] h-[45px] flex justify-center items-center">
          <Icon name={current} fill="#658481" />
        </div>
        <div className="relative">
          {isSubmenuOpen && <div className="submenu flex absolute right-0">
            <div>
              <ul className="h-full flex flex-col justify-around bg-secondary">
                {subMenuElements?.map((element, index) => {
                  if (current !== element){
                    return (
                      <li className="p-2 w-[45px] h-[45px] flex justify-center items-center" key={index} id={element} onClick={onSubmenuChange}><Icon name={element} fill={"#fff"}/></li>
                    );
                  }
                })}
              </ul>
              <div className="bg-secondary rounded-full w-[45px] h-[45px] flex justify-center items-center -mt-[20px]"></div>
            </div>
          </div>}
        </div>
      </div>
    </div>
  );
};

export default Submenu;
