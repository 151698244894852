export const handleFridge = (variant, scene) => {
  if(!variant) return;
  const getKitchenFirdgeFullFront = scene.getMeshesById("Front_Plain_Fridge_Yes");
  const getKitchenFirdgeHalfFront = scene.getMeshesById("Front_Plain_Fridge_No");
  const getKitchenFirdgeFullFrame = scene.getMeshesById("Front_Frame_Fridge_Yes");
  const getKitchenFirdgeHalfFrame = scene.getMeshesById("Front_Frame_Frige_No");
  
  const kitchenWallYesFridge = scene.getMeshesById("Splashback_Fridge_Yes");
  const kitchenWallNoFridge = scene.getMeshesById("Splashback_Fridge_No");

  const getKitchenCountertopFridge = scene.getMeshesById("Countertop_Fridge_No");
  const getStandarNoFridgeHandtags = scene?.getMeshByName("Handles_Standard_Fridge_No");
  const getStandarYesFridgeHandtags = scene?.getMeshByName("Handles_Standard_Fridge_Yes");
  const getBowlFridgeNoHandtags = scene?.getMeshByName("Handles_Bowl_Fridge_No");
  const getBowlFridgeYesHandtags = scene?.getMeshByName("Handles_Bowl_Fridge_Yes");
  const splashBackFridgeYes = scene?.getMeshByName("Splashback_Fridge_Yes");

  if(variant.original_key.includes("electrolux_kylkap_full_size")){
    kitchenWallNoFridge?.map(m => m.isVisible = false);
    kitchenWallYesFridge?.map(m =>  {
      if(m.parent.parent.parent.isVisible){
        m.isVisible = true;
      }});
    getKitchenFirdgeHalfFront?.map(m => m.isVisible = false);
    getKitchenFirdgeHalfFrame?.map(m => m.isVisible = false);
    getKitchenCountertopFridge?.map(m => m.isVisible = false);
    getKitchenFirdgeFullFront?.map((m) => m.parent.parent.parent.isVisible && (m.isVisible = true));
    getKitchenFirdgeFullFrame?.map((m) => m.parent.parent.parent.isVisible && (m.isVisible = true));

    if(getStandarNoFridgeHandtags) getStandarNoFridgeHandtags.isVisible = false;
    if(getStandarYesFridgeHandtags && getStandarYesFridgeHandtags.parent.parent.isVisible) getStandarYesFridgeHandtags.isVisible = true;
    if(getBowlFridgeNoHandtags) getBowlFridgeNoHandtags.isVisible = false;
    if(getBowlFridgeYesHandtags && getBowlFridgeYesHandtags.parent.parent.isVisible) getBowlFridgeYesHandtags.isVisible = true;
  }

  if(variant.original_key.includes("electrolux_kylkap_half_size")){
    getKitchenFirdgeHalfFront?.map(m => m.parent.parent.parent.isVisible && (m.isVisible = true));
    getKitchenFirdgeHalfFrame?.map(m => m.parent.parent.parent.isVisible && (m.isVisible = true));
    getKitchenFirdgeFullFrame?.map((m) => m.isVisible = false);
    getKitchenCountertopFridge?.map(m => m.parent.parent.parent.isVisible && (m.isVisible = true));
    getKitchenFirdgeFullFront?.map(m => m.isVisible = false);
    if(splashBackFridgeYes) splashBackFridgeYes.isVisible = false;

    kitchenWallNoFridge?.map(m => {
      if(m.parent.parent.parent.isVisible){
        m.isVisible = true;
      }
    });
    kitchenWallYesFridge?.map(m => m.isVisible = false);
    if(getStandarNoFridgeHandtags && getStandarNoFridgeHandtags.parent.parent.isVisible) getStandarNoFridgeHandtags.isVisible = true;
    if(getStandarYesFridgeHandtags) getStandarYesFridgeHandtags.isVisible = false;
    if(getBowlFridgeNoHandtags && getBowlFridgeNoHandtags.parent.parent.isVisible ) getBowlFridgeNoHandtags.isVisible = true;
    if(getBowlFridgeYesHandtags) getBowlFridgeYesHandtags.isVisible = false;
  }
};