import { AbstractAssetTask, AbstractMesh, MeshAssetTask } from "@babylonjs/core";

let allMeshes: AbstractMesh[] = [];

export const saveSlidingSprojsData = (tasks: AbstractAssetTask[]) => {
  allMeshes = [];

  tasks.forEach((task) => {
    if (task.name.includes("Windows Sprojs Type")) {
      const assetTask = task as MeshAssetTask;
      const taskNameParts = task.name.split("_");
      const variationName = taskNameParts?.[1];

      if (!variationName) {
        return;
      }

      assetTask?.loadedMeshes?.forEach((mesh) => {
        allMeshes.push(mesh);

        if (!mesh.metadata) {
          mesh.metadata = {};
        }
        mesh.metadata.task = `${taskNameParts?.[2].split(" - ")?.[0]} ${taskNameParts?.[3]?.split(" - ")?.[0]}`;
      });
    }
  });

};

const hideAllMeshes = () => {
  allMeshes?.forEach((mesh) => (mesh.isVisible = false));
};

const showMeshes = (slidingDoorsSelected, scene, singleID) => {
  allMeshes.forEach((mesh) => {
    mesh.isVisible = false;
    if(slidingDoorsSelected.original_key.includes("pivot_opening") && mesh.name.includes("Pivot_Crossbar")){
      mesh.isVisible = true;
    }
    if(slidingDoorsSelected.original_key.includes("gable_opening") && mesh.name.includes("_Opening_")){
      mesh.isVisible = true;
    }
    if(!scene.getMeshByName("Gable_Opening_Sliding_In")?.isVisible && mesh.name.includes("Gable_Opening_Sliding_Crossbar_primitive")){
      mesh.isVisible = false;
    }
    if(!scene.getMeshByName("Side_Opening_Sliding_In")?.isVisible && mesh.name.includes("Side_Opening_Sliding_Crossbar_primitive")){
      mesh.isVisible = false;
    }
    if(scene.getMeshByName("Side_Opening_Sliding_In")?.isVisible && mesh.name.includes("Side_Opening_Fix_Crossbar_primitive")){
      mesh.isVisible = false;
    }
    if(scene.getMeshByName("Gable_Opening_Sliding_In")?.isVisible && mesh.name.includes("Gable_Opening_Fix_Crossbar_primitive")){
      mesh.isVisible = false;
    }
    if(singleID.includes("klassisk_d") && mesh.name.includes("Side_Opening_Sliding_Crossbar_primitive")){
      mesh.isVisible = true;
    }
    if(singleID.includes("klassisk_d") && mesh.name.includes("Gable_Opening_Sliding_Crossbar_primitive")){
      mesh.isVisible = true;
    }
  });
};

export const handleSlidingSprojs = (sprojsSelected, slidingDoorsSelected, scene, singleID) => {
  if(sprojsSelected){
    showMeshes(slidingDoorsSelected, scene, singleID);
  }else{
    hideAllMeshes();
  }
};